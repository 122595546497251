import React, {Fragment, useEffect, useState} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import axios from 'axios';
import {next_icon, previous_icon, response_error} from '../../../helpers/functions';
import Member from './Member';
import MembersFilterForm from './MembersFilterForm';
import ReactPaginate from "react-paginate";
import {useSearchParams} from "react-router-dom";
import Confirm from "../../../components/custom/confirm";

const members = () => {
    const [searchParams] = useSearchParams()

    const [members, setMembers] = useState([]);
    const [param, setParam] = useState({
        search: searchParams.get('search') ?? '',
        groups: [],
        memberships: [],
        contact_types: [],
        statuses: [],
        regions: [],
        sites: [],
        ambassador_titles: [],
        page: 1
    });
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const handlePageClick = page => {
        setPage(page.selected + 1);
    }

    const getMembers = (_page) => {
        const _param = {...param};
        _param.groups = _param.groups.map(item => item.value);
        _param.memberships = _param.memberships.map(item => item.value);
        _param.contact_types = _param.contact_types.map(item => item.value);
        _param.statuses = _param.statuses.map(item => item.value);
        _param.regions = _param.regions.map(item => item.value);
        _param.sites = _param.sites.map(item => item.value);
        _param.ambassador_titles = _param.ambassador_titles.map(item => item.value);
        //_param.search = _param.search ? _param.search : searchParams.get('search');
        _param.page = page;
        if (typeof _page === 'undefined') {
            _param.page = page;
        } else {
            _param.page = _page;
        }
        axios
            .get('/members', {params: _param})
            .then(response => {
                setMembers(response.data.data);
                let total_page = parseInt(response.data.total) / parseInt(response.data.per_page);
                total_page = total_page > 0 ? Math.ceil(total_page) : 0;
                setPageCount(total_page);
            })
            .catch(error => {
                response_error(error);
            });
    };

    const downloadMembers = () => {
        const _param = {...param};
        _param.groups = _param.groups.map(item => item.value);
        _param.memberships = _param.memberships.map(item => item.value);
        _param.contact_types = _param.contact_types.map(item => item.value);
        _param.statuses = _param.statuses.map(item => item.value);
        _param.regions = _param.regions.map(item => item.value);
        _param.sites = _param.sites.map(item => item.value);
        _param.ambassador_titles = _param.ambassador_titles.map(item => item.value);
        //_param.search = _param.search ? _param.search : searchParams.get('search');

        axios
            .get('/members/export', {params: _param, responseType: "blob"})
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'members.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                response_error(error);
            });
    };

    useEffect(() => {
        getMembers();
    }, []);

    return (
        <Fragment>
            <Row className="g-0 mt-3">
                <Col lg={12}>
                    <Row className="g-0">
                        <Col lg={12}>
                            <Card className="h-lg-100">
                                <Card.Header as="h5">Members</Card.Header>
                                <div className="border-dashed-bottom"></div>
                                <Card.Body>
                                    <MembersFilterForm
                                        setParam={setParam}
                                        param={param}
                                        getMembers={() => getMembers()}
                                        downloadMembers={() => downloadMembers()}
                                    />
                                    <Member members={members}/>
                                    <ReactPaginate
                                        previousLabel={previous_icon()}
                                        nextLabel={next_icon()}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={'pagination mb-0 mx-1'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>



        </Fragment>
    );
};

export default members;
