import {Button, Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {Link} from "react-router-dom";
import IconButton from "../../../../components/common/IconButton";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {response_error} from "../../../../helpers/functions";
import moment from "moment/moment";

const EventFilterForm = ({param, setParam, getEvents}) => {
    const [groupOptions, setGroupOptions] = useState([]);

    const eventOptions = [
        {value: 'Previous Events', label: 'Previous Events'},
        {value: 'Upcoming Events', label: 'Upcoming Events'},
    ];

    useEffect(() => {
        axios.get('/groups/valid')
            .then(response => {
                setGroupOptions(response.data.groups.map(group => {
                    return {value: group.id, label: group.name}
                }))
            })
            .catch(error => {
                response_error(error);
            })

    }, [])
  return (
      <Row>
          <Col lg={12}>
              <Form.Group
                  as={Row}
                  controlId="formPlaintextEmail"
              >
                  <Col lg="2">
                      <Select
                          closeMenuOnSelect={false}
                          options={groupOptions}
                          placeholder='Groups'
                          isMulti
                          classNamePrefix="react-select"
                          value={param.groups}
                          onChange={value => setParam({...param, groups: value})}
                      />
                  </Col>
                  <Col lg="2">
                      <DatePicker
                          selected={Date.parse(param.event_date)}
                          onChange={eventTime =>
                              setParam(prevState => ({
                                  ...prevState,
                                  event_date: moment(eventTime).format('YYYY-MM-DD')
                              }))
                          }
                          className='form-control'
                          placeholderText="Select Date"
                          dateFormat="yyyy-MM-dd"
                      />
                  </Col>

                  <Col lg="2">
                      <Select
                          options={eventOptions}
                          placeholder='Event Mode'
                          classNamePrefix="react-select"
                          value={param.event_mode}
                          onChange={value => setParam({...param, event_mode: value})}
                      />
                  </Col>


                  <Col lg="2">
                      <Button
                          variant="falcon-default"
                          className="me-2"
                          type="button"
                          onClick={getEvents}
                      >
                          Search
                      </Button>
                  </Col>
                  <Col lg="4" className="text-end">
                      <div id="orders-actions">
                          <Link to="/events/create">
                              <IconButton
                                  variant="falcon-default"
                                  icon="plus"
                                  transform="shrink-3"
                              >
                                  <span className="d-none d-sm-inline-block ms-1">New</span>
                              </IconButton>
                          </Link>
                      </div>
                  </Col>
              </Form.Group>
          </Col>
      </Row>
  )
}

export default EventFilterForm;