export const profession = [
        {value: 'Accountant', label: 'Accountant'},
        {value: 'Architect', label: 'Architect'},
        {value: 'Asset finance', label: 'Asset finance'},
        {value: 'Business coach', label: 'Business coach'},
        {value: 'Business consultant', label: 'Business consultant'},
        {value: 'Bookkeeper', label: 'Bookkeeper'},
        {value: 'Builder', label: 'Builder'},
        {value: 'Car/van leasing', label: 'Car/van leasing'},
        {value: 'CRM', label: 'CRM'},
        {value: 'Electrical contractor', label: 'Electrical contractor'},
        {value: 'Energy consultant', label: 'Energy consultant'},
        {value: 'Estate/letting agent', label: 'Estate/letting agent'},
        {value: 'Financial advisor', label: 'Financial advisor'},
        {value: 'Fire safety', label: 'Fire safety'},
        {value: 'Graphic designer', label: 'Graphic designer'},
        {value: 'Health & safety', label: 'Health & safety'},
        {value: 'Health insurance', label: 'Health insurance'},
        {value: 'HR', label: 'HR'},
        {value: 'Insolvency practitioners', label: 'Insolvency practitioners'},
        {value: 'Insurance', label: 'Insurance'},
        {value: 'IT consultant', label: 'IT consultant'},
        {value: 'Leadership coach', label: 'Leadership coach'},
        {value: 'Manufacturing companies', label: 'Manufacturing companies'},
        {value: 'Mortgage broker', label: 'Mortgage broker'},
        {value: 'Office supplies', label: 'Office supplies'},
        {value: 'Printer', label: 'Printer'},
        {value: 'Promotional goods', label: 'Promotional goods'},
        {value: 'Recruitment', label: 'Recruitment'},
        {value: 'Security/CCTV', label: 'Security/CCTV'},
        {value: 'Solicitor', label: 'Solicitor'},
        {value: 'Quantity surveyor', label: 'Quantity surveyor'},
        {value: 'Tax advisor', label: 'Tax advisor'},
        {value: 'Telecoms', label: 'Telecoms'},
        {value: 'Training', label: 'Training'},
        {value: 'Video production', label: 'Video production'},
        {value: 'Website designer', label: 'Website designer'},
        {value: 'Windows & conservatories', label: 'Windows & conservatories'},
        {value: 'App development', label: 'App development'},
        {value: 'Banking', label: 'Banking'},
        {value: 'Building supplies', label: 'Building supplies'},
        {value: 'Business sales', label: 'Business sales'},
        {value: 'Call answering', label: 'Call answering'},
        {value: 'Carpenter', label: 'Carpenter'},
        {value: 'Car servicing', label: 'Car servicing'},
        {value: 'Cleaning company', label: 'Cleaning company'},
        {value: 'Copywriter', label: 'Copywriter'},
        {value: 'Computer sales', label: 'Computer sales'},
        {value: 'Courier', label: 'Courier'},
        {value: 'Debt collections', label: 'Debt collections'},
        {value: 'Digital agency', label: 'Digital agency'},
        {value: 'Direct mail', label: 'Direct mail'},
        {value: 'Engineering', label: 'Engineering'},
        {value: 'Entertainer', label: 'Entertainer'},
        {value: 'Event planner', label: 'Event planner'},
        {value: 'Foreign exchange', label: 'Foreign exchange'},
        {value: 'Fitness trainer', label: 'Fitness trainer'},
        {value: 'Fleet management', label: 'Fleet management'},
        {value: 'Flooring', label: 'Flooring'},
        {value: 'FMCG', label: 'FMCG'},
        {value: 'Holiday rentals', label: 'Holiday rentals'},
        {value: 'Interior design', label: 'Interior design'},
        {value: 'Investor', label: 'Investor'},
        {value: 'Jeweller', label: 'Jeweller'},
        {value: 'Kitchens and bathrooms', label: 'Kitchens and bathrooms'},
        {value: 'Landscaping', label: 'Landscaping'},
        {value: 'Language services', label: 'Language services'},
        {value: 'Legal services', label: 'Legal services'},
        {value: 'Life coach', label: 'Life coach'},
        {value: 'Lighting', label: 'Lighting'},
        {value: 'Locksmith', label: 'Locksmith'},
        {value: 'Logistics', label: 'Logistics'},
        {value: 'Network cabling', label: 'Network cabling'},
        {value: 'Painter', label: 'Painter'},
        {value: 'Payroll', label: 'Payroll'},
        {value: 'Photography', label: 'Photography'},
        {value: 'Plumber', label: 'Plumber'},
        {value: 'Publisher', label: 'Publisher'},
        {value: 'Property investor', label: 'Property investor'},
        {value: 'Office furniture', label: 'Office furniture'},
        {value: 'Pest control', label: 'Pest control'},
        {value: 'Property maintenance', label: 'Property maintenance'},
        {value: 'PR', label: 'PR'},
        {value: 'Renewable energy', label: 'Renewable energy'},
        {value: 'R&D tax', label: 'R&D tax'},
        {value: 'Removals', label: 'Removals'},
        {value: 'Sales training', label: 'Sales training'},
        {value: 'SEO & PPC', label: 'SEO & PPC'},
        {value: 'Signage', label: 'Signage'},
        {value: 'Social media management', label: 'Social media management'},
        {value: 'Social media advertising', label: 'Social media advertising'},
        {value: 'Speaker', label: 'Speaker'},
        {value: 'Storage', label: 'Storage'},
        {value: 'Telemarketing', label: 'Telemarketing'},
        {value: 'Travel', label: 'Travel'},
        {value: 'Vehicle sales & leasing', label: 'Vehicle sales & leasing'},
        {value: 'Virtual FD', label: 'Virtual FD'},
        {value: 'Wellbeing', label: 'Wellbeing'},
        {value: 'Will writer', label: 'Will writer'},
        {value: 'Window cleaning', label: 'Window cleaning'},
        {value: 'Workwear', label: 'Workwear'},
        {value: 'Other', label: 'Other'},
        {value: 'Marketing consultant', label: 'Marketing consultant'},
        {value: 'Alarm Systems', label: 'Alarm Systems'},
        {value: 'Estate Attorney', label: 'Estate Attorney'},
        {value: 'Commercial Realtor', label: 'Commercial Realtor'},
        {value: 'Commercial Real Estate lender', label: 'Commercial Real Estate lender'},
        {value: 'Estate Planner', label: 'Estate Planner'},
        {value: 'Residential Realtor', label: 'Residential Realtor'},
        {value: 'Residential Real Estate Lender', label: 'Residential Real Estate Lender'},
        {value: 'Software Developer', label: 'Software Developer'},
        {value: 'Event Management', label: 'Event Management'},
        {value: 'Cyber Security', label: 'Cyber Security'},
        {value: 'Hospitality', label: 'Hospitality'},
        {value: 'Waste Management', label: 'Waste Management'},
        {value: 'Sauna & Swimming Pools', label: 'Sauna & Swimming Pools'},
        {value: 'Business Loans', label: 'Business Loans'},
        {value: 'Invoice Factoring', label: 'Invoice Factoring'},
        {value: 'Office Fit Out', label: 'Office Fit Out'},
        {value: 'Vehicle Maintenance', label: 'Vehicle Maintenance'},
        {value: 'Language Translator', label: 'Language Translator'},
        {value: 'Building Management Services', label: 'Building Management Services'},
        {value: 'Doctor', label: 'Doctor'},
        {value: 'Chiropractor', label: 'Chiropractor'},
        {value: 'Physiotherapist', label: 'Physiotherapist'},
        {value: 'Osteopath', label: 'Osteopath'},
    ];