import {Button, Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {response_error} from "../../../helpers/functions";
import {contactTypeOptions} from "../../../data/options";
import DatePicker from "react-datepicker";
import {format} from "date-fns";

const MembersFilterForm = ({param, setParam, getMembers, downloadMembers}) => {
    const ambassadorTitles = [
        { value: 'ambassador', label: 'Ambassador' },
        { value: 'deputy_ambassador', label: 'Deputy Ambassador' },
        { value: 'social_ambassador', label: 'Social Ambassador' },
        { value: 'regional_manager', label: 'Regional Manager' }
    ];
    const membershipStatuses = [
        {value: 'pending', label: 'Pending'},
        {value: 'active', label: 'Active'},
        {value: 'suspended', label: 'Suspended'},
        {value: 'lapsed', label: 'Lapsed'}
    ];

    const [regionOptions, setRegionOptions] = useState([]);
    const [siteOptions, setSiteOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);

    const [membershipOptions, setMembershipOptions] = useState([]);

    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            getMembers(1)
        }
    }
    const date = new Date();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const dateOnChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setParam({...param, leaving_date_start: start ? format(new Date(start), 'yyyy-MM-dd') : '', leaving_date_end: start ? format(new Date(end), 'yyyy-MM-dd') : ''})
    };
    useEffect(() => {
        axios.get('/groups/forFilter')
            .then(response => {
                setGroupOptions(response.data.groups.map(group => {
                    return {value: group.id, label: group.name}
                }))
            })
            .catch(error => {
                response_error(error);
            })

        axios.get('/membership')
            .then(response => {
                setMembershipOptions(response.data.map(membership => {
                    return {value: membership.id, label: membership.name}
                }))
            })
            .catch(error => {
                response_error(error);
            })
        axios.get('/regions')
            .then(response => {
                setRegionOptions(response.data.regions.map(region => {
                    return {value: region.id, label: region.name}
                }))
            })
            .catch(error => {
                response_error(error);
            })
        axios.get('/sites')
            .then(response => {
                setSiteOptions(response.data.map(site => {
                    return {value: site.id, label: site.name}
                }))
            })
            .catch(error => {
                response_error(error);
            })

    }, [])

    return (
        <>
            <Row className="mb-3" style={{alignItems: "baseline"}}>
                <Col lg={10}>
                    <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextEmail"

                    >
                        <Col lg="3">
                            <Form.Control
                                type="text"
                                className="mb-2"
                                placeholder="Search By Name, Email"
                                onChange={e => setParam({...param, search: e.target.value})}
                                onKeyDown={handleKeyDown}
                                value={param.search}
                            />
                        </Col>
                        <Col lg="3">
                            <Select
                                className="mb-2"
                                closeMenuOnSelect={false}
                                options={groupOptions}
                                placeholder="Groups"
                                isMulti
                                classNamePrefix="react-select"
                                value={param.groups}
                                onChange={value => setParam({...param, groups: value})}
                                onKeyDown={handleKeyDown}
                            />
                        </Col>
                        <Col lg="3">
                            <Select
                                className="mb-2"
                                closeMenuOnSelect={false}
                                options={contactTypeOptions}
                                placeholder="Person Type"
                                isMulti
                                classNamePrefix="react-select"
                                value={param.contact_types}
                                onChange={value => setParam({...param, contact_types: value})}
                                onKeyDown={handleKeyDown}
                            />
                        </Col>

                        <Col lg="3">
                            <Select
                                className="mb-2"
                                closeMenuOnSelect={false}
                                options={membershipOptions}
                                placeholder="Memberships"
                                isMulti
                                classNamePrefix="react-select"
                                value={param.memberships}
                                onChange={value => setParam({...param, memberships: value})}
                                onKeyDown={handleKeyDown}
                            />
                        </Col>

                        <Col lg="3">
                            <Select
                                className="mb-2"
                                closeMenuOnSelect={false}
                                options={membershipStatuses}
                                placeholder="Status"
                                isMulti
                                classNamePrefix="react-select"
                                value={param.statuses}
                                onChange={value => setParam({...param, statuses: value})}
                                onKeyDown={handleKeyDown}
                            />
                        </Col>

                        <Col lg="3">
                            <Select
                                className="mb-2"
                                closeMenuOnSelect={false}
                                options={ambassadorTitles}
                                placeholder='Ambassador Titles'
                                isMulti
                                classNamePrefix="react-select"
                                value={param.ambassador_titles}
                                onChange={value => setParam({...param, ambassador_titles: value})}
                                onKeyDown={handleKeyDown}
                            />
                        </Col>
                        <Col lg="3">
                            <Select
                                className="mb-2"
                                closeMenuOnSelect={false}
                                options={regionOptions}
                                placeholder='Country'
                                isMulti
                                classNamePrefix="react-select"
                                value={param.regions}
                                onChange={value => setParam({...param, regions: value})}
                                onKeyDown={handleKeyDown}
                            />
                        </Col>
                        <Col lg="3">
                            <Select
                                className="mb-2"
                                closeMenuOnSelect={false}
                                options={siteOptions}
                                placeholder='Region'
                                isMulti
                                classNamePrefix="react-select"
                                value={param.sites}
                                onChange={value => setParam({...param, sites: value})}
                                onKeyDown={handleKeyDown}
                            />
                        </Col>
                        <Col lg="3">
                            <DatePicker
                                selected={startDate}
                                onChange={dateOnChange}
                                startDate={startDate}
                                // formatWeekDay={day => day.slice(0, 3)}
                                endDate={endDate}
                                selectsRange
                                dateFormat="MMM dd yyyy"
                                className='form-control'
                                placeholderText="Leaving date"
                            />
                        </Col>
                    </Form.Group>
                </Col>
                <Col lg="2">
                    <Button
                        variant="falcon-default"
                        className="me-2"
                        type="button"
                        onClick={() => getMembers(1)}
                    >
                        Search
                    </Button>
                    <Button
                        variant="falcon-default"
                        className="me-2"
                        type="button"
                        onClick={() => downloadMembers()}
                    >
                        Download
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default MembersFilterForm;