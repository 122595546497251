import axios from 'axios';
import { RegionContext } from 'context/Context';
import { response_error } from 'helpers/functions';
import React, { Component, Fragment, useContext, useState } from 'react';
import { Form, Card, Col, Row, Button } from "react-bootstrap";
import toast from 'react-hot-toast';
import Select from "react-select";

const RegionForm = (prop) => {
    const { getRegions, regions, region, setRegion } = useContext(RegionContext);
    const payment = [
        { value: 'UK', label: 'UK' },
        { value: 'Colorado', label: 'Colorado' },
        { value: 'USA and rest of the world', label: 'USA and rest of the world' }
    ];
    const allowedRegions = regions.map(region => {
        return { value: region.id, label: region.name }
    });

    const changeHandler = (e) => {
        setRegion(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const selectChangeHandler = (e, meta) => {
        setRegion(prevState => ({ ...prevState, [meta.name]: e.value }));
    }

    const multiSelectChangeHandle = (e, meta) => {

        let regions = e.map(region => region.value);

        setRegion(prevState => ({ ...prevState, [meta.name]: regions }));
    }

    const saveRegion = () => {
        axios.post('/regions/create', region)
            .then(response => {
                getRegions();
                prop.setModalShow(false);
                toast.success('Successfully region saved')
            })
            .catch(error => {
               response_error(error)
            })
    }

    const updateRegion = () => {
        axios.put(`/regions/${region.id}`, region)
            .then(response => {
                getRegions();
                prop.setModalShow(false);
                toast.success('Successfully region saved')
            })
            .catch(error => {
               response_error(error)
            })
    }

    return (
        <Fragment>
            <Row>
                <Col lg={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            placeholder="name"
                            name="name"
                            type="text"
                            onChange={changeHandler}
                            value={region.name}
                        />
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            placeholder="Country name in IS"
                            name="is_country_name"
                            type="text"
                            onChange={changeHandler}
                            value={region.is_country_name}
                        />
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Payment method & Xero Account</Form.Label>
                        <Select
                            options={payment}
                            name="payment_method"
                            onChange={selectChangeHandler}
                            value={payment.filter(payment_option => payment_option.value === region.payment_method)}
                        />
                    </Form.Group>
                </Col>
                <Col lg={6}>
                    <Form.Group>
                        <Form.Label>Allowed Countries</Form.Label>
                        <Select
                            options={allowedRegions}
                            isMulti
                            name='regions'
                            onChange={multiSelectChangeHandle}
                            value={allowedRegions.filter(allowedRegions_option => region.regions.includes(allowedRegions_option.value))}
                        />
                    </Form.Group>
                </Col>
                <Col lg={12} className="text-end">
                    {
                        region.id > 0 ? (
                            <Button variant='falcon-default' className='me-2 mb-1' size="sm" onClick={updateRegion}>Update Country</Button>
                        ) : (
                            <Button variant='falcon-default' className='me-2 mb-1' size="sm" onClick={saveRegion}>Add Country</Button>
                        )
                    }

                </Col>
            </Row>
        </Fragment>
    );

}

export default RegionForm;