import React, {useEffect, useState} from 'react';
import {Card, Col, Dropdown, Row, Modal, Form} from 'react-bootstrap';
import CardDropdown from './../../../components/common/CardDropdown';
import AdvanceTableWrapper from './../../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from './../../../components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from './../../../components/common/advance-table/AdvanceTablePagination';
import IconButton from "../../../components/common/IconButton";
import FalconCloseButton from "../../../components/common/FalconCloseButton";
import {Link} from "react-router-dom";
import axios from "axios";
import {toast} from "react-hot-toast";
import {response_error} from "../../../helpers/functions";

const memberTypeData = [
    {
        name: 'Rayhan',
        email: 'rayhan@mail.com',
        acp: 'Yes'
    },
    {
        name: 'Mehedee',
        email: 'mehedee@mail.com',
        acp: 'No',
    },
    {
        name: 'Rayhan',
        email: 'rayhan@mail.com',
        acp: 'Yes'
    },
    {
        name: 'Mehedee',
        email: 'mehedee@mail.com',
        acp: 'No'
    },
    {
        name: 'Rayhan',
        email: 'rayhan@mail.com',
        acp: 'Yes'
    },
    {
        name: 'Mehedee',
        email: 'mehedee@mail.com',
        acp: 'No'
    },
    {
        name: 'Rayhan',
        email: 'rayhan@mail.com',
        acp: 'Yes'
    },
    {
        name: 'Mehedee',
        email: 'mehedee@mail.com',
        acp: 'No',
    },
    {
        name: 'Rayhan',
        email: 'rayhan@mail.com',
        acp: 'Yes'
    },
    {
        name: 'Mehedee',
        email: 'mehedee@mail.com',
        acp: 'No'
    },
    {
        name: 'Rayhan',
        email: 'rayhan@mail.com',
        acp: 'Yes'
    },
    {
        name: 'Mehedee',
        email: 'mehedee@mail.com',
        acp: 'No'
    },
];

const columns = [
    {
        accessor: 'name',
        Header: 'Name',
        headerProps: {className: 'pe-1'},
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const {name} = rowData.row.original
            return (
                <>
                    {name}
                </>
            );
        }
    },
    {
        accessor: 'email',
        Header: 'Email',
        headerProps: {className: 'pe-7'},
        Cell: rowData => {
            const {email} = rowData.row.original
            return (
                <>
                    {email}
                </>
            );
        }
    },
    {
        accessor: 'acp',
        Header: 'ACP',
        headerProps: {className: 'pe-7'},
        Cell: rowData => {
            const {acp} = rowData.row.original
            return (
                <>
                    {acp}
                </>
            );
        }
    },
    {
        accessor: 'none',
        Header: '',
        disableSortBy: true,
        cellProps: {
            className: 'text-end'
        },
        Cell: (rowData) => {
           const {id} = rowData.row.original;
           let link = `/membership/edit/${id}`;


            return (
                <CardDropdown iconClassName="fs--1">
                    <div className="py-2">
                        <Dropdown.Item href={link}>Edit</Dropdown.Item>
                        <Dropdown.Divider as="div"/>
                        <Dropdown.Item className="text-danger" onClick={() => _delete(id) }>
                            Delete
                        </Dropdown.Item>
                    </div>
                </CardDropdown>
            );
        }
    }
];

const MembershipType = () => {

    const [membershipTypes, setMembershipTypes] = useState([]);

    useEffect(() => {
            axios.get(`/membership`)
                .then(response => setMembershipTypes(response.data))
                .catch(error => response_error(error))


    }, [])

    const _delete = (id) => {
        axios.delete(`/membership/${id}`)
            .then(response => setMembershipTypes(response.data.message))
            .catch(error => response_error(error))
    }

    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={membershipTypes}
                sortable
                pagination
                perPage={10}
                _delete
            >
                <Card className="mb-3">
                    <Card.Header>
                        <Row className="flex-between-center">
                            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Membership Types</h5>
                            </Col>
                            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                                <Link to="/membership-types/create">
                                    <IconButton
                                        variant="falcon-default"
                                        size="sm"
                                        icon="plus"
                                        transform="shrink-3"
                                    >
                                        <span className="d-none d-sm-inline-block ms-1">Create Membership Type</span>
                                    </IconButton>
                                </Link>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTablePagination table/>
                    </Card.Footer>
                </Card>
            </AdvanceTableWrapper>
        </>
    );
};

export default MembershipType;