import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import ForeBallBooking from './ForeBallBooking';
import ForeBallBookingChange from "./ForeBallBookingChange";
import PoContact from "./PoContact";
import Ambassador from "./Ambassador";
import {useParams} from 'react-router-dom';
import {GroupContext} from 'context/Context';
import axios from 'axios';
import ClubInfo from './ClubInfo';
import GroupInfo from './GroupInfo';
import CapInfo from './CapInfo';
import GroupHeader from './GroupHeader';
import GroupFooter from './GroupFooter';
import {toast} from "react-hot-toast";
import {response_error} from "../../../../helpers/functions";

const GroupForm = () => {
    const { handleSubmit, setValue, control} = useForm();
    const onSubmit = data => {
        console.log(data);
    };
    const {groupId} = useParams();
    const [group, setGroup] = useState({
        name: '',
        golf_club_contact_name: '',
        golf_club_contact_email: '',
        golf_club_contact_phone: '',
        golf_club_contact_email2: '',
        golf_club_contact_name2: '',
        golf_club_contact_phone2: '',
        po_contact_name: ' ',
        po_contact_email: '',
        golf_club_name: '',
        golf_club_address: '',
        soft_cap: '',
        hard_cap: '',
        vip_cap: '',
        region_id: '',
        site_id: '',
        status: '',
        publish_at: '',
        golden_ticket: true,
        vip: false,
        active_members_count: 0
    });

    const groupHandleChange = e => setGroup(prevState => ({...prevState, [e.target.name]: e.target.value === '1' ? true : e.target.value === '0' ? false : e.target.value}));
    const selectChangeHandler = (e, meta) => {
        setGroup(prevState => ({...prevState, [meta.name]: e.value}))
    }

    const [foreballEmails, setForeballEmails] = useState([]);

    const [count, setCount] = useState(foreballEmails.length > 0 ? foreballEmails[foreballEmails.length - 1]['id'] + 1 : 1);

    const [regions, setRegions] = useState([]);
    const [sites, setSites] = useState([]);

    const groupUpdate = () => {
        let data = {...group, foreballEmails: foreballEmails, ambassadors: ambassadors}
        axios.put(`/groups/${groupId}`, data)
            .then(response => {
                toast.success('Successfully saved.');
            })
            .catch(error => {
                response_error(error);
            })
    }

    const getGroup = () => {
        axios.get(`/groups/${groupId}`)
            .then(response => {
                setGroup(response.data.group);
                setRegions(response.data.regions);
                setSites(response.data.sites);
                setForeballEmails(response.data.foreballEmails);
                setAmbassadors((response.data.ambassadors));
            })
            .catch(error => {
                response_error(error);
            })
    }

    const remind_attendance_confirmation = () => {
        axios.post(`/groups/${groupId}/attendance-confirmation-reminder`)
            .then(response => {
                toast.success(response.data.message);
            })
            .catch(error => {
                response_error(error);
            })
    }

    useEffect(() => {
        getGroup();
    }, [])

    const [ambassadors, setAmbassadors] = useState([]);

    return (
        <GroupContext.Provider
            value={{
                group, setGroup,
                foreballEmails, setForeballEmails,
                groupHandleChange,
                count, setCount,
                ambassadors, setAmbassadors,
                groupId
            }}
        >
            <Form>
                <Row className="g-3">
                    <Col xs={12}>
                        <GroupHeader groupUpdate={groupUpdate} group={group} remind_attendance_confirmation={remind_attendance_confirmation}/>
                    </Col>
                    <Col lg={8}>
                        <ClubInfo
                            group={group}
                            groupHandleChange={groupHandleChange}
                        />

                        <PoContact
                            group={group}
                            groupHandleChange={groupHandleChange}
                        />

                        <ForeBallBooking/>
                        <ForeBallBookingChange/>
                        <Ambassador/>
                    </Col>

                    <Col lg={4}>
                        <div>
                            <GroupInfo
                                group={group}
                                setGroup={setGroup}
                                groupHandleChange={groupHandleChange}
                                selectChangeHandler={selectChangeHandler}
                            />

                            <CapInfo
                                group={group}

                                groupHandleChange={groupHandleChange}
                                selectChangeHandler={selectChangeHandler}
                                regions={regions}
                                sites={sites}
                            />
                        </div>
                    </Col>
                    <Col>
                        <GroupFooter groupUpdate={groupUpdate} group={group}/>
                    </Col>
                </Row>
            </Form>
        </GroupContext.Provider>
    );
};

export default GroupForm;
