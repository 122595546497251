import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from '../../../../components/common/FalconCardHeader';
import {Card, Button, Row, Col, Modal, Form} from 'react-bootstrap';
import FalconCloseButton from '../../../../components/common/FalconCloseButton';
import Select from "react-select";
import EventList from './EventLists';
import SimpleBarReact from "simplebar-react";
import axios from "axios";
import {response_error} from "../../../../helpers/functions";
import {useParams} from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment/moment";
import {MemberContext} from "../../../../context/Context";
import {contactTypeOptions} from "../../../../data/options";
import {FaPlus} from "react-icons/all";

const RightEvents = ({cardTitle, ...rest}) => {
    const {member} = useContext(MemberContext);
    const [formData, setFormData] = useState({});
    const [show, setShow] = useState(false);
    const [groupOptions, setGroupOptions] = useState([]);
    const [eventOptions, setEventOptions] = useState([]);
    const [events, setEvents] = useState([]);
    const [substitutes, setSubstitutes] = useState(null);
    const defaultOption = {value: '0', label: (<><FaPlus /> Add Substitute</>)};
    const [substituteOptions,setSubstituteOptions] = useState([defaultOption]);


    let { memberId } = useParams();

    const getSubstitutes = () => {
        axios.get(`/members/${memberId}/substitutes`)
            .then((res) => {
                setSubstituteOptions([defaultOption, ...res.data.map(substitute => {
                    return {value: substitute.id, label: substitute.name}
                })]);
            })
            .catch((err) => {
                response_error(err);
            });
    };

    const getGroupOption = () => {
        axios.get('/groups/valid')
            .then(response => {
                setGroupOptions(response.data.groups.map(group => {
                    return {value: group.id, label: group.name};
                }));
            })
            .catch(error => {
                response_error(error);
            })
    }

    const getEventOption = (group_id) => {
        axios.get(`/groups/${group_id}/events`)
            .then(response => {
                setEventOptions(response.data.map(event => {
                    return {value: event.id, label: event.event_date + ' ' + event.event_time};
                }));
            })
            .catch(error => {
                response_error(error);
            })
    }

    const addEventHandler = () => {
        setShow(true);
        getGroupOption();
    }

    const groupChangeHandler = (e, meta) => {
        getEventOption(e.value);
        setFormData(prevState => ({...prevState, group_id: e.value}))
    }

    const selectChangeHandler = (e, meta) => {
        setFormData(prevState => ({...prevState, [meta.name]: e.value}))
    }

    const inputHandler = (e) => {
        setFormData(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    const substituteChangeHandler = (e, meta) => {
        setFormData(prevState => ({...prevState, substitute: {...prevState.substitute, id: e.value}}))
    }

    const substituteInputHandler = (e) => {
        setFormData(prevState => ({...prevState, substitute: {...prevState.substitute, [e.target.name]: e.target.value}}))
    }

    const saveEvent = (e) => {
        e.preventDefault();
        axios.post(`/members/${memberId}/events`, {...formData})
            .then(response => {
                toast.success(response.data.message);
                setShow(false);
                showEvent();
            })
            .catch(error => {
                response_error(error);
            })
    }

    const showEvent = () => {
        axios.get(`/members/${memberId}/events`)
            .then(response => {
                setEvents(response.data.map(attendee => {
                    let date = moment(attendee.event_date);
                    return {
                        id: attendee.id,
                        calendar: { month: date.format('MMM'), day: date.format('D') },
                        group_name: attendee.group_name,
                        attending_as: attendee.attending_as,
                        attending: attendee.attending,
                        time: attendee.event_time,
                        substitute: attendee.substitute,
                    }
                }))
            })
            .catch(error => {
                response_error(error);
            })
    }


    const deleteEvent = (attendee_id) => {
        axios.delete(`/members/${memberId}/events/${attendee_id}`)
            .then(response => {
                toast(response.data.message);
                showEvent();
            })
            .catch(error => {
                response_error(error);
            })
    };

    useEffect(() => {
        showEvent();
        getSubstitutes();
    },[memberId])

    return (
        <>
            <Row className="g-0 mb-3">
                <Col lg={12}>
                    <Card {...rest}>
                        <Row>
                            <Col lg={6} md={6} sm={6}>
                                <FalconCardHeader as="5" title={cardTitle}/>
                            </Col>
                            <Col lg={6} md={6} sm={6} className="text-end">
                                <Card.Header as="h5">
                                    <Button size="sm" variant="falcon-default" className="me-2"
                                            onClick={addEventHandler}> Add Meeting </Button>
                                </Card.Header>
                            </Col>
                        </Row>

                        <div className="border-dashed-bottom"></div>
                        {
                            events && (
                                <SimpleBarReact style={{maxHeight: '19rem'}}>
                                    <Card.Body className="bg-light">
                                        {events.map((event, index) => (
                                            <EventList
                                                key={event.id}
                                                details={event}
                                                isLast={index === events.length - 1}
                                                deleteEvent={deleteEvent}
                                                edit={addEventHandler}
                                            />
                                        ))}
                                    </Card.Body>
                                </SimpleBarReact>
                            )
                        }


                    </Card>
                </Col>
            </Row>
            <Modal
                size=" "
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add Event
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => saveEvent(e)}>
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Group</Form.Label>
                                    <Select options={groupOptions}
                                            onChange={groupChangeHandler}
                                            name='group_id'
                                            value={
                                                groupOptions.filter(option =>
                                                    option.value === formData.group_id)
                                            }
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Event</Form.Label>
                                    <Select options={eventOptions}
                                            name='event_id'
                                            value={
                                                eventOptions.filter(option =>
                                                    option.value === formData.event_id)
                                            }
                                            onChange={selectChangeHandler}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Attending as</Form.Label>
                                    <Select options={contactTypeOptions}
                                            value={contactTypeOptions.filter(option => option.value === formData.attending_as)}
                                            name={'attending_as'}
                                            onChange={selectChangeHandler}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Attending</Form.Label>
                                    <div className="d-block">
                                        <Form.Check inline type='radio' id='attending_yes' label='Yes'
                                                    name='attending'
                                                    value="YES"
                                                    checked={formData.attending === 'YES' ? 'checked' : ""}
                                                    onChange={inputHandler}
                                        />
                                        <Form.Check inline type='radio' id='attending_no' label='No'
                                                    name='attending'
                                                    value="NO"
                                                    checked={formData.attending === 'NO' ? 'checked' : ""}
                                                    onChange={inputHandler}
                                        />
                                        <Form.Check inline type='radio' id='attending_dont_know' label="Don't Know"
                                                    value={"DON'T KNOW"}
                                                    name='attending'
                                                    checked={formData.attending === "DON'T KNOW" ? 'checked' : ""}
                                                    onChange={inputHandler}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Substitute</Form.Label>
                                    <Select options={substituteOptions}
                                            name='substitute_id'
                                            value={
                                                substituteOptions.filter(option =>
                                                    option.value === formData.substitute?.id)
                                            }
                                            onChange={substituteChangeHandler}
                                    />
                                </Form.Group>
                            </Col>
                            {formData.substitute && formData.substitute?.id === '0' && (
                                <Row>
                                    <Col lg={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                name='name'
                                                type="text"
                                                placeholder="Name"
                                                onChange={substituteInputHandler}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control
                                                name='phone'
                                                type="text"
                                                placeholder="Phone"
                                                onChange={substituteInputHandler}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                name='email'
                                                type="text"
                                                placeholder="Email"
                                                onChange={substituteInputHandler}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Handicap</Form.Label>
                                            <Form.Control
                                                name='handicap'
                                                type="text"
                                                placeholder="Handicap"
                                                onChange={substituteInputHandler}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}
                            <Col lg={12}>
                                <Form.Group className="mb-3 text-end">
                                    <Button variant="falcon-default" size="sm" type="submit">Submit</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

RightEvents.propTypes = {
    cardTitle: PropTypes.string.isRequired,
    events: PropTypes.arrayOf(PropTypes.shape(EventList.propTypes))
};

export default RightEvents;
