import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Form, Modal, Row} from 'react-bootstrap';
import SaveBar from "../../../components/custom/save-bar";
import Flex from "../../../components/common/Flex";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from "react-router-dom";
import SoftBadge from "../../../components/common/SoftBadge";
import DatePicker from "react-datepicker";
import Select from "react-select";
import FalconCloseButton from "../../../components/common/FalconCloseButton";
import Confirm from "../../../components/custom/confirm";

const NationalEventBookings = () => {
    const [show, setShow] = useState(false);
    const [date, setDate] = useState(null);
    const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);
     const groupOptions = [
        {value: 'Option 1', label: 'Option 1'},
        {value: 'Medium 2', label: 'Option 2'},
        {value: 'Option 3', label: 'Option 3'},
        {value: 'Option 4', label: 'Option 4'},
        {value: 'Option 5', label: 'Option 5'}
    ];
    const OpenModal = () => {
        setShow(true);
    }
    const showConfirmation = () => {
        setDeleteConfirmationShow(true)
      };

    return (
        <>
            <SaveBar
                title="National Event Bookings"
            />
            <Row className="gx-2 gy-3 pt-3">
                <Col md="4">
                    <Card className="mb-3 card_no_shadow">
                        <Card.Body>
                            <Flex>
                                <div className="flex-1 position-relative">
                                    <h6 className="fs-0 mb-0">
                                        <Link to="#">
                                            <span className="me-1">Aberdeen</span>
                                            <span className="badge rounded-pill"></span>
                                        </Link>
                                    </h6>
                                    <p className="mb-1">Status: <SoftBadge pill bg='success'>Active</SoftBadge>
                                    </p>
                                    <p className="text-1000 mb-0">Time: 07:00</p>
                                </div>
                                <div className="end-0 top-50 pe-3">
                                    <Button variant="light" size="sm" className="border-300 text-600 me-2" onClick={OpenModal}>
                                        <FontAwesomeIcon icon="edit"/>
                                    </Button>
                                    <Button variant="light" size="sm" className="border-300 text-600" onClick={showConfirmation}>
                                        <FontAwesomeIcon icon="trash-alt"/>
                                    </Button>
                                </div>
                            </Flex>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Confirm body="Do you want to delete your Event?" title="Delete!" show={deleteConfirmationShow} setShow={setDeleteConfirmationShow}/>
            <Modal
                size=" "
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Edit Booking
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Group</Form.Label>
                                    <Select options={groupOptions}
                                            name='group_id'
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Date</Form.Label>
                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => setDate(date)}
                                        className='form-control'
                                        placeholderText="Select Date"
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Time</Form.Label>
                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => setDate(date)}
                                        className='form-control'
                                        placeholderText="Select Time"
                                        timeIntervals={5}
                                        dateFormat="h:mm aa"
                                        showTimeSelect
                                        showTimeSelectOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3 text-end">
                                    <Button variant="falcon-default" size="sm" type="submit">Submit</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default NationalEventBookings;