import React, {useContext, useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import {Button, Card, Col, Form, Modal, Row} from 'react-bootstrap';
import Flex from "../../../components/common/Flex";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from "react-router-dom";
import SoftBadge from "../../../components/common/SoftBadge";
import DatePicker from "react-datepicker";
import Select from "react-select";
import FalconCloseButton from "../../../components/common/FalconCloseButton";
import Confirm from "../../../components/custom/confirm";
import axios from "axios";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {response_error} from "../../../helpers/functions";
import moment from "moment";
import {toast} from "react-hot-toast";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import './foreball.css';

const schema = yup.object({
    event_name: yup.object().shape({
        value: yup.string().required('Group is required'),
        label: yup.string().required('Group is required')
    }),
    schedule_date: yup.date().required('Date is required'),
    time_from: yup.string().required('Time From is required'),
    time_to: yup.string().required('Time To is required'),
    notes: yup.string().nullable(),
    id: yup.string().nullable()
})

const ForeBallBookings = () => {
    const [formData, setFormData] = useState({});

    const {
        register,
        handleSubmit,
        watch,
        reset,
        control,
        formState: {errors}
    } = useForm({
        resolver: yupResolver(schema),
        values: formData
    });

    const [show, setShow] = useState(false);
    const [date, setDate] = useState(null);
    const [timeFrom, setTimeFrom] = useState(null);
    const [timeTo, setTimeTo] = useState(null);
    const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [groupOptions, setGroupOptions] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [availableDates, setAvailableDates] = useState([]);
    const [availableTimes, setAvailableTimes] = useState({
        time_from: moment(),
        time_to: moment()

    });

    const onSubmit = (data) => {
        console.log(data);
        let _data = data;
        _data.group_name = data.event_name.label;
        _data.schedule_date = moment(data.schedule_date).format('YYYY-MM-DD')

        if (data.id) {
            axios.put('/member/_4balls/bookings/' + data.id, data)
                .then((response) => {
                    console.log(response);
                    getBookings();
                    setShow(false);
                })
                .catch((error) => {
                    response_error(error)
                });
            return;
        }

        axios.post('/member/_4balls/bookings', data)
            .then((response) => {
                console.log(response);
                getBookings();
                setShow(false);
            })
            .catch((error) => {
                response_error(error)
            });
    }

    const OpenModal = () => {
        setShow(true);
    }
    const showConfirmation = (id) => {
        setCurrentId(id)
        setDeleteConfirmationShow(true)
    };

    useEffect(() => {
        axios.get('/member/groups')
            .then((response) => {
                const options = response.data.map((group) => {
                    return {
                        value: group.name,
                        label: group.name
                    }
                });
                setGroupOptions(options);
            })
            .catch((error) => {
                console.log(error);
            });

        getBookings();
    }, [])

    const getBookings = () => {
        axios.get('/member/_4balls/bookings/')
            .then((response) => {
                setBookings(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleEdit = (id) => {
        OpenModal();
        // return;
        const current_booking = {...bookings.find(booking => booking.id === id)}
        const name = current_booking.event_name;
        current_booking.event_name = {
            value: name,
            label: name
        }

        setFormData(current_booking);
        // setFormData(current_booking)
        // OpenModal()
    }

    const getAvailableDate = (event_name) => {
        axios.get('/member/_4balls/available-dates/?event_name='+ event_name)
            .then((response) => {
                let dates = response.data.data.dates;
                setAvailableDates(dates.length > 0 ? dates : [])
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleGroupChange = (field, value) => {
        field.onChange(value);
        getAvailableDate(value.value);
    }

    const handleDateChange = (field, date) => {
        field.onChange(date);
        const date_fmt = moment(date).format('YYYY-MM-DD');

        availableDates.find((availableDate) => {
            if (availableDate.date === date_fmt) {

                setAvailableTimes({
                    time_from: moment(`${availableDate.date} ${availableDate.time_from}`, "YYYY-MM-DD hh:mm A"),
                    time_to: moment(`${availableDate.date} ${availableDate.time_to}`, "YYYY-MM-DD hh:mm A"),
                })
            }
        })
    }

    const handleDelete = () => {
        axios.delete('/member/_4balls/bookings/' + currentId)
            .then(response => {
                setDeleteConfirmationShow(false)
                toast.success(response.data.message)
                getBookings()
            })
            .catch(error => {
                response_error(error)
            })
    }


    return (
        <>
            <Card>
                <Card.Header as="h5">
                    <Row className="g-3">
                        <Col lg={8}>4 Ball Bookings</Col>
                        <Col lg={4} className="text-end">
                            <Button
                                onClick={OpenModal}
                                variant="falcon-default"
                                size="sm"
                                className="me-2"
                                type="button"
                            >
                                Add 4 Ball Booking
                            </Button>
                        </Col>
                    </Row>
                </Card.Header>
            </Card>

            <Row className="gx-2 gy-3 pt-3">
                {
                    bookings.map((booking) => {
                        return (
                            <Col md="4" key={booking.id}>
                                <Card className="mb-3 card_no_shadow">
                                    <Card.Body>
                                        <Flex>
                                            <div className="flex-1 position-relative">
                                                <h6 className="fs-0 mb-0">
                                                    <Link to="#">
                                                        <span className="me-1">{booking.event_name}</span>
                                                        <span className="badge rounded-pill"></span>
                                                    </Link>
                                                </h6>
                                                <p className="mb-1">Status: <SoftBadge pill
                                                                                       bg='success'>{booking.status}</SoftBadge>
                                                </p>
                                                <p className="mb-1">Schedule Date: {booking.schedule_date}</p>
                                                {
                                                    booking.schedule_time &&
                                                    <p className="mb-1">Time: {booking.schedule_time}</p>
                                                }
                                                {
                                                    !booking.schedule_time &&
                                                    <p className="mb-1">Time: {booking.time_from} - {booking.time_to}</p>
                                                }

                                                {
                                                    booking.notes && <p className="mb-1">Notes: {booking.notes}</p>
                                                }
                                                {
                                                    booking.notes_for_member &&
                                                    <p className="mb-1">Notes from club: {booking.notes_for_member}</p>
                                                }

                                            </div>
                                            <div className="end-0 top-50 pe-3">
                                                <Button variant="light" size="sm" className="border-300 text-600 me-2"
                                                        onClick={() => handleEdit(booking.id)}>
                                                    <FontAwesomeIcon icon="edit"/>
                                                </Button>
                                                <Button variant="light" size="sm" className="border-300 text-600"
                                                        onClick={() => showConfirmation(booking.id)}>
                                                    <FontAwesomeIcon icon="trash-alt"/>
                                                </Button>
                                            </div>
                                        </Flex>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })
                }

            </Row>
            <Confirm body="Do you want to delete your 4 Ball Booking?" title="Delete!" show={deleteConfirmationShow}
                     setShow={setDeleteConfirmationShow} handleConfirm={handleDelete}/>
            <Modal
                size=" "
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Edit 4 Ball Booking
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <input type="hidden" name="id"/>
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Group</Form.Label>
                                    <Controller
                                        name="event_name"
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                            <Select
                                                {...field}
                                                value={field.value}
                                                options={groupOptions}
                                                onChange={(value) => handleGroupChange(field, value)}
                                                className={(!!errors.event_name ? 'is-invalid' : '')}
                                            />
                                        )}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.event_name?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {
                                watch('event_name')?.value && (
                                    <>
                                        <Col lg={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Date</Form.Label>
                                                <Controller
                                                    name="schedule_date"
                                                    control={control}
                                                    render={({ field: { ref, ...field } }) => (
                                                        <DatePicker
                                                            {...field}
                                                            includeDates={availableDates.length > 0 ? availableDates.map(date => moment(date.date).toDate()): []}
                                                            dateFormat={'yyyy-MM-dd'}
                                                            className={'form-control ' + (!!errors.schedule_date ? 'is-invalid' : '')}
                                                            selected={field.value ? new Date(field.value): null}
                                                            onChange={(date) => handleDateChange(field, date)}
                                                            inputRef={ref}
                                                            placeholderText="Select Date"
                                                        />
                                                    )}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.scheduled_date && errors.scheduled_date.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Time From</Form.Label>
                                                <Controller
                                                    name="time_from"
                                                    control={control}
                                                    render={({ field: { ref, ...field } }) => (
                                                        <DatePicker
                                                            {...field}
                                                            value={field.value}
                                                            minTime={setHours(setMinutes(new Date(), availableTimes.time_from.minute()), availableTimes.time_from.hour())}
                                                            maxTime={setHours(setMinutes(new Date(), availableTimes.time_to.minute()), availableTimes.time_to.hour())}
                                                            onChange={(date) => field.onChange(date ? moment(date).format('HH:mm') : null)}
                                                            className={'form-control ' + (!!errors.time_from ? 'is-invalid' : '')}
                                                            placeholderText="Select Time"
                                                            timeIntervals={5}
                                                            dateFormat="HH:mm"
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            error={!!errors.time_from}
                                                            inputRef={ref}
                                                        />
                                                    )}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.time_from && errors?.time_from?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Time To</Form.Label>
                                                <Controller
                                                    name="time_to"
                                                    control={control}
                                                    render={({ field: { ref, ...field } }) => (
                                                        <DatePicker
                                                            {...field}
                                                            value={field.value}
                                                            minTime={setHours(setMinutes(new Date(), 0), 17)}
                                                            maxTime={setHours(setMinutes(new Date(), 30), 20)}
                                                            onChange={(date) => field.onChange(date ? moment(date).format('HH:mm') : null)}
                                                            className={'form-control ' + (!!errors.time_from ? 'is-invalid' : '')}
                                                            placeholderText="Select Time"
                                                            timeIntervals={5}
                                                            dateFormat="HH:mm"
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            error={!!errors.time_to}
                                                            inputRef={ref}
                                                        />
                                                    )}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.time_to && errors?.time_to?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group className="mb-3" controlId="notes">
                                                <Form.Label>Notes</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    {...register('notes')}
                                                    placeholder="Enter notes here"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </>
                                )

                            }

                            <Col lg={12}>
                                <Form.Group className="mb-3 text-end">
                                    <Button variant="falcon-default" size="sm" type="submit">Submit</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ForeBallBookings;
