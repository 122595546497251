import {useState} from "react";
import {Button, Modal} from "react-bootstrap";

const Confirm = ({setShow, show, handleConfirm, title, body}) => {

    const handleClose = () => setShow(false);

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Body className="pt-4">
                    {title && (
                        <h5>{title}</h5>
                    )}
                    {
                        body && (
                            <p>{body}</p>
                        )
                    }
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button  variant="falcon-default" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleConfirm}>OK</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Confirm;