import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import moment from "moment/moment";

const MemberActivitiesLog = ({member}) => {
    return (
        <>
            <Card className="mt-3">
                <Row className="mb-3">
                    <Col md={12}>
                        <Card.Header className="bg-light">
                            <Flex justifyContent="between">
                                <h5 className="mb-1 mb-md-0">Activities</h5>
                            </Flex>
                        </Card.Header>
                        <Card.Body className="p-0">
                            {
                                member?.last_new_joiner?.sent_to_fusion_at && (
                                    <Link className="notification border-x-0 border-bottom-0 border-300 rounded-0" to="#!" style={{boxShadow: 'none'}}>
                                        <div className="notification-avatar me-3">
                                            <div class="avatar avatar-xl me-3">
                                                <div class="avatar-emoji rounded-circle ">
                                                    <span role="img" aria-label="Emoji">👕</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="notification-body">
                                            <p className="mb-1"><strong>Joiner Pack Shipped</strong></p>
                                            <span className="notification-time">{moment(member?.last_new_joiner?.sent_to_fusion_at).format('LL')}</span>
                                        </div>
                                    </Link>
                                )
                            }
                            {
                                (member?.last_valid_winner && member?.last_valid_winner?.won_at) && (
                                    <Link className="notification border-x-0 border-bottom-0 border-300 rounded-0" to="#!" style={{boxShadow: 'none'}}>
                                        <div className="notification-avatar me-3">
                                            <div class="avatar avatar-xl me-3">
                                                <div class="avatar-emoji rounded-circle ">
                                                    <span role="img" aria-label="Emoji">🏌🏻‍♂️</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="notification-body">
                                            <p className="mb-1"><strong>Red Shirt Shipped</strong></p>
                                            <span className="notification-time">{moment(member?.last_valid_winner?.won_at).format('LL')}</span>
                                        </div>
                                    </Link>
                                )
                            }


                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default MemberActivitiesLog;


