import React, {useEffect, useState, Fragment} from 'react';
import {Card, Dropdown, Button, Col, Form, Row} from 'react-bootstrap';
import CardDropdown from './../../../components/common/CardDropdown';

import axios from "axios";
import {toast} from "react-hot-toast";
import {next_icon, previous_icon, response_error} from "../../../helpers/functions";
import classNames from "classnames";
import SoftBadge from "../../../components/common/SoftBadge";
import ReactPaginate from "react-paginate";
import EventFilterForm from "./EventTable/EventFilterForm";


const Events = () => {

    const [param, setParam] = useState({
        groups: [],
        event_date: '',
        event_mode: {},
    });

    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const handlePageClick = page => {
        setPage(page.selected + 1);
    }


    const [events, setEvents] = useState([]);

    const get_events = () => {
        const _param = {...param};
        _param.page = page;
        _param.groups = _param.groups.map(item => item.value);
        _param.event_mode = _param.event_mode.value;

        axios.get('/events', {params: _param})
            .then(response => {
                setEvents(response.data.events.data);
                let total_page = parseInt(response.data.events.total) / parseInt(response.data.events.per_page);
                total_page = total_page > 0 ? Math.ceil(total_page) : 0;
                setPageCount(total_page);
            })
            .catch(error => {
                response_error(error);
            })
    }

    useEffect(() => {
        get_events();
    }, [page])

    const _delete = (id) => {
        axios.delete(`/events/${id}`)
            .then(response => {
                toast.success(response.data.message);
                get_events();
            })
            .catch(error => response_error(error))
    }

    return (
        <Fragment>
            <Card className="mb-3">
                <Card.Header>
                    <h5 className="fs-0 mb-3 text-nowrap py-2 py-xl-0">Events</h5>
                    <EventFilterForm
                        setParam={setParam}
                        param={param}
                        getEvents={get_events}
                    />
                </Card.Header>
                <Card.Body className="pt-0">
                    <div className="table-responsive">
                        <table className="fs--1 mb-0 table table-sm table-striped overflow-hidden" role="table">
                            <thead className="bg-200 text-900 text-nowrap align-middle">
                            <tr>
                                <th>ID</th>
                                <th>Group</th>
                                <th>Event Date</th>
                                <th>Event Time</th>
                                <th>Is Valid</th>
                                <th>Is Closed</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {

                                events.map(event => {
                                    let link = `/events/edit/${event.id}`;
                                    return (
                                        <tr className="align-middle white-space-nowrap" role="row" key={event.id}>

                                            <td role="cell" className="py-2"><a href={link}>{event.id}</a></td>
                                            <td role="cell"><a href={link}>{event.name}</a></td>
                                            <td role="cell">{event.event_date}</td>
                                            <td role="cell">{event.event_time}</td>
                                            <td role="cell">
                                                <SoftBadge pill bg={classNames({
                                                    success: event.is_valid === 1,
                                                    danger: event.is_valid === 0,
                                                })} className='d-block'>
                                                    {event.is_valid === 1 ? 'Valid' : 'Invalid'}</SoftBadge>
                                            </td>
                                            <td role="cell">
                                                <SoftBadge pill bg={classNames({
                                                    success: event.is_closed === 0,
                                                    danger: event.is_closed === 1,
                                                })} className='d-block'>
                                                    {event.is_closed === 1 ? 'Closed' : 'Open'}</SoftBadge>
                                            </td>

                                            <td role="cell" className="text-end">
                                                <CardDropdown iconClassName="fs--1" drop="start">
                                                    <div className="py-2">
                                                        <Dropdown.Item className="text-danger"
                                                                       onClick={() => _delete(event.id)}>
                                                            Delete
                                                        </Dropdown.Item>
                                                    </div>
                                                </CardDropdown>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            </tbody>
                        </table>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <ReactPaginate
                        previousLabel={previous_icon()}
                        nextLabel={next_icon()}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination mb-0 mx-1'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </Card.Footer>
            </Card>
        </Fragment>
    );
};

export default Events;