import {Card} from "react-bootstrap";
import EventFilterForm from "./EventTable/EventFilterForm";
import React, {useEffect, useState, Fragment} from "react";
import Events from "./index";
import axios from "axios";
import {response_error} from "../../../helpers/functions";
import './events-table.css'
import * as PropTypes from "prop-types";

const FutureEvents = () => {

    const [groups, setGroups] = useState([
        {
            name: '',
            events: [
                {
                    id: '',
                    event_date: ''
                }
            ]
        }
    ])

    useEffect(() => {
        axios.get('/groups/with-events')
            .then(response => {
                setGroups(response.data)
            })
            .catch(error => {
                response_error(error);
            })
    }, [])

    return (
        <Fragment>
            <Card className="mb-3">
                <Card.Header>
                    <h5 className="fs-0 mb-3 text-nowrap py-2 py-xl-0">Future Events</h5>
                </Card.Header>
                <Card.Body className="pt-0">
                    <div className="future_events_table_responsive">
                        <div className="future_events_table">
                            <table>
                                {groups.map(group => (
                                    <tr>
                                        <th>{group.name}</th>
                                        {
                                            group.events.map(event => (
                                                <td>{event.event_date}</td>
                                            ))
                                        }
                                    </tr>
                                ))}

                            </table>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Fragment>
    )
}

export default FutureEvents;