import React, {Fragment, useEffect, useState} from 'react';
import {Card, Col, Nav, Row, Tab, Table} from 'react-bootstrap';
import JSONView from "react-json-view";
import ActivitiesFilter from "./activitiesFilter";
import axios from "axios";
import {response_error} from "../../../helpers/functions";


const Activities = () => {
    const [selectedRow, setSelectedRow] = useState();
    const [selectedRowIndex, setSelectedRowIndex] = useState(0);

    const [activities, setActivities] = useState([]);
    const [params, setParams] = useState({
        models: [],
        causer_id: null,
        date: null
    });

    const handleRowClick = (index) => {
        activities.map((activity) => {
            if (activity.id === index) {
                setSelectedRow(JSON.stringify(activity.properties));
            }
        });

        // setSelectedRow(contentData[index]);
        setSelectedRowIndex(index);
    };

    useEffect(() => {
        axios.get('/activities', {
            params: params
        })
            .then(res => {
                setActivities(res.data.data);
            })
            .catch(error => {
                response_error(error);
            });
    }, [params]);

    return (
        <Fragment>
            <Row className="g-0 mt-3">
                <Col lg={12}>
                    <Row className="g-0">
                        <Col lg={12}>
                            <Card className="h-lg-100">
                                <Card.Header as="h5">Activities Log</Card.Header>
                                {/*<div className="border-dashed-bottom"></div>*/}
                                <Card.Body>
                                    <Row>
                                        <ActivitiesFilter setParams={setParams}/>
                                        <Col sm={6}>
                                            <div className="table-responsive">
                                            <Table borderless hover
                                                   className="fs--1 mb-0 overflow-hidden table table-md table-striped"
                                                   role="table">
                                                <thead className="bg-200 text-900 text-nowrap align-middle">
                                                <tr>
                                                    <th>Model</th>
                                                    <th>Description</th>
                                                    <th>Date</th>
                                                    <th>Updated By</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {activities.map((activity) => (
                                                    <tr
                                                        key={activity.id}
                                                        onClick={() => handleRowClick(activity.id)}
                                                        className={selectedRowIndex === activity.id ? 'table-primary' : ''}
                                                        style={{cursor: 'pointer'}}
                                                    >
                                                        <td role="cell">{activity.subject_type}</td>
                                                        <td role="cell">{activity.description}</td>
                                                        <td role="cell">{activity.updated_at}</td>
                                                        <td role="cell">{activity.causer.first_name} {activity.causer.last_name}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                                </div>
                                        </Col>
                                        <Col sm={6}>
                                            {selectedRow && (
                                                <Card.Body className="pt-0">
                                                    <JSONView
                                                        src={JSON.parse(selectedRow)}
                                                        theme="monokai"
                                                    />
                                                </Card.Body>
                                            )}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Fragment>
    );
}

export default Activities;