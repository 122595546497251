import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from "react-select";
import { Link } from 'react-router-dom';
import {Button, Card, Col, Dropdown, Form, Row} from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import axios from "axios";
import {response_error} from "../../../helpers/functions";
import {useNavigate} from "react-router-dom";

const AddMemberForm = () => {

  const [isOpen, setIsOpen] = useState(false);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const [memberShipType, setMemberShipType] = useState([]);

  let defaultFormData = {
        first_name: '',
        last_name: '',
        email: '',
        phone1: '',
        membership_id: '',
    }

    const [formData, setFormData] = useState(defaultFormData);

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const selectChangeHandler = (e, meta) => {
        setFormData({
            ...formData,
            [meta.name]: e.value
        });
    }

    const navigate = useNavigate();

    const handleSubmit = e => {
        e.preventDefault();
        axios.post('/members', formData)
            .then(response => {
                setIsOpen(false);
                setFormData(defaultFormData);
                navigate('/members/edit/' + response.data.id);
            })
            .catch(error => {
                response_error(error);
            });
    };

  useEffect(() => {
      axios.get('/membership')
          .then(response => {
              let data = response.data.map(item => {
                  return {
                      value: item.id,
                      label: item.name
                  }
              });

              setMemberShipType(data);
          })
          .catch(error => {
              response_error(error);
          })
  }, []);


  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
      >
        <FontAwesomeIcon icon="plus" transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{minWidth: '30rem'}}
        >
            <FalconCardHeader
                className="card-header"
                title="Add Member"
                titleTag="h6"
                light={false}
            />
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                <Row>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <h6 className="text-1100">First Name</h6>
                            <Form.Control placeholder="name"
                                          value={formData.first_name}
                                          name="first_name"
                                          type="text"
                                          onChange={handleFieldChange}

                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <h6 className="text-1100">Last Name</h6>
                            <Form.Control placeholder="name"
                                          value={formData.last_name}
                                          name="last_name"
                                          type="text"
                                          onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <h6 className="text-1100">Email</h6>
                            <Form.Control placeholder="Email"
                                          value={formData.email}
                                          name="email"
                                          type="text"
                                          onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <h6 className="text-1100">Phone</h6>
                            <Form.Control placeholder="Phone"
                                          value={formData.phone1}
                                          name="phone1"
                                          type="text"
                                          onChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <h6 className="text-1100">Membership Type</h6>
                           <Select options={memberShipType}
                                   name={'membership_id'}
                           onChange={selectChangeHandler}
                           />
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="text-center">
                        <Button variant='falcon-primary' className='me-2 mb-1' type="submit">Save</Button>
                    </Col>
                </Row>
                </Form>
            </Card.Body>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AddMemberForm;
