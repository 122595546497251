export const leadSource = [
        {value: 'VIP REGISTRATION FORM', label: 'VIP REGISTRATION FORM'},
        {value: 'staging.fore-business.com', label: 'staging.fore-business.com'},
        {value: 'trello.com', label: 'trello.com'},
        {value: 'staging.fore-business.com', label: 'staging.fore-business.com'},
        {value: 'duckduckgo.com', label: 'duckduckgo.com'},
        {value: 'AOL', label: 'AOL'},
        {value: 'Guest Area', label: 'Guest Area'},
        {value: 'Live SMS to +441566232048', label: 'Live SMS to +441566232048'},
        {value: 'STANDARD JOINER FORM', label: 'STANDARD JOINER FORM'},
        {value: 'GOLDEN TICKET', label: 'GOLDEN TICKET'},
        {value: 'T.AD - Florida', label: 'T.AD - Florida'},
        {value: 'Jon Geypens', label: 'Jon Geypens'},
        {value: 'Billy Perskaas', label: 'Billy Perskaas'},
        {value: 'Bob McCool', label: 'Bob McCool'},
        {value: 'Referral-Paige Mott', label: 'Referral-Paige Mott'},
        {value: 'Matt Porter', label: 'Matt Porter'},
        {value: 'Gerald Tafoya', label: 'Gerald Tafoya'},
        {value: 'Referral-Matthew Powell', label: 'Referral-Matthew Powell'},
        {value: 'Travis Stevens', label: 'Travis Stevens'},
        {value: 'Stacey Dowling', label: 'Stacey Dowling'},
        {value: 'FB Post', label: 'FB Post'},
        {value: 'Online enquiry from LI', label: 'Online enquiry from LI'},
        {value: 'Referral-Michael Bernstein', label: 'Referral-Michael Bernstein'},
        {value: 'Cheryl Pegues', label: 'Cheryl Pegues'},
        {value: 'Cameron Dunford', label: 'Cameron Dunford'},
        {value: 'COI-Eric Fritzke', label: 'COI-Eric Fritzke'},
        {value: 'Neighbor', label: 'Neighbor'},
        {value: 'Paige Mott', label: 'Paige Mott'},
        {value: 'Britt Benton', label: 'Britt Benton'},
        {value: 'Womens Chamber', label: 'Womens Chamber'},
        {value: 'Referral', label: 'Referral'},
        {value: 'Jon Rottier', label: 'Jon Rottier'},
        {value: 'Stacey Sorensen', label: 'Stacey Sorensen'},
        {value: 'Referral-Bryce Babcock', label: 'Referral-Bryce Babcock'},
        {value: 'Doug Hartley', label: 'Doug Hartley'},
        {value: 'Speed Networking', label: 'Speed Networking'},
        {value: 'Jill Isensee', label: 'Jill Isensee'},
        {value: 'FB Ad', label: 'FB Ad'},
        {value: 'Bryce Babcock', label: 'Bryce Babcock'},
        {value: 'Networking group', label: 'Networking group'},
        {value: 'Terry Swanland', label: 'Terry Swanland'},
        {value: 'Matthew Powell', label: 'Matthew Powell'},
        {value: 'COI', label: 'COI'},
        {value: 'Referral-Ryan Holocher', label: 'Referral-Ryan Holocher'},
        {value: '441256830471 - live-msg - SMS', label: '441256830471 - live-msg - SMS'},
        {value: '447427544122 - default - SMS', label: '447427544122 - default - SMS'},
        {value: 'T.AD - Linkedin', label: 'T.AD - Linkedin'},
        {value: 'T.AD - Dubai', label: 'T.AD - Dubai'},
        {value: '441256270108 - n - SMS', label: '441256270108 - n - SMS'},
        {value: 'thatgolfingvlog.com', label: 'thatgolfingvlog.com'},
        {value: '16312121526 - billing - SMS', label: '16312121526 - billing - SMS'},
        {value: 'www.theludisgroup.com', label: 'www.theludisgroup.com'},
        {value: 'T.AD', label: 'T.AD'},
        {value: '441566232048 - Phone', label: '441566232048 - Phone'},
        {value: 'LUDIS GROUP', label: 'LUDIS GROUP'},
        {value: 'ww.fore-business.com', label: 'ww.fore-business.com'},
        {value: 'www.williamsfund.co.uk', label: 'www.williamsfund.co.uk'},
        {value: 'instagram.com', label: 'instagram.com'},
        {value: 'l.instagram.com', label: 'l.instagram.com'},
        {value: 'youtube.com', label: 'youtube.com'},
        {value: 'road-cc.cdn.ampproject.org', label: 'road-cc.cdn.ampproject.org'},
        {value: 'www.kenyamoja.com', label: 'www.kenyamoja.com'},
        {value: 'thoughtcatalog.com', label: 'thoughtcatalog.com'},
        {value: 'jin115.com', label: 'jin115.com'},
        {value: 'stv.tv', label: 'stv.tv'},
        {value: 'www.farmerama.com', label: 'www.farmerama.com'},
        {value: 'aftv.co.uk', label: 'aftv.co.uk'},
        {value: 'www.timesnownews.com', label: 'www.timesnownews.com'},
        {value: 'www.shannonside.ie', label: 'www.shannonside.ie'},
        {value: 'www.celticnewsnow.com', label: 'www.celticnewsnow.com'},
        {value: 'www-caughtoffside-com.cdn.ampproject.org', label: 'www-caughtoffside-com.cdn.ampproject.org'},
        {value: 'thedroidguy-com.cdn.ampproject.org', label: 'thedroidguy-com.cdn.ampproject.org'},
        {value: 'sweetandsavory.co', label: 'sweetandsavory.co'},
        {value: 'www.dinamalar.com', label: 'www.dinamalar.com'},
        {value: 'www.rodalesorganiclife.com', label: 'www.rodalesorganiclife.com'},
        {value: 'palmettostatearmory.com', label: 'palmettostatearmory.com'},
        {value: 'depor.com', label: 'depor.com'},
        {value: 'www.garnethill.com', label: 'www.garnethill.com'},
        {value: 'amp.breakingnews.ie', label: 'amp.breakingnews.ie'},
        {value: 'www.spurs-web.com', label: 'www.spurs-web.com'},
        {value: 'www.inquisitr.com', label: 'www.inquisitr.com'},
        {value: 'www.salon.com', label: 'www.salon.com'},
        {value: 'www.frontgate.com', label: 'www.frontgate.com'},
        {value: 'playjunkie.com', label: 'playjunkie.com'},
        {value: 'www.avangate.com', label: 'www.avangate.com'},
        {value: 'www.craftsy.com', label: 'www.craftsy.com'},
        {value: 'www.carandclassic.co.uk', label: 'www.carandclassic.co.uk'},
        {value: 'indianexpress.com', label: 'indianexpress.com'},
        {value: 'moneyversed.com', label: 'moneyversed.com'},
        {value: 'www.sarahah.com', label: 'www.sarahah.com'},
        {value: 'Tagged', label: 'Tagged'},
        {value: 'www.psychologytoday.com', label: 'www.psychologytoday.com'},
        {value: 'www.societe.com', label: 'www.societe.com'},
        {value: 'boredomtherapy.com', label: 'boredomtherapy.com'},
        {value: 'www.ria.com', label: 'www.ria.com'},
        {value: 'jobtome.com', label: 'jobtome.com'},
        {value: 'hollywoodlife.com', label: 'hollywoodlife.com'},
        {value: 'www.christianbook.com', label: 'www.christianbook.com'},
        {value: 'headcramp.com', label: 'headcramp.com'},
        {value: 'www.zumiez.com', label: 'www.zumiez.com'},
        {value: 'serato.com', label: 'serato.com'},
        {value: 'www.fantasypros.com', label: 'www.fantasypros.com'},
        {value: 'www.internships.com', label: 'www.internships.com'},
        {value: 'madpeople.net', label: 'madpeople.net'},
        {value: 'thesweethome.com', label: 'thesweethome.com'},
        {value: 'www.eventhubs.com', label: 'www.eventhubs.com'},
        {value: 'www.happiest.net', label: 'www.happiest.net'},
        {value: 'www.editorchoice.com', label: 'www.editorchoice.com'},
        {value: 'www.sportskeeda.com', label: 'www.sportskeeda.com'},
        {value: 'www.bewakoof.com', label: 'www.bewakoof.com'},
        {value: 'www.purseblog.com', label: 'www.purseblog.com'},
        {value: 'gamebanana.com', label: 'gamebanana.com'},
        {value: 'tippfm.com', label: 'tippfm.com'},
        {value: 'www.crash.net', label: 'www.crash.net'},
        {value: 'www.boxingscene.com', label: 'www.boxingscene.com'},
        {value: 'www.northernsound.ie', label: 'www.northernsound.ie'},
        {value: 'm.hindustantimes.com', label: 'm.hindustantimes.com'},
        {value: 'www-thisisanfield-com.cdn.ampproject.org', label: 'www-thisisanfield-com.cdn.ampproject.org'},
        {value: 'mobile.reverso.net', label: 'mobile.reverso.net'},
        {value: 'www.dresslink.com', label: 'www.dresslink.com'},
        {value: 'www.newsnow.co.uk', label: 'www.newsnow.co.uk'},
        {value: 'footballleagueworld.co.uk', label: 'footballleagueworld.co.uk'},
        {value: 'amp.hitc.com', label: 'amp.hitc.com'},
        {value: 'jamanetwork.com', label: 'jamanetwork.com'},
        {value: 'www.collectionsetc.com', label: 'www.collectionsetc.com'},
        {value: 'www.teamtalk.com', label: 'www.teamtalk.com'},
        {value: 'rateyourmusic.com', label: 'rateyourmusic.com'},
        {value: 'twentytwowords.com', label: 'twentytwowords.com'},
        {value: 'dictionary-cambridge-org.cdn.ampproject.org', label: 'dictionary-cambridge-org.cdn.ampproject.org'},
        {value: 'm.ranker.com', label: 'm.ranker.com'},
        {value: 'www.oasisdating.com', label: 'www.oasisdating.com'},
        {value: 'www.parents.com', label: 'www.parents.com'},
        {value: 'www.packers.com', label: 'www.packers.com'},
        {value: 'www.graduatez.com', label: 'www.graduatez.com'},
        {value: 'www-crash-net.cdn.ampproject.org', label: 'www-crash-net.cdn.ampproject.org'},
        {value: 'www.empireofthekop.com', label: 'www.empireofthekop.com'},
        {value: 'www.fashionmia.com', label: 'www.fashionmia.com'},
        {value: 'www.jomashop.com', label: 'www.jomashop.com'},
        {value: 'm.sportskeeda.com', label: 'm.sportskeeda.com'},
        {value: 'www.fanatix.com', label: 'www.fanatix.com'},
        {value: 'www.radiokerry.ie', label: 'www.radiokerry.ie'},
        {value: 'quizly.co', label: 'quizly.co'},
        {value: 'www.footballtransfertavern.com', label: 'www.footballtransfertavern.com'},
        {value: 'www.soolide.com', label: 'www.soolide.com'},
        {value: 'www-psychologytoday-com.cdn.ampproject.org', label: 'www-psychologytoday-com.cdn.ampproject.org'},
        {value: 'www.caughtoffside.com', label: 'www.caughtoffside.com'},
        {value: 'www.echolive.ie', label: 'www.echolive.ie'},
        {value: 'www.hesgoal.com', label: 'www.hesgoal.com'},
        {value: 'imasdk.googleapis.com', label: 'imasdk.googleapis.com'},
        {value: 'mortgageafterlife.com', label: 'mortgageafterlife.com'},
        {value: 'www.youtube.com', label: 'www.youtube.com'},
        {value: '441256270108 - y - SMS', label: '441256270108 - y - SMS'},
        {value: 'Telephone Call', label: 'Telephone Call'},
        {value: '441566232048 - default - SMS', label: '441566232048 - default - SMS'},
        {value: '447427544122 - Phone', label: '447427544122 - Phone'},
        {value: 'email.1and1.co.uk', label: 'email.1and1.co.uk'},
        {value: 's-tg-bcpvip001.towergate.local', label: 's-tg-bcpvip001.towergate.local'},
        {value: 'LM Lead', label: 'LM Lead'},
        {value: 'www.golfshake.com', label: 'www.golfshake.com'},
        {value: 'jupiter.avecoh.com', label: 'jupiter.avecoh.com'},
        {value: 'zh181.infusionsoft.app', label: 'zh181.infusionsoft.app'},
        {value: 'zh181.infusionsoft.app', label: 'zh181.infusionsoft.app'},
        {value: 'www.getactualresults.com', label: 'www.getactualresults.com'},
        {value: 'www.stoneleighdeerparkgolfclub.com', label: 'www.stoneleighdeerparkgolfclub.com'},
        {value: 'Golf Magic', label: 'Golf Magic'},
        {value: 'www.golfshake.com', label: 'www.golfshake.com'},
        {value: 'app.asana.com', label: 'app.asana.com'},
        {value: 'webmail.amber-homes.co.uk', label: 'webmail.amber-homes.co.uk'},
        {value: 'Find A Golf Break', label: 'Find A Golf Break'},
        {value: 'Golf Tours International', label: 'Golf Tours International'},
        {value: '441256270108 - Phone', label: '441256270108 - Phone'},
        {value: 'hunt-w2k16-test1.rapleys.local', label: 'hunt-w2k16-test1.rapleys.local'},
        {value: 'webmail.123-reg.co.uk', label: 'webmail.123-reg.co.uk'},
        {value: 'The Internet Electrical Store', label: 'The Internet Electrical Store'},
        {value: 'Charity', label: 'Charity'},
        {value: 'email19.godaddy.com', label: 'email19.godaddy.com'},
        {value: 'us7.campaign-archive2.com', label: 'us7.campaign-archive2.com'},
        {value: '441256270108 - default - SMS', label: '441256270108 - default - SMS'},
        {value: 'Black Shirt Campaign', label: 'Black Shirt Campaign'},
        {value: 'l.messenger.com', label: 'l.messenger.com'},
        {value: 'yandex.ru', label: 'yandex.ru'},
        {value: 'Work Space Mail', label: 'Work Space Mail'},
        {value: 'www.europrotour.com', label: 'www.europrotour.com'},
        {value: 'www.dev-fore.co.uk', label: 'www.dev-fore.co.uk'},
        {value: 'webmail.seawayinsurance.co.uk', label: 'webmail.seawayinsurance.co.uk'},
        {value: 's1.itseeze.co.uk', label: 's1.itseeze.co.uk'},
        {value: 'www.networkinginberkshire.co.uk', label: 'www.networkinginberkshire.co.uk'},
        {value: 'Google', label: 'Google'},
        {value: 'Microsoft Live', label: 'Microsoft Live'},
        {value: 'search.findwide.com', label: 'search.findwide.com'},
        {value: 'Golf Shake', label: 'Golf Shake'},
        {value: 'Exhibition', label: 'Exhibition'},
        {value: '441256830471 - default - SMS', label: '441256830471 - default - SMS'},
        {value: '441256830471 - Phone', label: '441256830471 - Phone'},
        {value: 'www.searchlock.com', label: 'www.searchlock.com'},
        {value: 'www.savevy.com', label: 'www.savevy.com'},
        {value: 'owa.afhgroup.com', label: 'owa.afhgroup.com'},
        {value: 'android-app:', label: 'android-app:'},
        {value: 'Linked In', label: 'Linked In'},
        {value: 'remote.victorwelshlegal.co.uk', label: 'remote.victorwelshlegal.co.uk'},
        {value: 'Yahoo', label: 'Yahoo'},
        {value: 'www.threelocksgolfclub.co.uk', label: 'www.threelocksgolfclub.co.uk'},
        {value: 't.co', label: 't.co'},
        {value: 'lnkd.in', label: 'lnkd.in'},
        {value: 'Youtube', label: 'Youtube'},
        {value: 'Instagram', label: 'Instagram'},
        {value: 'Bing', label: 'Bing'},
        {value: 'Outbound Sales', label: 'Outbound Sales'},
        {value: 'Referral - From Guest', label: 'Referral - From Guest'},
        {value: 'Facebook', label: 'Facebook'},
        {value: 'Google PPC', label: 'Google PPC'},
        {value: 'www.findnetworkingevents.com', label: 'www.findnetworkingevents.com'},
        {value: 'www.fore-business.com', label: 'www.fore-business.com'},
        {value: '2 DELETE', label: '2 DELETE'},
        {value: 'Google Natural Search', label: 'Google Natural Search'},
        {value: '1 DELETE', label: '1 DELETE'},
        {value: 'Referral - Other', label: 'Referral - Other'},
        {value: 'Email Marketing', label: 'Email Marketing'},
        {value: 'Networking - Other', label: 'Networking - Other'},
        {value: 'Networking - BNI', label: 'Networking - BNI'},
        {value: '3 DELETE', label: '3 DELETE'},
        {value: 'LinkedIn', label: 'LinkedIn'},
        {value: 'Twitter', label: 'Twitter'},
        {value: 'Direct Mail', label: 'Direct Mail'},
        {value: 'Referral - From Member', label: 'Referral - From Member'},
        {value: 'Referral - From Affiliate/Partner', label: 'Referral - From Affiliate/Partner'},
        {value: 'Advertisement', label: 'Advertisement'}
    ];