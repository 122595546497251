export const contactTypeOptions = [
    {value: 'Ambassador - Flexi', label: 'Ambassador - Flexi'},
    {value: 'Ambassador - Ultimate', label: 'Ambassador - Ultimate'},
    {value: 'Club', label: 'Club'},
    {value: 'Fair Weather Golfer', label: 'Fair Weather Golfer'},
    {value: 'Golden Ticket Sent', label: 'Golden Ticket Sent'},
    {value: 'Member', label: 'Member'},
    {value: 'Member Passport', label: 'Member Passport'},
    {value: 'Flexi Boost', label: 'Flexi Boost'},
    {value: 'Member - Academy', label: 'Member - Academy'},
    {value: 'Member - Community', label: 'Member - Community'},
    {value: 'Member - Corporate Flexi', label: 'Member - Corporate Flexi'},
    {value: 'Member - Corporate Ultimate', label: 'Member - Corporate Ultimate'},
    {value: 'Member - Flexi', label: 'Member - Flexi'},
    {value: 'Member - Flexi 1', label: 'Member - Flexi 1'},
    {value: 'Member - Flexi 2', label: 'Member - Flexi 2'},
    {value: 'Member - Flexi 3', label: 'Member - Flexi 3'},
    {value: 'Member - Flexi 4', label: 'Member - Flexi 4'},
    {value: 'Member - FREEBIE', label: 'Member - FREEBIE'},
    {value: 'Member - Guest', label: 'Member - Guest'},
    {value: 'Member - Lapsed', label: 'Member - Lapsed'},
    {value: 'Member - Lasped - No Longer Interested', label: 'Member - Lapsed - No Longer Interested'},
    {value: 'Member - New Group', label: 'Member - New Group'},
    {value: 'Member - Nov Import Flexi Email', label: 'Member - Nov Import Flexi Email'},
    {value: 'Member - Pending Information', label: 'Member - Pending Information'},
    {value: 'Member - Suspended', label: 'Member - Suspended'},
    {value: 'Member - Ultimate 1', label: 'Member - Ultimate 1'},
    {value: 'Member - Ultimate 2', label: 'Member - Ultimate 2'},
    {value: 'Member - Ultimate 3', label: 'Member - Ultimate 3'},
    {value: 'Member - Ultimate 4', label: 'Member - Ultimate 4'},
    {value: 'Member - Ultimate 5', label: 'Member - Ultimate 5'},
    {value: 'Member - Ultimate 6', label: 'Member - Ultimate 6'},
    {value: 'Member - Ultimate 7', label: 'Member - Ultimate 7'},
    {value: 'Member - Ultimate 8', label: 'Member - Ultimate 8'},
    {value: 'Member - Ultimate 9', label: 'Member - Ultimate 9'},
    {value: 'Member - Ultimate 10', label: 'Member - Ultimate 10'},
    {value: 'Member - Ultimate 11', label: 'Member - Ultimate 11'},
    {value: 'Member - Ultimate 12', label: 'Member - Ultimate 12'},
    {value: 'Member - Virtual', label: 'Member - Virtual'},
    {value: 'Partner', label: 'Partner'},
    {value: 'Prospect', label: 'Prospect'},
    {value: 'Prospect - Academy', label: 'Prospect - Academy'},
    {value: 'Prospect - Attended', label: 'Prospect - Attended'},
    {value: 'Prospect Attended - HQ', label: 'Prospect Attended - HQ'},
    {
        value: 'Prospect Attended - NOT INTERESTED IN JOINING',
        label: 'Prospect Attended - NOT INTERESTED IN JOINING'
    },
    {value: 'Prospect Attended - Ambassador', label: 'Prospect Attended - Ambassador'},
    {value: 'Prospect - Booked', label: 'Prospect - Booked'},
    {value: 'Prospect - Booked Pre CRM', label: 'Prospect - Booked Pre CRM'},
    {value: 'Prospect - Cancelled', label: 'Prospect - Cancelled'},
    {value: 'Prospect - Members Guest To Call', label: 'Prospect - Members Guest To Call'},
    {value: 'Prospect - Missing Data - Kelly Calls', label: 'Prospect - Missing Data - Kelly Calls'},
    {value: 'Prospect - More information sent', label: 'Prospect - More information sent'},
    {value: 'Prospect - New Group', label: 'Prospect - New Group'},
    {value: 'Prospect - No show', label: 'Prospect - No show'},
    {value: 'Prospect - Non Decision Maker', label: 'Prospect - Non Decision Maker'},
    {value: 'Prospect - Not Interested Now', label: 'Prospect - Not Interested Now'},
    {value: 'Prospect - Paid booking', label: 'Prospect - Paid booking'},
    {value: 'Test Record', label: 'Test Record'},
    {value: 'VIP - Booked', label: 'VIP - Booked'},
    {value: 'VIP - Paid', label: 'VIP - Paid'},
    {value: 'VIP - Covid-19 Prospect', label: 'VIP - Covid-19 Prospect'}
]