import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { AuthWizardContext } from 'context/Context';
import axios from "axios";

const AuthWizardProvider = ({ children }) => {
  const [user, setUser] = useState({first_name: 'mehedee'});
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect( () => {
    if (localStorage.getItem('token')) {
      axios.get('/member/me').then(res => {
        setUser({...user,...res.data});
        setIsLoading(false);
        console.log(user.first_name);
      }).catch(err => {
        setIsLoading(false);
      });
    }
  }, []);

  const value = { user,  step, setStep, isLoading };
  return (
    <AuthWizardContext.Provider value={value}>
      {children}
    </AuthWizardContext.Provider>
  );
};

AuthWizardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthWizardProvider;
