import React from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import createMarkup from 'helpers/createMarkup';
import { Card, Col, ProgressBar, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const ShoppingCartItem = ({ item, index }) => {
  const { id, title, amount, barWidth, items, variant, iconColor, icon } = item;

  return (
    <Row
      className={classNames('g-0 align-items-center pb-3', {
        'border-top pt-3': index !== 0
      })}
    >
      <Col className="pe-4">
        <h6 className="fs--2 text-600">{title}</h6>

        <ProgressBar className="mt-xxl-auto" style={{ height: 5 }}>
          <ProgressBar
            variant={variant}
            now={barWidth}
            key={id}
            className="rounded-3"
          />
        </ProgressBar>
      </Col>
      <Col xs="auto" className="text-end">
        <p className="mb-0 text-900 font-sans-serif">
          <FontAwesomeIcon icon={icon} className={`text-${iconColor} me-1`} />
          {amount}%
        </p>
        <p
          className="mb-0 fs--2 text-500 fw-semi-bold"
          dangerouslySetInnerHTML={createMarkup(items)}
        />
      </Col>
    </Row>
  );
};

const EventChart = (props) => {

  const shoppingCartItems = props.events.map(event => {
    return {
      id: event.id,
      title: event.name,
      amount: ((event.national_event_booking_events_count / event.quantity) * 100).toFixed(2),
      barWidth: ((event.national_event_booking_events_count / event.quantity) * 100).toFixed(2),
      items: `<span class ="text-600">${event.national_event_booking_events_count}</span>  of ${event.quantity} booked`,
      variant: 'danger',
      iconColor: 'danger',
      icon: 'caret-up'
    }
  })

  return (
    <Card>
      <FalconCardHeader
        title="National Event Bookings"
        titleTag="h6"
        className="py-2"
      />
      <Card.Body className="py-0 d-flex align-items-center h-100">
        <div className="flex-1">
          {shoppingCartItems.map((item, index) => (
            <ShoppingCartItem item={item} key={item.id} index={index} />
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};


export default EventChart;
