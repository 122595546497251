import React from 'react';
import { Card, Col, Button, Row } from 'react-bootstrap';

const GroupHeader = (prop) => {
  return (
    <Card>
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0"> {prop.group.name}</h5>
          </Col>
          <Col xs="auto">
            <Button
                onClick={prop.groupUpdate}
                size="sm"
                variant="falcon-default"
                className="me-2"
                type="button"
            >
              Save Group
            </Button>
            <Button
              onClick={prop.remind_attendance_confirmation}
              size="sm"
              variant="falcon-default"
              className="me-2"
              type="button"
            >
              Remind Attendance Confirmation
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default GroupHeader;