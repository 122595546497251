import {Button, Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import React, {useEffect, useState} from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

const ActivitiesFilter = ({setParams}) => {
    const [model, setModel] = useState(null);
    const [user, setUser] = useState(null);

    const modelOptions = [
        'Address',
        'Member',
        // 'Group',
        // 'Site',
        'User',
        // 'Ambassador',
        // 'GoldenTicketShared',
        // 'Notification',
        // 'EmailTemplate',
        // 'Region',
        // 'Event',
        'Attendee'
    ];

    const [users, setUsers] = useState(null);

     const [date, setDate] = useState(null);

     const HandleUser = (value) => {
         setUser(value);
         setParams(prevState => ({
             ...prevState,
             causer_id: value ? value.value : ''
         }))
     }

     const HandleModel = (value) => {
         setModel(value);
         setParams(prevState => ({
             ...prevState,
             models: value.map(item => item.value)
         }))
     }

     const HandleDate = (value) => {
         setDate(value);
         setParams(prevState => ({
             ...prevState,
             date: value ? moment(value).format('YYYY-MM-DD') : ''
         }))
     }

     useEffect(() => {
        axios.get('/teams')
            .then(res => {
                setUsers(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])


    return (
        <Row>
            <Col lg={12} className="mb-3">
                <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                >
                    <Col lg="3">
                        <Select
                            className="mb-3"
                            closeMenuOnSelect={false}
                            options={modelOptions.map((item, index) => ({
                                value: item,
                                label: item
                            }))}
                            placeholder='Modal'
                            isMulti
                            classNamePrefix="react-select"
                            value={model}
                            onChange={value => HandleModel(value)}
                        />
                    </Col>

                    <Col lg="3">
                        <Select
                            className="mb-3"
                            options={users && users.map((item, index) => ({
                                value: item.id,
                                label: item.first_name + ' ' + item.last_name
                            }))}
                            placeholder='Updated By'
                            classNamePrefix="react-select"
                            value={user}
                            onChange={value => HandleUser(value)}
                            isClearable={true}
                        />
                    </Col>
                    <Col lg="3">
                        <DatePicker
                            selected={date}
                            onChange={(date) => HandleDate(date)}
                            formatWeekDay={day => day.toString().slice(0, 3)}
                            className='form-control mb-3'
                            placeholderText="Select Date"
                            format="yyyy-MM-dd"
                        />
                    </Col>
                    <Col lg="3">
                        <Button
                        variant="falcon-default"
                        className="me-2"
                        type="button"
                    >
                        Search
                    </Button>
                    </Col>
                </Form.Group>
            </Col>

        </Row>
    )
}

export default ActivitiesFilter;