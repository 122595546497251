import {Button, Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import React, {useEffect, useState} from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import Flex from "../../../components/common/Flex";
import {response_error} from "../../../helpers/functions";

const GoldenTicketFilter = ({param, setParam, getGoldenTickets, downloadGoldenTickets}) => {
     const [region, setRegion] = useState(null);
  const regionOption = [
    { value: '1', label: 'Northern UK' },
    { value: '2', label: 'North West' },
    { value: '3', label: 'North Central' },
    { value: '4', label: 'Midlands' },
    { value: '5', label: 'South West' },
    { value: '6', label: 'South' },
    { value: '7', label: 'South Central' },
    { value: '8', label: 'South East' }
  ];
    const [groupOptions, setGroupOptions] = useState([]);
    // Date range
    const [wonDate, setWonDate] = useState({
        start: null,
        end: null
    })
    const onChangeWonDate = dates => {
        const [start, end] = dates;
        setWonDate(prevState => ({...prevState, start: start}));
        setWonDate(prevState => ({...prevState, end: end}));
        setParam(prevState => ({...prevState, date_at_from: start, date_at_to: end}))
    };

    useEffect(() => {
        axios.get('/groups/forFilter')
            .then(response => {
                setGroupOptions(response.data.groups.map(group => {
                    return {value: group.id, label: group.name}
                }))
            })
            .catch(error => {
                response_error(error);
            })
    }, [])



    return (
        <Row>
            <Col lg={8}>
                <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                >
                    <Col lg="4">
                        <Select
                            className="mb-2"
                            closeMenuOnSelect={false}
                            options={regionOption}
                            placeholder='Select Regions...'
                            isMulti
                            classNamePrefix="react-select"
                            onChange={value => setParam({...param, region_id: value})}
                        />
                    </Col>
                    <Col lg="4">
                        <Select
                            className="mb-2"
                            closeMenuOnSelect={false}
                            options={groupOptions}
                            placeholder='Select Groups...'
                            isMulti
                            classNamePrefix="react-select"
                            onChange={value => setParam({...param, groups_id: value})}
                        />
                    </Col>
                    <Col lg="4">
                        <DatePicker
                            selected={wonDate.start}
                            placeholderText="Date"
                            onChange={onChangeWonDate}
                            startDate={wonDate.start}
                            formatWeekDay={day => day.toString().slice(0, 3)}
                            endDate={wonDate.end}
                            selectsRange
                            dateFormat="MMM dd yyyy"
                            className='form-control mb-2'
                        />
                    </Col>
                    <Col lg="6">
                        <Form.Check
                            inline
                            type='checkbox'
                            id='hasSubmitted'
                            label='Has Submitted'
                            name='has_submitted'
                            className='pt-2'
                            onChange={e => setParam({...param, has_submitted: +e.target.checked})}
                        />
                        <Form.Check
                            inline
                            type='checkbox'
                            id='bankAccountAdded'
                            label='Bank Account Added'
                            name='cardless_mandate'
                            className='pt-2'
                            onChange={e => setParam({...param, cardless_mandate: +e.target.checked})}
                        />
                    </Col>
                </Form.Group>
            </Col>


            <Col lg="4">
                <Flex justifyContent='end' className="align-self-center">
                    <Button
                        variant="falcon-default"
                        className="me-2"
                        type="button"
                        onClick={() => getGoldenTickets(1)}
                    >
                        Search
                    </Button>

                    <Button
                        variant="falcon-default"
                        className="me-2"
                        type="button"
                        onClick={() => downloadGoldenTickets()}
                    >
                        Download
                    </Button>
                </Flex>

            </Col>

        </Row>
    )
}

export default GoldenTicketFilter;