import React, {useState} from 'react';
import {Card, Col, Form, Row, Button} from 'react-bootstrap';
import PropTypes from 'prop-types';

const PoContact = ({group, groupHandleChange}) => {
    const [formData, setFormData] = useState({
        startDate: null,
        endDate: null,
        regDate: null,
        startTime: null,
        endTime: null
    });

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <Card className="mb-3">
            <Card.Header as="h5">PO Contact</Card.Header>
            <div className="border-dashed-bottom"></div>
            <Card.Body className="bg-light">
                <Row className="gx-2 gy-3">
                    <Col md="6">
                        <Form.Group controlId="po_contact_name" className="mb-3">
                            <Form.Label>PO Contact Name</Form.Label>
                            <Form.Control
                                type="text"
                                name='po_contact_name'
                                value={group.po_contact_name}
                                onChange={groupHandleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="po_contact_email" className="mb-3">
                            <Form.Label>PO Contact Email</Form.Label>
                            <Form.Control
                                type="text"
                               name='po_contact_email'
                                value={group.po_contact_email}
                                onChange={groupHandleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>

    );
};


export default PoContact;
