import React, {useContext, useState} from 'react';
import {Card, Col, Form, Row, Button, Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { GroupContext } from 'context/Context';
import FalconCloseButton from 'components/common/FalconCloseButton';

const ForeBallBooking = () => {
    const {foreballEmails, setForeballEmails, count, setCount} = useContext(GroupContext);

    const bookings = foreballEmails.filter(function (foreballEmail) {
        return foreballEmail.type === 'booking';
      })

    const [show, setShow] = useState(false);
    const [update, setUpdate] = useState(false);

    const defaultBooking = {
        name: '',
        email: '',
        phone: '',
        type: 'booking',
        id: count
    }

    const [newBooking, setNewBooking] = useState(defaultBooking);

    const bookingHnadleChange = (e) => {
        setNewBooking(prevState => ({ ...prevState, [e.target.name]: e.target.value, type: 'booking', id: count }));
    }

    const bookingHnadleClick = (e) => {
        e.preventDefault();

        if(update){
            let newForeballEmails = foreballEmails.map(foreballEmail => {
                if(foreballEmail.id === newBooking.id){
                    console.log(newBooking);
                    return newBooking;
                }
                return foreballEmail;
            })
            setForeballEmails([...newForeballEmails]);
        }
        else{
            setForeballEmails([...foreballEmails, newBooking]);
        }

        setCount(count +1);
        setShow(false);
        setNewBooking(defaultBooking);
    }

    const deleteForeballEmail = (id) => {
        let newForeballEmails = foreballEmails.filter(foreballEmail => id !== foreballEmail.id);

        setForeballEmails(newForeballEmails);
    }

    const editForeballEmail = (id) => {
        setUpdate(true);
        setShow(true);
        setCount(id);
        let newForeballEmails = foreballEmails.filter(foreballEmail => id === foreballEmail.id);
        setNewBooking(...newForeballEmails);
    }

    const openModel = () => {
        setUpdate(false);
        setShow(true);
        setCount(foreballEmails.length > 0 ? foreballEmails[foreballEmails.length - 1]['id'] + 1 : 1);
        setNewBooking(defaultBooking);
    }


   const Actions = (prop) => {
    return (
        <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
            <Button onClick={() => editForeballEmail(prop.id)} variant="light" size="sm" className="border-300 me-1 text-600">
                <FontAwesomeIcon icon="edit"/>
            </Button>
            <Button variant="light" size="sm" className="border-300 text-600" onClick={() => deleteForeballEmail(prop.id)}>
                <FontAwesomeIcon icon="trash-alt"/>
            </Button>
        </div>

    );
   };

    return (
        <>
            <Card className="mb-3">

                <Card.Header as="h5">
                    <Row className="flex-between-center">
                        <Col md>
                            <h5 className="mb-2 mb-md-0">4ball Booking</h5>
                        </Col>
                        <Col xs="auto">
                            <Button
                                onClick={() => openModel()}
                                size="sm"
                                variant="falcon-default"
                                className="me-2"
                                type="button"
                            >
                                Add 4ball Booking
                            </Button>
                        </Col>
                    </Row>
                </Card.Header>

                <div className="border-dashed-bottom"></div>
                <Card.Body className="bg-light">
                    <Row className="gx-2 gy-3">
                        {
                            bookings.map(booking => {
                                return (
                                    <Col md="4" key={booking.id}>
                                    <Card className="mb-3 card_no_shadow">
                                        <Card.Body>
                                            <div className="flex-1 position-relative hover-actions-trigger">
                                                <h6 className="fs-0 mb-0">{booking.name}</h6>
                                                <p className="text-secondary mb-0 fs-0">{booking.email}<br/> {booking.phone}</p>
                                                <Actions id={booking.id}/>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                )
                            })
                        }

                    </Row>
                </Card.Body>
            </Card>

            <Modal
                size=" "
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add 4ball Booking
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md="12">
                            <Form.Group controlId="4ball_booking_name" className="mb-3">
                                <Form.Label>4Ball Booking Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='name'
                                    value={newBooking.name || ''}
                                    onChange={bookingHnadleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="4ball_booking_email" className="mb-3">
                                <Form.Label>4Ball Booking Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    value={newBooking.email}
                                    onChange={bookingHnadleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="4ball_booking_phone" className="mb-3">
                                <Form.Label>4Ball Booking Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    value={newBooking.phone}
                                    onChange={bookingHnadleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="text-end">
                            <Button
                                size="sm"
                                variant="falcon-default"
                                className="me-2"
                                type="button"
                                onClick={bookingHnadleClick}
                            >
                                Add 4ball Booking
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ForeBallBooking;
