import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from '../../../components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTableWrapper from '../../../components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '../../../components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from '../../../components/common/advance-table/AdvanceTablePagination';
import axios from 'axios';
import RegionsTableHeader from './RegionsTableHeader';
import { RegionContext } from 'context/Context';

const columns = [
  {
    accessor: 'id',
    Header: 'ID',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
       const {id} = rowData.row.original
      return (
        <>
          {id}
        </>
      );
    }
  },
  {
    accessor: 'name',
    Header: 'Name',
    headerProps: { className: 'pe-7' },
    Cell: rowData => {
      const {name} = rowData.row.original
      return (
            <>
              {name}
            </>
          );
    }
  },
  {
    accessor: 'allowed_regions',
    Header: 'Allowed Countries ',
    Cell: rowData => {
      const {names} = rowData.row.original
      return (
        <>
         {names.join(', ')}
        </>
      );
    }
  },
  {
    accessor: 'country',
    Header: 'Country ',
    Cell: rowData => {
      const {is_country_name} = rowData.row.original
      return (
        <>
          {is_country_name}
        </>
      );
    }
  },
  {
    accessor: 'payment',
    Header: 'Payment',
    Cell: rowData => {
      const {payment_method} = rowData.row.original
      return (
        <>
          {payment_method}
        </>
      );
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: (rowData) => {
      const {region, setRegion, setModalShow} = useContext(RegionContext);
      const {id} = rowData.row.original
      const editRegion = (id) => {
        axios.get(`/regions/${id}`)
        .then(response => {
          setRegion(response.data.region);
          setModalShow(true);
        })
      };
      return (
        <CardDropdown iconClassName="fs--1" drop="start">
          <div className="py-2">
            <Dropdown.Item onClick={() => editRegion(id)}>Edit</Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const RegionsTable = (prop) => {
  const {regions} = useContext(RegionContext);
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={prop.regions}
      selection
      sortable
      pagination
      perPage={10}
    >
      <Card className="mb-3">
        <Card.Header>
          <RegionsTableHeader table/>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default RegionsTable;