import React from 'react';
import {Button, Card, Col, Form, Modal, Row} from 'react-bootstrap';
import SaveBar from '../../../components/custom/save-bar';
import Confirm from "../../../components/custom/confirm";
import MemberReportsView from "../../Admin/Members/sections/MemberReportsView";
import MemberPersonalInfo from "./MemberPersonalInfo";
import MemberProfileImageView from "./MemberProfileImageView";
import MemberActivitiesLog from "./MemberActivitiesLog";
import {FaCreditCard} from "react-icons/all";
import axios from "axios";
import toasts from "../../../components/doc-components/Toasts";
import {response_error} from "../../../helpers/functions";
import {toast} from "react-hot-toast";

const MemberProfileView = () => {

    const addLoyaltyCard = () => {
        axios.post('/member/loyalty-card/add', {test: 'test'})
            .then(response => {
                toast.success(response.data.message);
            })
            .catch(error => {
                response_error(error);
            });
    }


    return (
        <>
             <Card>
                <Card.Header>
                    <Row>
                        <Col xs={6}>
                            <h5>Member Profile</h5>
                        </Col>
                        <Col xs={6}>
                            <div className="text-end">
                                <Button variant="falcon-default" onClick={addLoyaltyCard}>
                                    <FaCreditCard /> Add a Loyalty Card to my wallet
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
            </Card>
            <Confirm body="Do you want to send Golden Ticket Link with stripe payment?" title="Golden Ticket Link"/>
            <MemberPersonalInfo/>
        </>
    );
};

export default MemberProfileView;



