import React, {Component} from 'react';
import {Form, Card, Col, Row} from 'react-bootstrap';
import Select from 'react-select';
import {profession} from 'data/memberInfoData/profession';
import {shirtSizes} from 'data/memberInfoData/shirtSizes';
import {leadSource} from 'data/memberInfoData/leadSource';

const PersonalInfo = ({member, memberHandleChange, selectChangeHandler, }) => {
    const referring_member = (referring_member) => {
        let full_name = '';

        if(!referring_member){
            return full_name;
        }

        if(referring_member.first_name){
            full_name = referring_member.first_name;
        }

        if(referring_member.last_name){
            full_name += ' '+referring_member.last_name;
        }

        return full_name;
    }

    return (
        <Row className="g-0 mb-3">
            <Col lg={12} className="pe-lg-2">
                <Card className="h-lg-100">
                    <Card.Header as="h5">Personal Info</Card.Header>
                    <div className="border-dashed-bottom"></div>
                    <Card.Body className="bg-light">
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Gender</Form.Label>
                                    <div className="d-block">
                                        <Form.Check inline type='radio' id='male' label='Male' name='gender' value={'male'}
                                        onChange={memberHandleChange}
                                                    checked={member.gender === 'male'}
                                        />
                                        <Form.Check inline type='radio' id='female' label='Female' name='gender'  value={'female'}
                                        onChange={memberHandleChange}
                                                    checked={member.gender === 'female'}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="first name"
                                        value={member.first_name}
                                        onChange={memberHandleChange}
                                        name="first_name"
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        placeholder="last name"
                                        name="last_name"
                                        type="text"
                                        value={member.last_name}
                                        onChange={memberHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        placeholder="Company name"
                                        name="company"
                                        type="text"
                                        value={member.company}
                                        onChange={memberHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        placeholder="Email Address"
                                        name="email"
                                        type="text"
                                        value={member.email}
                                        onChange={memberHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Vpar Email</Form.Label>
                                    <Form.Control
                                        placeholder="Vpar Email Address"
                                        name="alternative_email"
                                        type="text"
                                        value={member.alternative_email}
                                        onChange={memberHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control
                                        placeholder="Mobile Number"
                                        name="phone1"
                                        type="text"
                                        value={member.phone1}
                                        onChange={memberHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Landline Number</Form.Label>
                                    <Form.Control
                                        placeholder="Landline Number"
                                        name="phone2"
                                        type="text"
                                        value={member.phone2}
                                        onChange={memberHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Profession</Form.Label>
                                    <Select
                                        options={profession}
                                         value={
                                            profession.filter(option => {
                                                if(member.profession){
                                                    return option.value.toLowerCase() === member.profession.toLowerCase()
                                                }
                                                return false;
                                            }
                                                )
                                        }
                                        onChange = {selectChangeHandler}
                                        name="profession"
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Job Title</Form.Label>
                                    <Form.Control
                                        placeholder="Job Title"
                                        name="job_title"
                                        type="text"
                                        value={member.job_title}
                                        onChange={memberHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Shirt Size</Form.Label>
                                    <Select
                                        options={shirtSizes}
                                        value={
                                            shirtSizes.filter(option =>
                                                option.value === member.shirt_size)
                                        }
                                        onChange = {selectChangeHandler}
                                        name="shirt_size"
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Linkedin Profile</Form.Label>
                                    <Form.Control
                                        placeholder="Linkedin Profile"
                                        name="linkedin"
                                        type="text"
                                        value={member.linkedin}
                                        onChange={memberHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Facebook Profile</Form.Label>
                                    <Form.Control
                                        placeholder="Facebook Profile"
                                        name="facebook"
                                        type="text"
                                        value={member.facebook}
                                        onChange={memberHandleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Lead Source</Form.Label>
                                    <Select
                                        name="lead_source"
                                        options={leadSource}
                                        value={
                                            leadSource.filter(option =>{
                                                if(member.lead_source){
                                                    return option.value.toLowerCase() === member.lead_source.toLowerCase()
                                                }
                                                return false;
                                            }
                                                )
                                        }
                                        onChange = {selectChangeHandler}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Handicap</Form.Label>
                                    <Form.Control
                                        placeholder="Handicap"
                                        name="handicap"
                                        type="text"
                                        value={member.handicap}
                                        onChange={memberHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Referring Member</Form.Label>
                                    <Form.Control
                                        placeholder="Referring Member"
                                        name="handicap"
                                        type="text"
                                        value={referring_member(member.referring_member && member.referring_member.user ? member.referring_member.user : null)}
                                        readOnly={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Ambassadors</Form.Label>
                                    <div className="d-block">
                                        <Form.Check inline type='radio' id='is_ambassador_yes' label='Yes' value='1' name='is_ambassador' checked={parseInt(member.is_ambassador) === 1} onChange={memberHandleChange} />

                                        <Form.Check inline type='radio' id='is_ambassador_no' label='No' value='0' name='is_ambassador' checked={parseInt(member.is_ambassador) !== 1} onChange={memberHandleChange} />

                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default PersonalInfo;