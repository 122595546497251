import React, {useEffect, useState} from 'react';
import {Card, Dropdown, Button, Col, Form, Row, Modal} from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import axios from "axios";
import {next_icon, previous_icon, response_error} from "../../../helpers/functions";
import ReactPaginate from "react-paginate";
import GoldenTicketFilter from "./goldenTicketFilter";
import {format} from "date-fns";
import toast from "react-hot-toast";
import SoftBadge from "../../../components/common/SoftBadge";
//import GroupFilterForm from "./GroupTable/GroupFilterForm";

const GoldenTickets = () => {
    const [param, setParam] = useState({
        groups_id: [],
        region_id: [],
        date_at_from: '',
        date_at_to: '',
        page: 1
    });

    const [goldenTickets, setgoldenTickets] = useState([])
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1)

    const getGoldenTickets = (page) => {
        let _param = {...param}

        _param.page = page
        _param.groups_id = param.groups_id.map(item => item.value);
        _param.region_id = param.region_id.map(item => item.value);
        _param.date_at_to = param.date_at_to ? format(new Date(param.date_at_to), 'yyyy-MM-dd') : ''
        _param.date_at_from = param.date_at_from ? format(new Date(param.date_at_from), 'yyyy-MM-dd') : ''

        axios.get('/golden-tickets', {params: _param})
            .then(response => {
                setgoldenTickets(response.data.data)
                let total_page = parseInt(response.data.total) / parseInt(response.data.per_page);
                total_page = total_page > 0 ? Math.ceil(total_page) : 0;
                setPageCount(total_page);
            })
            .catch(error => {
                response_error(error);
            });
    }

    const downloadGoldenTickets = () => {
        let _param = {...param}

        _param.groups_id = param.groups_id.map(item => item.value);
        _param.region_id = param.region_id.map(item => item.value);
        _param.date_at_to = param.date_at_to ? format(new Date(param.date_at_to), 'yyyy-MM-dd') : ''
        _param.date_at_from = param.date_at_from ? format(new Date(param.date_at_from), 'yyyy-MM-dd') : ''

        axios
            .get('/golden-tickets/export', {params: _param, responseType: "blob"})
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Golden-Tickets-'+format(new Date(), 'yyyy-MM-dd-HH-mm-ss')+'.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                response_error(error);
            });
    }

    useEffect(() => {
        getGoldenTickets(1)
    }, [])

    const handlePageClick = (selected) => {
        getGoldenTickets(selected.selected + 1)
    }

    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    <h5 className="fs-0 mb-3 text-nowrap py-2 py-xl-0">Golden Tickets</h5>
                    <GoldenTicketFilter
                        param={param}
                        setParam={setParam}
                        getGoldenTickets={(getGoldenTickets)}
                        downloadGoldenTickets={downloadGoldenTickets}
                    />
                    {/*<GroupFilterForm*/}
                    {/*    setParam={setParam}*/}
                    {/*    param={param}*/}
                    {/*    getGroups={getGroups}*/}
                    {/*    setModalShow={setModalShow}*/}
                    {/*/>*/}
                </Card.Header>
                <Card.Body className="pt-0">
                    <div className="table-responsive">
                    <table className="fs--1 mb-0 table table-sm table-striped overflow-hidden" role="table">
                        <thead className="bg-200 text-900 text-nowrap align-middle">
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Referring Member</th>
                            <th>Group</th>
                            <th>Has Submitted</th>
                            <th>Bank Account Added</th>
                            <th>Created at</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            goldenTickets.map((goldenTicket, index) => (

                                <tr className="align-middle white-space-nowrap" role="row" key={index}>
                                    <td role="cell" className="py-2"><a href={`/members/edit/${goldenTicket.member.id}`} target='_blank'>{goldenTicket.member.user.first_name} {goldenTicket.member.user.last_name}</a></td>
                                    <td role="cell">{goldenTicket.member.user.email}</td>
                                    <td role="cell">{goldenTicket.member.phone1}</td>
                                    <td role="cell"><a href={`/members/edit/${goldenTicket.member?.referring_member?.id}`} target='_blank'>{goldenTicket.member?.referring_member?.user?.first_name} {goldenTicket.member?.referring_member?.user?.last_name}</a></td>
                                    <td role="cell"> {goldenTicket.member.groups.map(groupsInfo => (groupsInfo.name))}</td>
                                    <td role="cell"><SoftBadge pill bg={goldenTicket.has_submitted ? "success" : "danger"}>{goldenTicket.has_submitted ? 'Yes' : 'No'}</SoftBadge></td>
                                    <td role="cell"><SoftBadge pill bg={goldenTicket?.new_joiner?.cardless_mandate ? "success" : "danger"}>{goldenTicket?.new_joiner?.cardless_mandate ? 'Yes' : 'No'}</SoftBadge></td>
                                    <td role="cell">{goldenTicket.created_at}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                        </div>

                </Card.Body>
                <Card.Footer>
                    <ReactPaginate
                        previousLabel={previous_icon()}
                        nextLabel={next_icon()}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination mb-0 mx-1'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </Card.Footer>
            </Card>
        </>
    );
};

export default GoldenTickets;