import React, { useContext, useState } from 'react';
import { Card, Col, Form, Row, Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from "react-confirm-alert";
import { GroupContext } from 'context/Context';
import FalconCloseButton from 'components/common/FalconCloseButton';

const ForeBallBookingChange = () => {
    const {foreballEmails, setForeballEmails, count, setCount} = useContext(GroupContext);
    const rebookings = foreballEmails.filter(function (foreballEmail) {
        return foreballEmail.type === 'rebooking';
      })
    const [show, setShow] = useState(false);
    const [update, setUpdate] = useState(false);

    const defaultBooking = {
        name: '',
        email: '',
        phone: '',
        type: 'booking',
        id: ''
    }

    const [newBooking, setNewBooking] = useState(defaultBooking);

    const bookingHnadleChange = (e) => {
        setNewBooking(prevState => ({ ...prevState, [e.target.name]: e.target.value, type: 'rebooking', id: count }));
    }

    const bookingHnadleClick = (e) => {
        e.preventDefault();

        if(update){
            let newForeballEmails = foreballEmails.map(foreballEmail => {
                if(foreballEmail.id === newBooking.id){
                    console.log(newBooking);
                    return newBooking;
                }
                return foreballEmail;
            })
            setForeballEmails([...newForeballEmails]);
        }
        else{
            setForeballEmails([...foreballEmails, newBooking]);
        }

        setCount(count +1);
        setShow(false);
        setNewBooking(defaultBooking);
    }

    const deleteForeballEmail = (id) => {
        let newForeballEmails = foreballEmails.filter(foreballEmail => id !== foreballEmail.id);

        setForeballEmails(newForeballEmails);
    }

        const editForeballEmail = (id) => {
        setUpdate(true);
        setShow(true);
        setCount(id);
        let newForeballEmails = foreballEmails.filter(foreballEmail => id === foreballEmail.id);
        console.log(...newForeballEmails);
        setNewBooking(...newForeballEmails);
    }

    const openModel = () => {
        setUpdate(false);
        setShow(true);
        if(foreballEmails.length > 0){
            setCount(foreballEmails[foreballEmails.length - 1]['id'] + 1);
        }
        else{
            setCount(1);
        }

        setNewBooking(defaultBooking);
    }

    const Actions = (prop) => {
        return (
            <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
                <Button onClick={() => editForeballEmail(prop.id)} variant="light" size="sm" className="border-300 me-1 text-600">
                    <FontAwesomeIcon icon="edit" />
                </Button>
                <Button variant="light" size="sm" className="border-300 text-600" onClick={() => deleteForeballEmail(prop.id)}>
                    <FontAwesomeIcon icon="trash-alt" />
                </Button>
            </div>

        );
    };

    return (
        <>
            <Card className="mb-3">
                <Card.Header as="h5">
                    <Row className="flex-between-center">
                        <Col md>
                            <h5 className="mb-2 mb-md-0">4ball Booking Change</h5>
                        </Col>
                        <Col xs="auto">
                            <Button
                                onClick={() => openModel()}
                                size="sm"
                                variant="falcon-default"
                                className="me-2"
                                type="button"
                            >
                                4ball Booking Change
                            </Button>
                        </Col>
                    </Row>
                </Card.Header>
                <div className="border-dashed-bottom"></div>
                <Card.Body className="bg-light">
                    <Row className="gx-2 gy-3">
                        {
                            rebookings.map(rebooking => {
                                return (
                                    <Col md="4" key={rebooking.id}>
                                        <Card className="mb-3 card_no_shadow">
                                            <Card.Body>
                                                <div className="flex-1 position-relative hover-actions-trigger">
                                                    <h6 className="fs-0 mb-0">{rebooking.name}</h6>
                                                    <p className="text-secondary mb-0 fs-0">{rebooking.email}<br /> {rebooking.phone}</p>
                                                    <Actions  id={rebooking.id}/>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })
                        }

                    </Row>
                </Card.Body>
            </Card>

            <Modal
                size=" "
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        4ball Booking Change
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md="12">
                            <Form.Group controlId="4ball_booking_name" className="mb-3">
                                <Form.Label>4ball Booking Change Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    onChange={bookingHnadleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="4ball_booking_email" className="mb-3">
                                <Form.Label>4ball Booking Change Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    onChange={bookingHnadleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="4ball_booking_phone" className="mb-3">
                                <Form.Label>4ball Booking Change Contact</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    onChange={bookingHnadleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="text-end">
                            <Button
                                size="sm"
                                variant="falcon-default"
                                className="me-2"
                                type="button"
                                onClick={bookingHnadleClick}
                            >
                                Add 4ball Booking Change
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ForeBallBookingChange;
