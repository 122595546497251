import React, {useEffect, useState} from 'react';
import {Card, Dropdown, Button, Col, Form, Row, Modal, Table, InputGroup, FormControl} from 'react-bootstrap';
import CardDropdown from "../../../components/common/CardDropdown";
import {next_icon, previous_icon, response_error} from "../../../helpers/functions";
import ReactPaginate from "react-paginate";
import NewJoinersFilter from "./NewJoinersFilter";
import DatePicker from "react-datepicker";
import axios from "axios";
import Badge from "react-bootstrap/Badge";
import Flex from "../../../components/common/Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-hot-toast";
import moment from "moment";
import {format} from "date-fns";


const NewJoiners = () => {
    const [date, setDate] = useState(null);
    const [param, setParam] = useState({
        lead_source: '',
        month_of_joining_date: 'all',
        search_key: '',
        page: 1
    });

    const [paidDate, setPaidDate] = useState({
        start: null,
        end: null
    })

    const [newJoiners, setNewJoiners] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1)
    const getNewJoiners = () => {

        axios.get('/new-joiners', {params: param})
            .then((response) => {
                setNewJoiners(response.data.data);
                let total_page = parseInt(response.data.meta.total) / parseInt(response.data.meta.per_page);
                total_page = total_page > 0 ? Math.ceil(total_page) : 0;
                setPageCount(total_page);
            })
            .catch((err) => {
                response_error(err);
            })
    }

    useEffect(() => {
        getNewJoiners();
    }, [param.lead_source, param.month_of_joining_date, param.search_key, param.page, param.paid_start, param.paid_end])

    const downloadNewJoiners = () => {
        let _param = {...param};

        axios
            .get('/new-joiners/export', {params: _param, responseType: "blob"})
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'new-joiners-'+format(new Date(), 'yyyy-MM-dd-HH-mm-ss')+'.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                response_error(error);
            });
    }

    const handlePageClick = (selected) => {
        // setPage(selected.selected + 1);
        setParam({...param, page: selected.selected + 1});
    }

    const joiner_type = {
        'VIP REGISTRATION FORM': 'VIP',
        'STANDARD JOINER FORM': 'ST',
        'GOLDEN TICKET': 'GT',
    }

    const handleSetParam = (key, value) => {
        setParam({...param, [key]: value, page: 1 });
    }

    const filter_reset = () => {
        setParam({
            lead_source: '',
            month_of_joining_date: 'all',
            search_key: '',
            page: 1
        })
    }

    // const sendCreditEmail = (id) => {
    //     axios.put(`/new-joiners/${id}/credit-email-to-member`)
    //         .then((response) => {
    //             toast.success(response.data.message);
    //         })
    //         .catch((err) => {
    //             response_error(err);
    //         })
    // }

    const updateDate = (date) => {
        const [start, end] = date;
        setPaidDate({start, end});
        param.paid_start = start ? moment(start).format('YYYY-MM-DD') : '';
        param.paid_end = end ? moment(end).format('YYYY-MM-DD') : '';
    }

    const set_checkbox = (e, id, name) => {

        let  end_point = 'set-sent_to_fusion';

        if(name === 'paid_at') {
            end_point = 'set-paid-date';
        }

        axios.put(`/new-joiners/${id}/${end_point}`, {[name]: e.target.checked})
            .then((response) => {
                toast.success(response.data.message);
            })
            .catch((err) => {
                response_error(err);
            } )

        setNewJoiners(newJoiners.map(joiner => {
            if(joiner.id === id) {
                joiner[name] = e.target.checked;
            }
            return joiner;
        }))
    }

    const send_a_request = (id) => {
        axios.post(`/new-joiners/${id}/request-address-confirmation`)
            .then(response => {
                toast.success(response.data.message)
            })
            .catch(error => response_error(error))
    }

    const top_filter = (
        <Row>
            <Col lg={9}>
                <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                >

                    <Col lg="12" className="mb-2">
                        <Badge pill bg={param.month_of_joining_date === 'all' ? 'primary' : 'secondary'}
                               className='me-2' style={{cursor: 'pointer'}}
                               onClick={filter_reset}
                        >All</Badge>
                        <Badge pill bg={param.month_of_joining_date === 3 ? 'primary' : 'secondary'}
                               className='me-2' style={{cursor: 'pointer'}}
                               onClick={() => handleSetParam('month_of_joining_date', 3)}
                        >3 month referral payout report</Badge>
                        <Badge pill bg={param.lead_source === 'GOLDEN TICKET' ? 'primary' : 'secondary'}
                               className='me-2' style={{cursor: 'pointer'}}
                               onClick={() => handleSetParam('lead_source', 'GOLDEN TICKET')}
                        >Golden Ticket</Badge>
                        <Badge pill bg={param.lead_source === 'STANDARD JOINER FORM' ? 'primary' : 'secondary'}
                               className='me-2' style={{cursor: 'pointer'}}
                               onClick={() => handleSetParam('lead_source', 'STANDARD JOINER FORM')}
                        >Standard Joiner</Badge>
                        <Badge pill bg={param.lead_source === 'VIP REGISTRATION FORM' ? 'primary' : 'secondary'}
                               className='me-2' style={{cursor: 'pointer'}}
                               onClick={() => handleSetParam('lead_source', 'VIP REGISTRATION FORM')}
                        >VIP</Badge>


                    </Col>
                </Form.Group>
                <Row>
                    <Col lg={3}>
                        <DatePicker
                            placeholderText="Select Paid Date"
                            selected={paidDate.start}
                            onChange={updateDate}
                            startDate={paidDate.start}
                            formatWeekDay={day => day.toString().slice(0, 3)}
                            endDate={paidDate.end}
                            selectsRange
                            dateFormat="MMM dd yyyy"
                            className='form-control mb-2'
                        />
                    </Col>
                    <Col lg={3}>
                        <InputGroup className="position-relative">
                        <FormControl
                            id="search"
                            placeholder="Search........."
                            type="search"
                            className="shadow-none"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSetParam('search_key', e.target.value);
                                }
                            }}
                        />
                        <InputGroup.Text className="bg-transparent">
                            <FontAwesomeIcon icon="search" className="fs--1 text-600"/>
                        </InputGroup.Text>
                    </InputGroup>
                    </Col>
                </Row>
            </Col>


            <Col lg="3">
                <Flex justifyContent='end' className="align-self-center">

                     <Button
                         onClick={downloadNewJoiners}
                        variant="falcon-default"
                        className="me-2"
                        type="button"
                    >
                        Download
                    </Button>

                </Flex>

            </Col>

        </Row>
    )

    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    <h5 className="fs-0 mb-3 text-nowrap py-2 py-xl-0">New Joiners</h5>
                    {top_filter}
                </Card.Header>
                <Card.Body className="pt-0">
                    <Table responsive className="fs--1 mb-0 table table-sm table-striped overflow-hidden" role="table">
                        <thead className="bg-200 text-900 align-middle text-center">
                        <tr>
                            <th style={{maxWidth: '107px'}}>Membership No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Group</th>
                            <th style={{maxWidth: '55px'}}>Bank account added</th>
                            <th style={{maxWidth: '45px'}}>Joiner Type</th>
                            <th style={{maxWidth: '107px'}}>Membership Start Date</th>
                            <th>Referrer</th>
                            <th>Still Member</th>
                            <th>Paid Status</th>
                            <th style={{maxWidth: '65px'}}>Sent to Fusion</th>
                            <th>Credit</th>
                            <th>Email</th>
                            <th>Joiner Form</th>
                        </tr>
                        </thead>
                        <tbody>

                        {
                            newJoiners.map(joiner => {
                                let credit_color = ''
                                let email_color = ''

                                if(joiner.credited) {
                                    credit_color = 'success'
                                } else if(joiner.still_member_after_3_month) {
                                    credit_color = 'danger'
                                }

                                if(joiner.email_sent) {
                                    email_color = 'success'
                                } else if(joiner.still_member_after_3_month) {
                                    email_color = 'danger'
                                }


                                return (
                                    <tr key={joiner.id}>
                                        <td>{joiner.membership_number > 0 ? joiner.membership_number : ''}</td>
                                        <td>{joiner.first_name} {joiner.last_name}</td>
                                        <td>{joiner.email}</td>
                                        <td>{joiner.phone}</td>
                                        <td>{joiner.group_name}</td>
                                        <td align="center">{joiner.cardless_mandate ? 'Yes' : ''}</td>
                                        <td align="center">{joiner_type[joiner.lead_source]}</td>
                                        <td align="center">{joiner.joined_date}</td>
                                        <td>{joiner.referring_member}</td>
                                        <td align="center">{joiner.still_member_after_3_month ? 'Yes' : ''}</td>
                                        <td align="center">
                                            <input type="checkbox"
                                                   name={`paid_${joiner.id}`}
                                                   id={`paid_${joiner.id}`}
                                                    checked={joiner.paid_at ? 'checked' : ''}
                                                   onClick={(event) => set_checkbox(event, joiner.id, 'paid_at')}/>
                                            <br/>
                                            {joiner.paid_at}
                                        </td>
                                        <td align="center">
                                            <input type="checkbox"
                                                   name={`virtual_meeting_${joiner.id}`}
                                                   id={`virtual_meeting_${joiner.id}`}
                                                   checked={joiner.sent_to_fusion ? 'checked' : ''}
                                                   onClick={(event) => set_checkbox(event, joiner.id, 'sent_to_fusion')}/>
                                        </td>
                                        <td>
                                            {
                                                credit_color !== '' &&
                                                <FontAwesomeIcon icon={faCreditCard} className={`text-${credit_color} ms-2`}/>

                                            }
                                        </td>
                                        <td>
                                            {
                                                email_color !== '' &&
                                                    <FontAwesomeIcon icon="envelope" className={`text-${email_color} ms-2`}/>

                                            }
                                        </td>
                                        <td align="center">
                                            <CardDropdown iconClassName="fs--1" drop="start">
                                                <div className="py-2">
                                                    <Dropdown.Item href={`https://www.fore-business.com/new-member-sign-up?email=${joiner.email}`}>Join</Dropdown.Item>
                                                    <Dropdown.Item>Send GT</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => send_a_request(joiner.id)}>Request Address Confirmation</Dropdown.Item>
                                                </div>
                                            </CardDropdown>
                                        </td>
                                    </tr>
                                )
                                }

                            )
                        }

                        </tbody>
                    </Table>

                </Card.Body>
                 <Card.Footer>
                    <ReactPaginate
                        previousLabel={previous_icon()}
                        nextLabel={next_icon()}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination mb-0 mx-1'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </Card.Footer>
            </Card>
        </>
    );
};

export default NewJoiners;

