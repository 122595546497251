import axios from 'axios';
import {SitesContext} from 'context/Context';
import React, {useEffect, useState} from 'react';
import SitesTable from './SitesTable';
import {Card, Col, Form, Row, Button, Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Select from "react-select";
import {useParams} from "react-router-dom";
import {toast} from "react-hot-toast";
import {response_error} from "../../../helpers/functions";

const SiteDetails = () => {
    const [show, setShow] = useState(false);
    const {regionId} = useParams();

    const [memberOptions, setMemberOptions] = useState([]);
    const [selectedAmbassador, setSelectedAmbassador] = useState();

    useEffect(() => {
        axios.get('/ambassadors')
            .then(response => {
                let options = response.data.map(ambassador => {
                    return {
                        value: ambassador.id,
                        label: `${ambassador.first_name} ${ambassador.last_name} - ${ambassador.email}`
                    }
                })

                setMemberOptions(options);
            })

    }, [])

    const _site = {
        id: 1,
        name: '',
        slug: '',
        ambassadors: [
            {
                name: '',
                title: 'Ambassador',
                email: ''
            }
        ]
    }

    const [site, setSite] = useState(_site)

    useEffect(() => {
        axios.get(`/sites/${regionId}`)
            .then(response => {
                const ambassadors = response.data.ambassadors.map(ambassador => {
                    return {
                        name: ambassador.user.first_name + ' ' + ambassador.user.last_name,
                        title: 'Ambassador',
                        email: ambassador.user.email,
                        id: ambassador.id
                    }
                })

                setSite(prevState => ({
                        ...site, ambassadors: ambassadors,
                        id: response.data.id,
                        name: response.data.name,
                        slug: response.data.slug
                    }
                ))
            })
    }, [])

    const ambassadorHandleChange = (e) => {
        setSelectedAmbassador(e.value);
    }

    const handleAttachAmbassador = () => {
        axios.put(`/sites/${regionId}/ambassador`, {ambassador_id: selectedAmbassador})
            .then(response => {
                toast.success(response.data.message)

                const ambassador = {
                    name: response.data.ambassador.user.first_name + ' ' + response.data.ambassador.user.last_name,
                    title: 'Ambassador',
                    email: response.data.ambassador.user.email
                }
                const ambassadors = site.ambassadors;
                ambassadors.push(ambassador)

                setSite(prevState => ({
                        ...site, ambassadors: ambassadors,
                    }
                ))

                setShow(false)

            })
    }

    const handleDetachAmbassador = (ambassador_id) => {
        axios.delete(`/sites/${regionId}/ambassador?ambassador_id=${ambassador_id}`)
            .then(response => {
                toast.success(response.data.message)
                setSite(prevState => ({
                        ...site, ambassadors: site.ambassadors.filter(ambassador => ambassador_id !== ambassador.id),
                    }
                ))
            })
            .catch(error => {
                response_error(error)
            })
    }

    const Actions = (prop) => {
        return (
            <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
                {/*<Button variant="light" size="sm" className="border-300 me-1 text-600">*/}
                {/*    <FontAwesomeIcon icon="edit"/>*/}
                {/*</Button>*/}
                <Button variant="light" size="sm" className="border-300 text-600" onClick={() => handleDetachAmbassador(prop.id)}>
                    <FontAwesomeIcon icon="trash-alt"/>
                </Button>
            </div>

        );
    };
    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    <Row className="flex-between-center">
                        <Col md>
                            <h5 className="mb-2 mb-md-0">{site.name}</h5>
                        </Col>
                        <Col xs="auto">
                            <Button
                                size="sm"
                                variant="falcon-default"
                                className="me-2"
                                type="button"
                            >
                                Save Region
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Row>
                <Col md={8}>
                    <Card className="mb-3">
                        <Card.Body className="bg-light">
                            <Row className="gx-2 gy-3">
                                <Col md="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name='name'
                                            value={site.name}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Slug</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name='slug'
                                            value={site.slug}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="mb-3">
                        <Card.Header as="h5">
                            <Row className="flex-between-center">
                                <Col md>
                                    <h5 className="mb-2 mb-md-0"> Ambassador</h5>
                                </Col>
                                <Col xs="auto">
                                    <Button
                                        onClick={() => setShow(true)}
                                        size="sm"
                                        variant="falcon-default"
                                        className="me-2"
                                        type="button"
                                    >
                                        Add Ambassador
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <div className="border-dashed-bottom"></div>
                        <Card.Body className="bg-light">
                            <Row className="gx-2 gy-3">

                                {
                                    site.ambassadors.map(ambassador => (
                                        <Col md="4" key={ambassador.id}>
                                            <Card className="mb-3 card_no_shadow">
                                                <Card.Body>
                                                    <div className="flex-1 position-relative hover-actions-trigger">
                                                        <h6 className="fs-0 mb-0">{ambassador.name}</h6>
                                                        <p className="text-secondary mb-0 fs-0">{ambassador.title}<br/> {ambassador.email}
                                                        </p>
                                                        <Actions id={ambassador.id}/>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
            <Modal
                size=" "
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add Ambassador
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Ambassador</Form.Label>
                                <Select
                                    options={memberOptions}
                                    onChange={ambassadorHandleChange}
                                    // value="Rayhan"
                                    name='ambassador_id'
                                />
                            </Form.Group>
                        </Col>
                        {/*<Col lg={12}>*/}
                        {/*    <Form.Group className="mb-3">*/}
                        {/*        <Form.Label>Title</Form.Label>*/}
                        {/*        <Select*/}
                        {/*            // options={title}*/}
                        {/*            // onChange={ambassadorTitleHandleChange}*/}
                        {/*            // value="Ambassador"*/}
                        {/*            name='title'*/}
                        {/*        />*/}
                        {/*    </Form.Group>*/}
                        {/*</Col>*/}
                        <Col className="text-end">
                            <Button
                                size="sm"
                                variant="falcon-default"
                                className="me-2"
                                type="button"
                                onClick={handleAttachAmbassador}
                            >
                                Add Ambassador
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SiteDetails;