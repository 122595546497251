import React, {Fragment, useEffect, useState} from 'react';
import {Card, Dropdown, Button, Col, Form, Row, Modal, InputGroup, FormControl} from 'react-bootstrap';
import CardDropdown from './../../../components/common/CardDropdown';
import axios from 'axios';
import {next_icon, previous_icon, response_error} from '../../../helpers/functions';
import ReactPaginate from "react-paginate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TinymceEditor from "../../../components/common/TinymceEditor";
import IconButton from "../../../components/common/IconButton";
import Paginate from "../../../components/common/Paginate";

const Email = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [search, setSearch] = useState('');
    const templateData = {
        id: '',
        to: '',
        cc: '',
        type: '',
        subject: '',
        body: '',
        notes: ''
    }

    const [emailTemplate, setEmailTemplate] = useState(templateData);
    const [emailTemplates, setEmailTemplates] = useState([
        templateData
    ])

    const [resource, setResource] = useState({});

    const handleOnchange = e => {
        setEmailTemplate(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleOnchangeEditor = value => {
        setEmailTemplate(prevState => ({
            ...prevState,
            'body': value
        }))
    }

    const getResource = (page) => {
        axios.get('/email-templates', {params: {page: page, search}})
            .then(response => {
                setEmailTemplates(response.data.data)
                setResource(response.data)
            })
            .catch(error => {
                response_error(error);
            })
    }

    useEffect(() => {
        getResource(1)
    }, [])

    const handleDelete = (email_template_id) => {
        axios.delete(`/email-templates/${email_template_id}`)
            .then(response => {
                setEmailTemplates(response.data.data)
            })
            .catch(error => {
                response_error(error);
            })
    }

    const handleSave = () => {
        if (emailTemplate.id > 0) {
            axios.put(`/email-templates/${emailTemplate.id}`, emailTemplate)
                .then(response => {
                    setEmailTemplates(response.data.data)
                    setEmailTemplate(templateData);
                })
                .catch(error => {
                    response_error(error);
                })
        } else {
            axios.post('/email-templates', emailTemplate)
                .then(response => {
                    setEmailTemplates(response.data.data)
                    setEmailTemplate(templateData);
                })
                .catch(error => {
                    response_error(error);
                })
        }

        setModalShow(false);

    }

    const handleEdit = (email_template_id) => {
        setModalShow(true);
        axios.get(`/email-templates/${email_template_id}`)
            .then(response => {
                setEmailTemplate(response.data);
            })
            .catch(error => {
                response_error(error);
            })
    }

    const searchOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            getResource(1);
        }
    }

    return (
        <Fragment>
            <Row className="g-0 mt-3">
                <Col lg={12}>
                    <Row className="g-0">
                        <Col lg={12}>
                            <Card className="h-lg-100">
                                <Card.Header>
                                    <Row className="flex-between-center">
                                        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                                            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0 me-3">Email</h5>
                                            <InputGroup className="position-relative">
                                                <FormControl
                                                    id="search"
                                                    placeholder="Search........."
                                                    type="search"
                                                    className="shadow-none"
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    onKeyDown={searchOnKeyDown}
                                                />
                                                <InputGroup.Text className="bg-transparent" onClick={() => getResource(1)} >
                                                    <FontAwesomeIcon icon="search" className="fs--1 text-600" />
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                                            <IconButton
                                                variant="falcon-default"
                                                size="sm"
                                                icon="plus"
                                                transform="shrink-3"
                                                onClick={() => setModalShow(true)}
                                            >
                                                <span className="d-none d-sm-inline-block ms-1">Add</span>
                                            </IconButton>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <div className="border-dashed-bottom"/>
                                <Card.Body>
                                    <div className="table-responsive">
                                        <table className="fs--1 mb-0 table table-sm table-striped overflow-hidden"
                                               role="table">
                                            <thead className="bg-200 text-900 text-nowrap align-middle">
                                            <tr>
                                                <th>Type</th>
                                                <th>Subject</th>
                                                <th>To</th>
                                                <th>Notes</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>


                                            {
                                                emailTemplates.map(emailTemplate => {
                                                    return (
                                                        <tr className="align-middle white-space-nowrap" role="row"
                                                            key={emailTemplate.id}>
                                                            <td role="cell" className="py-2" style={{textWrap: 'wrap'}}>{emailTemplate.type}</td>
                                                            <td role="cell" style={{textWrap: 'wrap'}}>{emailTemplate.subject}</td>
                                                            <td role="cell" style={{textWrap: 'wrap'}}>{emailTemplate.to}</td>
                                                            <td role="cell" style={{textWrap: 'wrap'}}>{emailTemplate.notes}</td>
                                                            <td role="cell" className="text-end">
                                                                <CardDropdown iconClassName="fs--1" drop="start">
                                                                    <div className="py-2">
                                                                        <Dropdown.Item className="text-success"
                                                                                       onClick={() => handleEdit(emailTemplate.id)}>
                                                                            Edit
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item className="text-danger"
                                                                                       onClick={() => handleDelete(emailTemplate.id)}>
                                                                            Delete
                                                                        </Dropdown.Item>
                                                                    </div>
                                                                </CardDropdown>

                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                                <Card.Footer>
                                    <Paginate resource={resource} getResource={getResource}/>
                                </Card.Footer>
                                <Modal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    enforceFocus={false}
                                    centered
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">Email Template</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="bg-light">
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>To</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="To"
                                                        onChange={handleOnchange}
                                                        value={emailTemplate.to}
                                                        name="to"/>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>CC</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="CC"
                                                        name="cc"
                                                        onChange={handleOnchange}
                                                        value={emailTemplate.cc}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Type</Form.Label>
                                                    <Form.Control type="text" placeholder="Type"
                                                                  name="type"
                                                                  onChange={handleOnchange}
                                                                  value={emailTemplate.type}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Notes</Form.Label>
                                                    <Form.Control type="text" placeholder="Type"
                                                                  name="notes"
                                                                  onChange={handleOnchange}
                                                                  value={emailTemplate.notes}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Subject</Form.Label>
                                                    <Form.Control type="text" placeholder="Subject"
                                                                  name="subject"
                                                                  onChange={handleOnchange}
                                                                  value={emailTemplate.subject}
                                                    />

                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Message</Form.Label>
                                                    <TinymceEditor
                                                        name="body"
                                                        handleChange={handleOnchangeEditor}
                                                        value={emailTemplate.body}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} className="text-end">
                                                <Button variant='falcon-default' className='me-2 mb-1'
                                                        size="sm" onClick={() => handleSave()}>Save Email</Button>
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                </Modal>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Fragment>
    );
};

export default Email;
