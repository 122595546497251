import React, {useEffect} from 'react';
import {Card, Col, Form, Modal, Row} from "react-bootstrap";
import {format} from "date-fns";
import ReactPaginate from "react-paginate";
import {next_icon, previous_icon, response_error} from "../../../helpers/functions";
import TinymceEditor from "../../../components/common/TinymceEditor";
import IconButton from "../../../components/common/IconButton";
import MemberSearchFilter from "../MemberInfo/MemberSearchFilter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './nocopy.css';
import axios from "axios";
import toasts from "../../../components/doc-components/Toasts";

export const Members = () => {
    useEffect(() => {
        const handleCopy = (e) => {
            e.preventDefault();
        };
        const handleSelect = (e) => {
            e.preventDefault();
        };

        document.addEventListener('copy', handleCopy);
        document.addEventListener('cut', handleCopy);
        document.addEventListener('paste', handleCopy);
        document.addEventListener('selectstart', handleSelect);
        document.addEventListener('contextmenu', handleCopy);

        return () => {
            document.removeEventListener('copy', handleCopy);
            document.removeEventListener('cut', handleCopy);
            document.removeEventListener('paste', handleCopy);
            document.removeEventListener('selectstart', handleSelect);
            document.removeEventListener('contextmenu', handleCopy);
        };
    }, []);

    const [modalShow, setModalShow] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [members, setMembers] = React.useState([]);

    const [emailTemplate, setEmailTemplate] = React.useState({
        subject: '',
        body: ''
    });

    const [currentMember, setCurrentMember] = React.useState(null);

    const handlePageClick = page => {
        setPage(page.selected + 1);
    }

    const getMembers = () => {
        axios.get(`/member/members`)
            .then(response => {
                setMembers(response.data.data);
                let total_page = parseInt(response.data.total) / parseInt(response.data.per_page);
                total_page = total_page > 0 ? Math.ceil(total_page) : 0;
                setPageCount(total_page);
            })
            .catch(error => {
                response_error(error);
            })
    }

    const sendEmail = () => {
        axios.post(`/member/members/${currentMember}/send-email`, emailTemplate)
            .then(response => {
                setModalShow(false);
                toasts.success(response.data.message);
            })
            .catch(error => {
                response_error(error);
            })
    }

    const openEmailModal = (member) => {
        setCurrentMember(member);
        setModalShow(true);
    }

    useEffect(() => {
        getMembers();

    }, [])

    return (
        <Row className="g-0 mb-3">
            <Col lg={12} className="pe-lg-2">
                <Card className="h-lg-100">
                    <Card.Header className="pb-0">
                        <h5 className="fs-0 mb-3 text-nowrap py-2 py-xl-0">Members</h5>
                        <MemberSearchFilter/>
                    </Card.Header>
                    <Card.Body className="pt-0">
                        <div className="table-responsive">
                            <table className="fs--1 mb-0 table table-sm table-striped overflow-hidden no-copy"
                                   role="table">
                                <thead className="bg-200 text-900 text-nowrap align-middle">
                                <tr>
                                    <th>Name</th>
                                    <th>Profession</th>
                                    <th>Phone Number</th>
                                    <th>Handicap</th>
                                    <th>Social Links</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    members.map(member => (
                                        <tr className="align-middle white-space-nowrap">
                                            <td>{member.user.name}</td>
                                            <td>{member.profession}</td>
                                            <td>{member.phone1}</td>
                                            <td>{member.handicap}</td>
                                            <td>
                                                {
                                                    member.facebook && member.facebook !== '' && (
                                                        <a href={member.facebook} target="_blank" rel="noreferrer">
                                                            <FontAwesomeIcon icon={['fab', 'facebook-f']}
                                                                             className="text-facebook fs-1 me-2"/>
                                                        </a>
                                                    )
                                                }
                                                {
                                                    member.linkedin && member.linkedin !== '' && (
                                                        <a href={member.linkedin} target="_blank" rel="noreferrer">
                                                            <FontAwesomeIcon icon={['fab', 'linkedin-in']}
                                                                             className="text-linkedin fs-1"/>
                                                        </a>
                                                    )
                                                }

                                            </td>
                                            <td>
                                                <button className="btn btn-sm btn-primary outline-primary"
                                                        onClick={() => openEmailModal(member.id)}>Send Email
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }


                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <ReactPaginate
                            previousLabel={previous_icon()}
                            nextLabel={next_icon()}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination mb-0 mx-1'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </Card.Footer>
                </Card>
            </Col>


            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                enforceFocus={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Email</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-light">
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Subject</Form.Label>
                                <Form.Control type="text"
                                              placeholder="Subject"
                                              name="subject"
                                                onChange={(e) => setEmailTemplate({...emailTemplate, subject: e.target.value})}
                                />

                            </Form.Group>
                        </Col>
                        <Col lg={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Message</Form.Label>
                                <TinymceEditor
                                    name="body"
                                    handleChange={(value) => setEmailTemplate({...emailTemplate, body: value})}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={12} className="text-end">
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="paper-plane"
                                transform="shrink-3"
                                onClick={sendEmail}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Send</span>
                            </IconButton>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        </Row>
    );
}