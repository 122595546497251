import {next_icon, previous_icon} from "../../helpers/functions";
import ReactPaginate from "react-paginate";
import React, {useEffect, useState} from "react";

const Paginate = ({resource, getResource}) => {

    // const [page, setPage] = useState(1);
    const handlePageClick = page => {
        // setPage(page.selected + 1);
        getResource(page.selected + 1)
    }
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        if(resource.hasOwnProperty('meta')){
            let total_page = parseInt(resource.meta.total) / parseInt(resource.meta.per_page);
            total_page = total_page > 0 ? Math.ceil(total_page) : 0;
            setPageCount(total_page);
        }
        console.log(resource.meta);
    }, [resource])

    return (
        <ReactPaginate
            previousLabel={previous_icon()}
            nextLabel={next_icon()}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination mb-0 mx-1'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
        />
    )
}

export default Paginate;