import React, {Fragment, useEffect, useState} from 'react';
import {Card, Dropdown, Button, Col, Form, Row, Modal} from 'react-bootstrap';
import CardDropdown from './../../../components/common/CardDropdown';
import axios from 'axios';
import Select from 'react-select';
import {next_icon, previous_icon, response_error} from '../../../helpers/functions';
import IconButton from "../../../components/common/IconButton";
import Paginate from "../../../components/common/Paginate";

const Team = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const teamData = {
        id:'',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        roles: [
            {
                id: '',
                name: ''
            }
        ]
    }
    const role = [
        {value: 1, label: 'Administrator'},
        {value: 2, label: 'Editor'},
        {value: 3, label: 'Member'},
        {value: 5, label: 'Staff'},
        {value: 4, label: 'Subscriber'}
    ];


    const [team, setTeam] = useState(teamData);
    const [teams, setTeams] = useState([
        teamData
    ])

    const [resource, setResource] = useState({});

    const handleOnchange = e => {
        setTeam(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const getResource = (page) => {
        axios.get('/teams', {params: {page: page}})
            .then(response => {
                setTeams(response.data)
                setResource(response.data)
            })
            .catch(error => {
                response_error(error);
            })
    }

    useEffect(() => {
        getResource(1)
    }, [])

    const handleDelete = (team_id) => {
        axios.delete(`/teams/${team_id}`)
            .then(response => {
                setTeams(response.data)
            })
            .catch(error => {
                response_error(error);
            })
    }

    const handleSave = () => {
        if(team.id > 0){
            axios.put(`/teams/${team.id}`, team)
                .then(response => {
                    setTeams(response.data)
                    setTeam(teamData);
                })
                .catch(error => {
                    response_error(error);
                })
        }
        else{
            axios.post('/teams', team)
                .then(response => {
                    setTeams(response.data)
                    setTeam(teamData);
                })
                .catch(error => {
                    response_error(error);
                })
        }

        setModalShow(false);

    }

    const handleEdit = (team_id) => {
        setModalShow(true);
        axios.get(`/teams/${team_id}`)
            .then(response => {
                setTeam(response.data);
            })
            .catch(error => {
                response_error(error);
            })
    }

    const onChangeRole = (value) => {
        let roles = [];
        value.map(function (a){
            roles.push({id: a.value, name: a.label})
        })
        setTeam({...team, roles: roles})
    }

    return (
        <Fragment>
            <Row className="g-0 mt-3">
                <Col lg={12}>
                    <Row className="g-0">
                        <Col lg={12}>
                            <Card className="h-lg-100">
                                <Card.Header>
                                    <Row className="flex-between-center">
                                        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                                            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Team</h5>
                                        </Col>
                                        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                                            <IconButton
                                                variant="falcon-default"
                                                size="sm"
                                                icon="plus"
                                                transform="shrink-3"
                                                onClick={() => setModalShow(true)}
                                            >
                                                <span className="d-none d-sm-inline-block ms-1">Add</span>
                                            </IconButton>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <div className="border-dashed-bottom"/>
                                <Card.Body>
                                    <div className="table-responsive">
                                    <table className="fs--1 mb-0 table table-sm table-striped overflow-hidden"
                                           role="table">
                                        <thead className="bg-200 text-900 text-nowrap align-middle">
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>Role</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>


                                        {
                                            teams.map(team => {
                                                return (
                                                    <tr className="align-middle white-space-nowrap" role="row"
                                                        key={team.id}>
                                                        <td role="cell" className="py-2">{team.first_name}</td>
                                                        <td role="cell">{team.last_name}</td>
                                                        <td role="cell">{team.email}</td>
                                                        <td role="cell">{team.roles.map((a) => a.name, team.roles).join(', ')}</td>
                                                        <td role="cell" className="text-end">
                                                            <CardDropdown iconClassName="fs--1" drop="start">
                                                                <div className="py-2">
                                                                    <Dropdown.Item className="text-success"
                                                                                   onClick={() => handleEdit(team.id)}>
                                                                        Edit
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="text-danger"
                                                                                   onClick={() => handleDelete(team.id)}>
                                                                        Delete
                                                                    </Dropdown.Item>
                                                                </div>
                                                            </CardDropdown>

                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                        </tbody>
                                    </table>
                                        </div>
                                </Card.Body>
                                <Card.Footer>
                                    <Paginate resource={resource} getResource={getResource}/>
                                </Card.Footer>
                                <Modal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">Team</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="bg-light">
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="First Name"
                                                        onChange={handleOnchange}
                                                        value={team.first_name}
                                                        name="first_name"/>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Last Name"
                                                        name="last_name"
                                                        onChange={handleOnchange}
                                                        value={team.last_name}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Email"
                                                        name="email"
                                                        onChange={handleOnchange}
                                                        value={team.email}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control type="password" placeholder="Password"
                                                                  name="password"
                                                                  onChange={handleOnchange}
                                                                  value={team.password}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Role</Form.Label>
                                                    <Select
                                                    isMulti={true}
                                                    name="roles"
                                                    options={role}
                                                    onChange={
                                                        value => onChangeRole(value)
                                                    }
                                                />

                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} className="text-end">
                                                <Button variant='falcon-default' className='me-2 mb-1'
                                                        size="sm" onClick={() => handleSave()}>Add Team</Button>
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                </Modal>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Fragment>
    );
};

export default Team;
