import React, {Component} from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import PaymentMethod from "../../components/app/e-commerce/checkout/PaymentMethod";
import logoutImg from 'assets/img/icons/spot-illustrations/21.png';
import Header from "./Header";

const PaymentSuccess = () => {

    return (
        <>
            <Header/>
            <Container>
                <Row  className="justify-content-center">
                    <Col lg={4} align="center">
                        <Card>
                            <Card.Body>
                                <img
                                    className="d-block mx-auto mb-4"
                                    src={logoutImg}
                                    alt="shield"
                                    width={100}
                                />
                                <h4>Thanks for your payment</h4>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default PaymentSuccess;