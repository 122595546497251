import React, { useContext } from 'react';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import { Col, Form, Row, Button, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import RegionForm from './RegionForm';
import { RegionContext } from 'context/Context';

const RegionsTableHeader = ({ selectedRowIds }) => {
    const {modalShow, setModalShow} = useContext(RegionContext);
    return (
        <>
            <Row className="flex-between-center">
                <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">All Countries</h5>
                </Col>
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">

                    <div id="orders-actions">
                        <IconButton
                            onClick={() => setModalShow(true)}
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                        >
                            <span className="d-none d-sm-inline-block ms-1">New</span>
                        </IconButton>
                    </div>

                </Col>
            </Row>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Create Country</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RegionForm setModalShow={setModalShow}/>
                </Modal.Body>
            </Modal>
        </>
    );
};

RegionsTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default RegionsTableHeader;
