import React, {useContext, useState} from 'react';
import {Button, Form, Card, Col, Row, Modal} from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import DatePicker from 'react-datepicker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from "axios";
import toast from "react-hot-toast";
import {response_error} from "../../../../helpers/functions";
import moment from "moment/moment";
import {MemberContext} from "../../../../context/Context";

const RedShirt = () => {
    const { memberData, setMemberData, memberId } = useContext(MemberContext);
    const [show, setShow] = useState(false);
    const [confirmedAt, setConfirmedAt] = useState('');
    const [nextAttemptAt, setNextAttemptAt] = useState('');
    const openModel = () => {
        setShow(true);
    };

     const Actions = (prop) => {
        return (
            <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
                <Button variant="light" size="sm" className="border-300 text-600" onClick={() => handleDelete(prop.id)}>
                    <FontAwesomeIcon icon="trash-alt" />
                </Button>
            </div>

        );
    };

     // const [redShirts, setRedShirts] = useState(memberData ? memberData.red_shirts : [] )

    const handleDelete = (id) => {
        axios.delete(`/members/${memberId}/red-shirt/${id}`)
            .then(response => {
                setMemberData(prevState => ({...prevState, red_shirts: response.data}))
                toast.success('Successfully deleted');
            })
            .catch(error => {
                response_error(error);
            });
    }

    const submit = (e) => {
         e.preventDefault();
         let data = {
             confirmed_at: confirmedAt ? moment(confirmedAt).format('YYYY-MM-DD HH:mm:ss') : '',
             next_attempt_at: nextAttemptAt ? moment(nextAttemptAt).format('YYYY-MM-DD HH:mm:ss') : '',
             attempt: e.target.attempt.value,
             type: e.target.type.value,
         };

         axios.post(`/members/${memberId}/red-shirt`, data)
             .then(response => {
                 setMemberData(prevState => ({...prevState, red_shirts: response.data}))
                 toast.success('Successfully saved');
             })
             .catch(error => {
                 response_error(error);
             });
    }

    return (
        <>
            <Row className="g-0 mb-3 row">
                <Col lg={12} className="pe-lg-2">
                    <Card className="h-lg-100">
                        <Card.Header as="h5">
                            <Row>
                                <Col lg={6} md={6} sm={6}>
                                    Red Shirt
                                </Col>
                                <Col lg={6} md={6} sm={6} className="text-end">
                                    <Button
                                        size="sm"
                                        variant="falcon-default"
                                        className="me-2"
                                        onClick={() => openModel()}
                                    >

                                        Add Red Shirt
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <div className="border-dashed-bottom"></div>
                        <SimpleBarReact style={{maxHeight: '19rem'}}>
                            <Card.Body className="bg-light">
                                <Card.Body className="p-0">
                                    <Row className="gx-2 gy-3">
                                        {
                                            memberData && memberData.red_shirts.length > 0 ?
                                                memberData.red_shirts.filter(redShirt => redShirt.type !== '').map(redShirt => (

                                                    <Col md="4" key={redShirt.id}>
                                                        <Card className="mb-3 card_no_shadow">
                                                            <Card.Body>
                                                                <div className="flex-1 position-relative hover-actions-trigger">
                                                                    <h6 className="fs-0 mb-2">{redShirt.type}</h6>
                                                                    {
                                                                        redShirt.confirmed_at ? (<><Form.Label className="mb-0">Confirmed at : {redShirt.confirmed_at}</Form.Label> <br/></>)
                                                                            :
                                                                            (<><Form.Label className="mb-0">Notify at : {redShirt.next_attempt_at}</Form.Label> <br/>
                                                                                <Form.Label className="mb-0">Attempt : {redShirt.attempt}</Form.Label> <br/></>)
                                                                    }
                                                                    <Actions id={redShirt.id}/>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                ))
                                            : (<p>No Red Shirt</p>)
                                        }
                                    </Row>
                                </Card.Body>
                            </Card.Body>
                        </SimpleBarReact>
                    </Card>
                </Col>
            </Row>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Add Red Shirt</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="" onSubmit={submit}>
                    <Row>
                        <Col md="12">
                            <Form.Group className="mb-3">
                                <Form.Label>Confirmed At</Form.Label>
                                <DatePicker
                                    className='form-control'
                                    placeholderText="Select Date & Time"
                                    timeIntervals={5}
                                    dateFormat="yyyy-MM-dd H:mm:ss"
                                    showTimeSelect
                                    fixedHeight
                                    name='confirmed_at'
                                    selected={confirmedAt}
                                    onChange={setConfirmedAt}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="12">
                            <Form.Group className="mb-3">
                                <Form.Label>Send Notification At</Form.Label>
                                <DatePicker
                                    className='form-control'
                                    placeholderText="Select Date & Time"
                                    timeIntervals={5}
                                    dateFormat="yyyy-MM-dd H:mm:ss"
                                    showTimeSelect
                                    fixedHeight
                                    name='next_attempt_at'
                                    selected={nextAttemptAt}
                                    onChange={setNextAttemptAt}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="12">
                            <Form.Group className="mb-3">
                                <Form.Label>Attempt</Form.Label>
                                <Form.Control
                                    placeholder="Attempt"
                                    name="attempt"
                                    type="number"
                                    defaultValue={0}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="12">
                            <Form.Group className="mb-3">
                                <Form.Label>Type</Form.Label>
                                <Form.Control
                                    placeholder="Type"
                                    name="type"
                                    type="type"
                                    defaultValue='Red Shirt'
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="text-end">
                        <Button
                            variant="falcon-default"
                            className="me-2"
                            size="sm"
                            type="submit"
                        >
                            Add Red Shirt
                        </Button>
                    </Form.Group>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default RedShirt;