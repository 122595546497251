import axios from 'axios';
import { SitesContext } from 'context/Context';
import React, { useEffect, useState } from 'react';
import SitesTable from './SitesTable';

const Sites = () => {
    return (
       <SitesTable/>
    )
}

export default Sites;