import React, {Component, useContext, useEffect, useState} from 'react';
import {Form, Card, Col, Row, Dropdown} from 'react-bootstrap';
import SoftBadge from "../../../components/common/SoftBadge";
import ReactPaginate from "react-paginate";
import {next_icon, previous_icon, response_error} from "../../../helpers/functions";
import axios from "axios";

const OrderHistory = () => {
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [orders, setOrders] = useState([]);
    const handlePageClick = page => {
        setPage(page.selected + 1);
    }
    const getOrders = () => {
        axios.get(`/member/orders`)
            .then(response => {
                //console.log(response.data[0].data)
                setOrders(response.data[0].data);
                // Pagination
                let total_page = parseInt(response.data[0].total) / parseInt(response.data[0].per_page);
                total_page = total_page > 0 ? Math.ceil(total_page) : 0;
                setPageCount(total_page);
                // End Pagination
            })
            .catch(error => {
                response_error(error);
            });
    }
    useEffect(() => {
        getOrders();
    }, []);
    return (
        <Row className="g-0 mb-3">
            <Col lg={12} className="pe-lg-2">
                <Card className="h-lg-100">
                    <Card.Header className="pb-0">
                        <h5 className="fs-0 mb-3 text-nowrap py-2 py-xl-0">Orders</h5>
                    </Card.Header>
                    <Card.Body className="pt-0">
                        <div className="table-responsive">
                            <table className="fs--1 mb-0 table table-sm table-striped overflow-hidden" role="table">
                                <thead className="bg-200 text-900 text-nowrap align-middle">
                                <tr>
                                    <th>Product Name</th>
                                    <th>Points</th>
                                    <th>Order ID</th>
                                    <th>Product ID</th>
                                    <th className="text-center">Order Status</th>
                                    {/*<th></th>*/}
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    orders.map((order, index) => (
                                        order.items.map(item => (
                                                <tr className="align-middle white-space-nowrap" role="row" key={index}>
                                                    <td role="cell" className="py-2">{item.product.name}</td>
                                                    <td role="cell">{item.product.point}</td>
                                                    <td role="cell">{item.product.sub_title}</td>
                                                    <td role="cell">{item.product.description}</td>
                                                    <td role="cell" className="fs-0 text-center">
                                                        <SoftBadge pill bg={
                                                            order.status === 'completed' ? 'success' :
                                                                order.status === 'draft' ? 'warning' :
                                                                    order.status === 'inactive' ? 'danger' :
                                                                        ''
                                                        } className='text-capitalize'>{order.status}</SoftBadge>
                                                    </td>
                                                </tr>
                                            ))

                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <ReactPaginate
                            previousLabel={previous_icon()}
                            nextLabel={next_icon()}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination mb-0 mx-1'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    );
};

export default OrderHistory;