import React from 'react';
import { Card, Col, Button, Row } from 'react-bootstrap';

const GroupFooter = (prop) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0"> {prop.group.name}</h5>
          </Col>
          <Col xs="auto">
            <Button
              size="sm"
              variant="falcon-default"
              className="me-2"
              type="button"
              onClick={prop.groupUpdate}
            >
              Save Group
            </Button>

          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default GroupFooter;
