import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Button, Form} from 'react-bootstrap';
import toast from "react-hot-toast";
import {response_error} from "../../helpers/functions";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const ForgetPasswordForm = () => {
    // State
    const [email, setEmail] = useState('');

    const navigate = useNavigate();

    // Handler
    const handleSubmit = e => {
        e.preventDefault();
        if (email) {
            sessionStorage.setItem('email', email);
            axios.post( '/forgot-password', {email})
                .then(response => {
                    navigate('/confirm-mail');
                })
            .catch(error => {
                response_error(error);
            })
        }
    };

    return (
        <Form className="mt-4" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Control
                    placeholder={'Email address'}
                    value={email}
                    name="email"
                    onChange={({target}) => setEmail(target.value)}
                    type="email"
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Button className="w-100" type="submit" disabled={!email}>
                    Send reset link
                </Button>
            </Form.Group>

            <Link className="fs--1 text-600" to="#!">
                I can't recover my account using this page
                <span className="d-inline-block ms-1"> &rarr;</span>
            </Link>
        </Form>
    );
};

ForgetPasswordForm.propTypes = {
    layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = {layout: 'simple'};

export default ForgetPasswordForm;
