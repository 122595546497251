import React, {useEffect, useState} from 'react';
import {Card, Dropdown, Button, Col, Form, Row, Modal} from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import axios from "axios";
import {next_icon, previous_icon, response_error} from "../../../helpers/functions";
import ReactPaginate from "react-paginate";
import WinnerFilter from "./WinnerFilter";
import {format} from "date-fns";
import toast from "react-hot-toast";

//import GroupFilterForm from "./GroupTable/GroupFilterForm";
const Winner = () => {
    const [param, setParam] = useState({
        // search: searchParams.get('search') ?? '',
        group_ids: [],
        confirmed: 'all',
        name: '',
        won_at_from: '',
        won_at_to: '',
        confirmed_at_from: '',
        confirmed_at_to: '',
        red_shirt_size: '',
        page: 1
    });

    const [winners, setWinners] = useState([])
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1)

    const getWinners = (page) => {
        let _param = {...param}

        _param.group_ids = param.group_ids.map(item => item.value)
        _param.confirmed = param.confirmed.value
        _param.page = page
        _param.confirmed_at_from = param.confirmed_at_from ? format(new Date(param.confirmed_at_from), 'yyyy-MM-dd') : ''
        _param.confirmed_at_to = param.confirmed_at_to ? format(new Date(param.confirmed_at_to), 'yyyy-MM-dd') : ''
        _param.won_at_to = param.won_at_to ? format(new Date(param.won_at_to), 'yyyy-MM-dd') : ''
        _param.won_at_from = param.won_at_from ? format(new Date(param.won_at_from), 'yyyy-MM-dd') : ''

        axios.get('/winners', {params: _param})
            .then(response => {
                setWinners(response.data.data)
                let total_page = parseInt(response.data.total) / parseInt(response.data.per_page);
                total_page = total_page > 0 ? Math.ceil(total_page) : 0;
                setPageCount(total_page);
            })
            .catch(error => {
                console.log(error)
                response_error(error);
            });
    }

    const downloadWinners = () => {
        let _param = {...param}

        _param.group_ids = param.group_ids.map(item => item.value)
        _param.confirmed = param.confirmed.value
        _param.confirmed_at_from = param.confirmed_at_from ? format(new Date(param.confirmed_at_from), 'yyyy-MM-dd') : ''
        _param.confirmed_at_to = param.confirmed_at_to ? format(new Date(param.confirmed_at_to), 'yyyy-MM-dd') : ''
        _param.won_at_to = param.won_at_to ? format(new Date(param.won_at_to), 'yyyy-MM-dd') : ''
        _param.won_at_from = param.won_at_from ? format(new Date(param.won_at_from), 'yyyy-MM-dd') : ''

        axios
            .get('/winners/export', {params: _param, responseType: "blob"})
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'red_shirt_winners-' + format(new Date(), 'yyyy-MM-dd-HH-mm-ss') + '.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                response_error(error);
            });
    }

    useEffect(() => {
        getWinners(1)
    }, [])

    const handleDelete = (id) => {
        axios.delete(`/winners/${id}`)
            .then(response => {
                toast.success(response.data.message)
                getWinners(page)
            })
            .catch(error => response_error(error))

    }

    const send_a_request = (id) => {
        axios.post(`/winners/${id}/request-address-confirmation`)
            .then(response => {
                toast.success(response.data.message)
                getWinners(page)
            })
            .catch(error => response_error(error))
    }

    const handlePageClick = (selected) => {
        getWinners(selected.selected + 1)
    }

    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    <h5 className="fs-0 mb-3 text-nowrap py-2 py-xl-0">Winners</h5>
                    <WinnerFilter
                        param={param}
                        setParam={setParam}
                        getWinners={(getWinners)}
                        downloadWinners={downloadWinners}
                    />
                    {/*<GroupFilterForm*/}
                    {/*    setParam={setParam}*/}
                    {/*    param={param}*/}
                    {/*    getGroups={getGroups}*/}
                    {/*    setModalShow={setModalShow}*/}
                    {/*/>*/}
                </Card.Header>
                <Card.Body className="pt-0">
                    <div className="table-responsive">
                        <table className="fs--1 mb-0 table table-sm table-striped overflow-hidden" role="table">
                            <thead className="bg-200 text-900 text-nowrap align-middle">
                            <tr>
                                <th>Name</th>
                                <th>Group</th>
                                <th>Email</th>
                                <th>Won Date</th>
                                <th>Confirmation Date</th>
                                <th>Reminders sent</th>
                                <th>Shirt Size</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>

                            {
                                winners.length > 0 && winners.map(winner => (
                                    <tr className="align-middle white-space-nowrap" role="row" key={winner.id}>
                                        <td role="cell"
                                            className="py-2"><a href={`/members/edit/${winner.member_id}`}
                                                                target="_blank">{winner.member?.user.first_name} {winner.member?.user.last_name}</a>
                                        </td>
                                        <td role="cell">{winner?.event?.group.name}</td>
                                        <td role="cell">{winner.member?.user.email}</td>
                                        <td role="cell">{winner?.won_at}</td>
                                        <td role="cell">{winner.confirmed_at}</td>
                                        <td role="cell">{winner.attempt > 0 ? 'Yes' : ''}</td>
                                        <td role="cell">{winner?.red_shirt_size}</td>
                                        <td role="cell" className="text-end">
                                            <CardDropdown iconClassName="fs--1" drop="start">
                                                <div className="py-2">
                                                    {/*<Dropdown.Item>Edit</Dropdown.Item>*/}
                                                    <Dropdown.Item onClick={() => send_a_request(winner.id)}>Send a
                                                        request</Dropdown.Item>
                                                    <Dropdown.Divider as="div"/>
                                                    <Dropdown.Item className="text-danger"
                                                                   onClick={() => handleDelete(winner.id)}>
                                                        Delete
                                                    </Dropdown.Item>
                                                </div>
                                            </CardDropdown>
                                        </td>
                                    </tr>
                                ))
                            }

                            </tbody>
                        </table>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <ReactPaginate
                        previousLabel={previous_icon()}
                        nextLabel={next_icon()}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination mb-0 mx-1'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </Card.Footer>
            </Card>
        </>
    );
};

export default Winner;

