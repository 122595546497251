import React, {useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import FalconDropzone from 'components/common/FalconDropzone';
import avatarImg from 'assets/img/team/avatar.png';
import { isIterableArray } from 'helpers/utils';
import Avatar from 'components/common/Avatar';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { AuthWizardContext } from 'context/Context';
import Flex from 'components/common/Flex';
import {Card, Col, Row} from 'react-bootstrap';
import axios from "axios";
import {response_error} from "../../../helpers/functions";

const MemberProfileImageView = () => {
  const { user } = useContext(AuthWizardContext);
  const [avatar, setAvatar] = useState([]);

  useEffect(() => {
    setAvatar([user.avatar ? user.avatar :avatarImg]);
  }, [user.avatar]);

  return (
    <>
        <Card className="p-3">
            <Row className="mb-3">
                <Col md="auto">
                    <Avatar
                        size="4xl"
                        src={
                            isIterableArray(avatar) ? avatar[0]?.base64 || avatar[0]?.src || avatar[0] : ''
                        }
                    />
                </Col>
                <Col md>
                    <FalconDropzone
                        files={avatar}
                        onChange={files => {
                            setAvatar(files);
                            // Ensure setValue is a function before calling
                            // if (typeof setValue === 'function') {
                            //     setValue('avatar', files);
                            // } else {
                            //     console.error('setValue is not a function');
                            // }

                            fetch(files[0].base64)
                            .then(res => res.blob())
                            .then(blob => {
                                const file = new File([blob], "image.jpg", {type: "image/jpeg"});
                                const formData = new FormData();
                                formData.append("image", file);

                                axios.post('/member/image', formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }).then((response) => {

                                }).catch((error) => {
                                    response_error(error);
                                })
                            })
                        }}
                        multiple={false}
                        accept="image/*"
                        placeholder={
                            <>
                                <Flex justifyContent="center">
                                    <img src={cloudUpload} alt="" width={25} className="me-2"/>
                                    <p className="fs-0 mb-0 text-700">
                                        Upload your profile picture
                                    </p>
                                </Flex>
                                {/*<p className="mb-0 w-75 mx-auto text-400">*/}
                                {/*    Upload a 300x300 jpg image with a maximum size of 400KB*/}
                                {/*</p>*/}
                            </>
                        }
                    />
                </Col>
            </Row>
        </Card>
    </>
  );
};

MemberProfileImageView.propTypes = {
  setValue: PropTypes.func.isRequired
};

export default MemberProfileImageView;
