import React, {useEffect, useState} from 'react';
import {Card, Dropdown, Button, Col, Form, Row, Modal} from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import axios from "axios";
import {next_icon, previous_icon, response_error} from "../../../helpers/functions";
import ReactPaginate from "react-paginate";
import GroupFilterForm from "./GroupTable/GroupFilterForm";


const Groups = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [group, setGroup] = useState(null);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const [param, setParam] = useState({
        groups: [],
        regions: [],
        include_inactive: 0
    });

    const handlePageClick = page => {
        setPage(page.selected + 1);
    }

    const [groups, setGroups] = useState([]);
    const changeHandle = (e) => {
        setGroup(prevState => ({...prevState, [e.target.name]: e.target.value}));
    }
    const createGroup = () => {
        axios.post('/groups/create', group)
            .then(response => {
                console.log(response);
                window.location.href = `/groups/edit/${response.data.group.id}`;
            })
            .catch(error => {
                response_error(error);
            });
    }

    const getGroups = () => {
        const _param = {...param};
        _param.page = page;
        _param.groups = _param.groups.map(item => item.value);
        _param.regions = _param.regions.map(item => item.value);

        axios.get('/groups', {params: _param})
            .then(response => {
                setGroups(response.data.groups.data)
                let total_page = parseInt(response.data.groups.total) / parseInt(response.data.groups.per_page);
                total_page = total_page > 0 ? Math.ceil(total_page) : 0;
                setPageCount(total_page);
            })
            .catch(error => {
                response_error(error);
            })
    }


    useEffect(() => {
        getGroups();
    }, [page]);
    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    <h5 className="fs-0 mb-3 text-nowrap py-2 py-xl-0">Groups</h5>
                    <GroupFilterForm
                        setParam={setParam}
                        param={param}
                        getGroups={getGroups}
                        setModalShow={setModalShow}
                    />
                </Card.Header>
                <Card.Body className="pt-0">
                    <div className="table-responsive">
                        <table className="fs--1 mb-0 table table-sm table-striped overflow-hidden" role="table">
                            <thead className="bg-200 text-900 text-nowrap align-middle">
                            <tr>
                                <th>Group Name</th>
                                <th>Golf Club Name</th>
                                <th>Members</th>
                                <th>Hard Cap</th>
                                <th>Region</th>
                                <th className="text-center">Status</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                groups.map(group => {
                                    let link = `/groups/edit/${group.id}`;
                                    return (
                                        <tr className="align-middle white-space-nowrap" role="row" key={group.id}>
                                            <td role="cell" className="py-2"><a href={link}>{group.name}</a></td>
                                            <td role="cell">{group.golf_club_name}</td>
                                            <td role="cell">{group.active_members_count}</td>
                                            <td role="cell">{group.hard_cap}</td>
                                            <td role="cell">{group.region && group.region.name}</td>
                                            <td role="cell" className="fs-0">
                                                <SoftBadge pill bg={classNames({
                                                    success: group.status === 'active',
                                                    danger: group.status === 'inactive',
                                                    warning: group.status === 'test',
                                                })} className='d-block'>{group.status}</SoftBadge>
                                            </td>
                                            <td role="cell" className="text-end">
                                                <CardDropdown iconClassName="fs--1" drop="start">
                                                    <div className="py-2">
                                                        <Dropdown.Item>Edit</Dropdown.Item>
                                                        <Dropdown.Divider as="div"/>
                                                        <Dropdown.Item className="text-danger">
                                                            Delete
                                                        </Dropdown.Item>
                                                    </div>
                                                </CardDropdown>
                                            </td>
                                        </tr>
                                    )
                                })
                            }


                            </tbody>
                        </table>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <ReactPaginate
                        previousLabel={previous_icon()}
                        nextLabel={next_icon()}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination mb-0 mx-1'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </Card.Footer>
            </Card>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Add New Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    placeholder="Group Name"
                                    name="name"
                                    type="text"
                                    onChange={changeHandle}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={12} className="text-end">
                            <Button variant='falcon-default' className='me-2 mb-1' onClick={createGroup}
                                    size="sm">Add</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Groups;
