import React from 'react';
import Flex from '../../../../components/common/Flex';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import Calendar from '../../../../components/common/Calendar';


const EventList = ({details, isLast, deleteEvent, edit}) => {
    const Actions = (prop) => (
        <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
            {/*<Button variant="light" size="sm" className="border-300 text-600" onClick={edit}>*/}
            {/*    <FontAwesomeIcon icon="edit"/>*/}
            {/*</Button>*/}
            <Button variant="light" size="sm" className="border-300 text-600" onClick={() => deleteEvent(prop.attendee_id)}>
                <FontAwesomeIcon icon="trash-alt"/>
            </Button>
        </div>
    );

    const {
        calendar,
        group_name,
        event_date,
        badge = {},
        time,
        attending_as,
        attending,
        id,
        substitute,
    } = details;
    return (
        <Flex className="hover-actions-trigger">
            <Calendar {...calendar} />
            <div className="flex-1 position-relative ps-3">
                <h6 className="fs-0 mb-0">
                    <Link to="/events/event-detail">
                        <span className="me-1">{group_name}</span>
                        {badge.title && (
                            <span className={`badge badge-${badge.type} rounded-pill `}>
                {badge.title}
              </span>
                        )}
                    </Link>
                </h6>
                <p className="mb-1">Attending as: {attending_as}</p>
                <p className="mb-1">Attending: {attending}</p>
                <p className="text-1000 mb-0">Time: {time}</p>
                {
                    substitute && (
                        <p className="mb-1">Substitute: {substitute.name}</p>
                    )
                }

                {!isLast && <div className="border-dashed-bottom my-3"></div>}
            </div>
            <Actions attendee_id={id}/>
        </Flex>
    );
};

export default EventList;
