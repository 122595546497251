import React, {useContext, useEffect, useState} from 'react';
import {Form, Card, Col, Row, Button} from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from "moment";
import {MemberContext} from "../../../../context/Context";
import axios from "axios";
import toast from "react-hot-toast";
import {response_error} from "../../../../helpers/functions";
import {contactTypeOptions} from "../../../../data/options";

const Membership = () => {
    const {member, setMember, selectChangeHandler, memberHandleChange, memberId} = useContext(MemberContext);
    const [memberShipTypes, setMemberShipTypes] = useState([]);
    const memberShipStatuses = [
        {value: 'pending', label: 'Pending'},
        {value: 'active', label: 'Active'},
        {value: 'suspended', label: 'Suspended'},
        {value: 'lapsed', label: 'Lapsed'}
    ];

    const checkboxChangeHandler = (e) => {
        setMember(prevState => ({
            ...prevState,
            [e.target.name]: e.target.checked ? 1 : 0
        }))
    }

    useEffect(() => {
        axios.get('/membership')
            .then(response => {
                let membershipOptions = response.data.map(membership => {
                    return {value: membership.id, label: membership.name}
                });

                setMemberShipTypes(membershipOptions);

            })
    }, [])

    const HandleSendAppCode = () => {
        axios.post('/send-app-code', {
            type: 'app-code-email',
            member_id: memberId
        })
            .then(response => {
                toast.success(response.data.message);
            })
            .catch(error => {
                response_error(error);
            })
    }

    return (
        <Row className="g-0 mb-3">
            <Col lg={12}>
                <Card className={member.status !== 'active' ? 'h-100 red' : 'h-100'}>
                    <Row>
                        <Col lg={6} md={7} sm={7}>
                            <Card.Header as="h5">Membership</Card.Header>
                        </Col>
                        <Col lg={6} md={5} sm={5} className="text-end">
                            <Card.Header as="h5">
                                <Button size="sm" variant="falcon-default" className="me-2"
                                        onClick={HandleSendAppCode}> Send App Code</Button>
                            </Card.Header>
                        </Col>
                    </Row>
                    <div className="border-dashed-bottom"></div>
                    <Card.Body className="bg-light">
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Person Type</Form.Label>
                                    <Select options={contactTypeOptions}
                                            value={contactTypeOptions.filter(option => option.value === member.contact_type)}
                                            name={'contact_type'}
                                            onChange={selectChangeHandler}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Membership </Form.Label>
                                    <Select options={memberShipTypes}
                                            value={memberShipTypes.filter(memberShipType => memberShipType.value === member.membership_id)}
                                            name={'membership_id'}
                                            onChange={selectChangeHandler}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Membership Status</Form.Label>
                                    <Select
                                        options={memberShipStatuses}
                                        value={memberShipStatuses.filter(memberShipStatus => memberShipStatus.value === member.status)}
                                        name={'status'}
                                        onChange={selectChangeHandler}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Membership Number</Form.Label>
                                    <Form.Control
                                        placeholder="Membership Number"
                                        name="membership_number"
                                        type="text"
                                        value={member.membership_number}
                                        onChange={memberHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>App Code</Form.Label>
                                    <Form.Control
                                        placeholder="App Code"
                                        name="app_code"
                                        type="text"
                                        value={member.app_code}
                                        onChange={memberHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Ambassador Password</Form.Label>
                                    <Form.Control
                                        placeholder="Ambassador Password"
                                        name="password"
                                        type="text"
                                        value={member.password}
                                        onChange={memberHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Joined Date</Form.Label>
                                    <DatePicker
                                        selected={Date.parse(member.joined_date)}
                                        onChange={(eventTime) => setMember(prevState => ({
                                            ...prevState,
                                            joined_date: eventTime ? moment(eventTime).format('YYYY-MM-DD') : ''
                                        }))}
                                        // formatWeekDay={day => day.slice(0, 3)}
                                        className='form-control'
                                        placeholderText="yyyy-MM-dd"
                                        dateFormat="yyyy-MM-dd"
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Leaving Date</Form.Label>
                                    <DatePicker
                                        selected={Date.parse(member.leaving_date)}
                                        onChange={(eventTime) => setMember(prevState => ({
                                            ...prevState,
                                            leaving_date: eventTime ? moment(eventTime).format('YYYY-MM-DD') : ''
                                        }))}
                                        // formatWeekDay={day => day.slice(0, 3)}
                                        className='form-control'
                                        placeholderText="yyyy-MM-dd"
                                        dateFormat="yyyy-MM-dd"
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Check inline type='checkbox' id='rejoiner' label='Rejoiner' checked={member.rejoined}
                                                name='rejoined'
                                                onChange={checkboxChangeHandler}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Membership Start Month</Form.Label>
                                    <Form.Control placeholder="" type="text" disabled={true}
                                                  value={member.joined_date ? moment(member.joined_date).format('MMMM') : ''}/>
                                </Form.Group>
                            </Col>


                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Attended and Ask Hughsie meeting </Form.Label>
                                    <div className="d-block">
                                        <Form.Check inline type='radio' id='hughsiem_meeting_yes' label='Yes'
                                                    name='hughsiem_meeting'
                                                    value='1'
                                                    checked={parseInt(member.hughsiem_meeting) === 1}
                                                    onChange={memberHandleChange}
                                        />
                                        <Form.Check inline type='radio' id='hughsiem_meeting_no' label='No'
                                                    name='hughsiem_meeting'
                                                    value='0'
                                                    checked={parseInt(member.hughsiem_meeting) === 0}
                                                    onChange={memberHandleChange}

                                        />
                                    </div>
                                </Form.Group>
                            </Col>

                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default Membership;