import React, {useContext, useEffect} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';

import ErrorLayout from './ErrorLayout';
import WizardAuth from 'components/authentication/wizard/WizardAuth';
import Landing from 'components/pages/landing/Landing';
import {toast, ToastContainer} from 'react-toastify';
import {CloseButton} from 'components/common/Toast';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';


import CardLogin from 'components/authentication/card/Login';
import Logout from 'components/authentication/card/Logout';
import CardRegistration from 'components/authentication/card/Registration';
import CardForgetPassword from 'components/authentication/card/ForgetPassword';
import CardConfirmMail from 'components/authentication/card/ConfirmMail';
import CardPasswordReset from 'components/authentication/card/PasswordReset';
import CardLockScreen from 'components/authentication/card/LockScreen';

import SplitLogin from 'components/authentication/split/Login';
import SplitLogout from 'components/authentication/split/Logout';
import SplitRegistration from 'components/authentication/split/Registration';
import SplitForgetPassword from 'components/authentication/split/ForgetPassword';
import SplitPasswordReset from 'components/authentication/split/PasswordReset';
import SplitConfirmMail from 'components/authentication/split/ConfirmMail';
import SplitLockScreen from 'components/authentication/split/LockScreen';

import Wizard from 'components/wizard/Wizard';
import AppContext, {AuthWizardContext} from 'context/Context';

import Regions from 'Containers/Admin/Regions/Regions';
import GroupForm from 'Containers/Admin/Groups/GroupForm';
import Groups from 'Containers/Admin/Groups';
import Events from "../Containers/Admin/Events";
import EventForm from "../Containers/Admin/Events/EventForm";
import MembershipType from "../Containers/Admin/Membership";
import MembershipTypeForm from "../Containers/Admin/Membership/MembershipForm";
import MemberForm from "../Containers/Admin/Members/MemberForm";
import Members from "../Containers/Admin/Members";
import Email from "../Containers/Admin/Email/email";
import Team from "../Containers/Admin/Team/team";
import FutureEvents from "../Containers/Admin/Events/FutureEvents";
import ShippingAddressEntry from "../components/authentication/card/ShippingAddressEntry";
import Sites from "../Containers/Admin/Sites";
import SiteDetails from "../Containers/Admin/Sites/SiteDetails";
import Winner from "../Containers/Admin/Winner/Winner";
import NewJoiners from "../Containers/Admin/NewJoiners/NewJoiners";
import Activities from "../Containers/Admin/Activities/activities";
import GoldenTickets from "../Containers/Admin/GoldenTickets/goldenTickets";
import BookingForm from "../components/pages/bookingForm/BookingForm";
import NationalEvents from "../Containers/Admin/NationalEvents/Events/NationalEvents";
import Bookings from "../Containers/Admin/NationalEvents/Bookings/Bookings";
import Coupons from "../Containers/Admin/NationalEvents/Coupons/Coupons";
import Payment from "../Containers/Public/Payment";
import PaymentSuccess from "../Containers/Public/PaymentSuccess";

import CreateProduct from "../Containers/Admin/Products/CreateProduct";
import Orders from "../Containers/Admin/Products/Orders";
import ProductAllTable from "../Containers/Admin/Products/ProductAllTable";
import RedeemPoints from "../Containers/Member/Products/RedeemPoints";
import OrderHistory from "../Containers/Member/Products/OrderHistory";
import MemberProfileView from "../Containers/Member/Profile/MemberProfileView";
import MemberDashboard from "../Containers/Member/Dashboard/MemberDashboard";
import MemberMeeting from "../Containers/Member/Profile/MemberMeeting";
import GoldenTicketShared from "../Containers/Member/GoldenTicketShared/GoldenTicketShared";
import NationalEventBookings from "../Containers/Member/Booking/NationalEventBookings";
import ForeBallBookings from "../Containers/Member/Booking/ForeBallBookings";
import useScript from "../hooks/useScript";
import MyPoints from "../Containers/Member/MyPoints/MyPoints";
import {Members as LimitedMembers} from "../Containers/Member/Members/Members";

const Layout = () => {
    const HTMLClassList = document.getElementsByTagName('html')[0].classList;
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const {config: {role}} = useContext(AppContext);
    const token = localStorage.getItem('token');

    const {user, isLoading} = useContext(AuthWizardContext);

    useEffect(() => {
        if (is.windows()) {
            HTMLClassList.add('windows');
        }
        if (is.chrome()) {
            HTMLClassList.add('chrome');
        }
        if (is.firefox()) {
            HTMLClassList.add('firefox');
        }
    }, [HTMLClassList]);

    useEffect(() => {
        if (!role && !token && pathname === 'forgot-password') {
            return navigate('/login')
        }
    }, [pathname]);

    const isMember = () => {
        if(!isLoading){
            return user?.roles.map(role => role.name).includes('member');
        }
    };

    useScript('https://desk.zoho.eu/portal/api/web/inapp/41726000038004909?orgId=20069004211')


    return (
        <>
            <Routes>
                <Route path="bookings/national-event/:eventId" element={<BookingForm/>}/>
                <Route path="/payment" element={<Payment/>}/>
                <Route path="/payment-success" element={<PaymentSuccess/>}/>
                <Route path="landing" element={<Landing/>}/>
                <Route element={<ErrorLayout/>}>
                    <Route path="errors/404" element={<Error404/>}/>
                    <Route path="errors/500" element={<Error500/>}/>
                </Route>
                {/*- ------------- Authentication ---------------------------  */}


                {/*- ------------- Card ---------------------------  */}
                <Route path="/login" element={<CardLogin/>}/>
                <Route path="/shipping-info" element={<ShippingAddressEntry/>}/>

                <Route
                    path="/register"
                    element={<CardRegistration/>}
                />
                <Route path="/logout" element={<Logout/>}/>
                <Route
                    path="/forgot-password"
                    element={<CardForgetPassword/>}
                />
                <Route
                    path="/reset-password"
                    element={<CardPasswordReset/>}
                />
                <Route
                    path="/confirm-mail"
                    element={<CardConfirmMail/>}
                />
                <Route
                    path="/lock-screen"
                    element={<CardLockScreen/>}
                />

                {/*- ------------- Split ---------------------------  */}

                <Route path="authentication/split/login" element={<SplitLogin/>}/>
                <Route path="authentication/split/logout" element={<SplitLogout/>}/>
                <Route
                    path="authentication/split/register"
                    element={<SplitRegistration/>}
                />
                <Route
                    path="authentication/split/forgot-password"
                    element={<SplitForgetPassword/>}
                />
                <Route
                    path="authentication/split/reset-password"
                    element={<SplitPasswordReset/>}
                />
                <Route
                    path="authentication/split/confirm-mail"
                    element={<SplitConfirmMail/>}
                />
                <Route
                    path="authentication/split/lock-screen"
                    element={<SplitLockScreen/>}
                />

                <Route element={<WizardAuth/>}>
                    <Route
                        path="authentication/wizard"
                        element={<Wizard validation={true}/>}
                    />
                </Route>

                {/* //--- MainLayout Starts  */}

                <Route element={<MainLayout/>}>
                    {/* Custom Route */}
                    {
                        role === 'member' && (
                            <>
                                {/* Member Routes */}
                                <Route path="/" element={<MemberDashboard/>}/>
                                <Route path="/profile" element={<MemberProfileView/>}/>
                                <Route path="/redeem-points" element={<RedeemPoints/>}/>
                                <Route path="/order-history" element={<OrderHistory/>}/>
                                <Route path="/meetings" element={<MemberMeeting/>}/>
                                <Route path="/golden-ticket" element={<GoldenTicketShared/>}/>
                                <Route path="/my-points" element={<MyPoints/>}/>
                                <Route path="/4ball-bookings" element={<ForeBallBookings/>}/>
                                <Route path="/national-event-bookings" element={<NationalEventBookings/>}/>
                                <Route path="/members" element={<LimitedMembers />}/>
                            </>
                        )
                    }

                    {
                        role !== 'member' && (
                            <>
                                <Route path="/" element={<Groups/>}/>
                                <Route path="/groups" element={<Groups/>}/>
                                <Route path="/groups/edit/:groupId" element={<GroupForm/>}/>
                                <Route path="/members" element={<Members/>}/>
                                <Route path="/members/edit/:memberId" element={<MemberForm/>}/>
                                <Route path="/countries" element={<Regions/>}/>
                                <Route path="/regions" element={<Sites/>}/>

                                <Route path="/winner" element={<Winner/>}/>
                                <Route path="/new-joiners" element={<NewJoiners/>}/>

                                <Route path="/activities" element={<Activities/>}/>
                                <Route path="/golden-tickets" element={<GoldenTickets/>}/>

                                <Route path="/regions/edit/:regionId" element={<SiteDetails/>}/>

                                <Route path="/membership-types" element={<MembershipType/>}/>
                                <Route path="/membership-types/create" element={<MembershipTypeForm/>}/>
                                <Route path="/membership-types/edit/:membershipTypeId" element={<MembershipTypeForm/>}/>
                                <Route path="/products/create" element={<CreateProduct/>}/>
                                <Route path="/products/:productId/edit" element={<CreateProduct/>}/>
                                <Route path="/orders" element={<Orders/>}/>
                                <Route path="/products" element={<ProductAllTable/>}/>

                                <Route path="/events/edit/:eventId" element={<EventForm/>}/>
                                <Route path="/events/create" element={<EventForm/>}/>
                                <Route path="/email" element={<Email/>}/>
                                <Route path="/team" element={<Team/>}/>
                                <Route exact path="/events" element={<Events/>}/>
                                <Route exact path="/events/table-view" element={<FutureEvents/>}/>
                                <Route exact path="/national-events" element={<NationalEvents/>}/>
                                <Route exact path="/bookings" element={<Bookings/>}/>
                                <Route exact path="/coupons" element={<Coupons/>}/>
                            </>
                        )
                    }

                </Route>

                {/* //--- MainLayout end  */}

                {/* <Navigate to="/errors/404" /> */}
                <Route path="*" element={<Navigate to="/errors/404" replace/>}/>
            </Routes>
            <ToastContainer
                closeButton={CloseButton}
                icon={false}
                position={toast.POSITION.BOTTOM_LEFT}
            />
        </>
    );
};

export default Layout;
