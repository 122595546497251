import React, { useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ClubInfo = ({group, groupHandleChange }) => {
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    regDate: null,
    startTime: null,
    endTime: null
  });

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <Card className="mb-3">
      <Card.Header as="h5">Club Info</Card.Header>
      <div className="border-dashed-bottom"></div>
      <Card.Body className="bg-light">
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group controlId="contact_name">
              <Form.Label>Primary Golf Club Contact Name</Form.Label>
              <Form.Control
                type="text"
                name='golf_club_contact_name'
                value={group.golf_club_contact_name}
                onChange={groupHandleChange}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="club_email">
              <Form.Label>Primary Golf Club Contact Email</Form.Label>
              <Form.Control
                type="text"
                name='golf_club_contact_email'
                value={group.golf_club_contact_email}
                onChange={groupHandleChange}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="club_phone">
              <Form.Label>Primary Golf Club Contact Phone</Form.Label>
              <Form.Control
                type="text"
                name='golf_club_contact_phone'
                value={group.golf_club_contact_phone}
                onChange={groupHandleChange}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="contact_name_2">
              <Form.Label>Secondary Golf Club Contact Name</Form.Label>
              <Form.Control
                type="text"
                name='golf_club_contact_name2'
                value={group.golf_club_contact_name2}
                onChange={groupHandleChange}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="club_email_2">
              <Form.Label>Secondary Golf Club Contact Email</Form.Label>
              <Form.Control
                type="text"
                name='golf_club_contact_email2'
                value={group.golf_club_contact_email2}
                onChange={groupHandleChange}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="club_phone_2">
              <Form.Label>Secondary Golf Club Contact Phone</Form.Label>
              <Form.Control
                type="text"
                name='golf_club_contact_phone2'
                value={group.golf_club_contact_phone2}
                onChange={groupHandleChange}
              />
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group controlId="club_name">
              <Form.Label>Golf Club Name</Form.Label>
              <Form.Control
                type="text"
                name='golf_club_name'
                value={group.golf_club_name}
                onChange={groupHandleChange}
              />
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group controlId="club_address">
              <Form.Label>Golf Club Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                name='golf_club_address'
                value={group.golf_club_address}
                onChange={groupHandleChange}
              />
            </Form.Group>
          </Col>

        </Row>
      </Card.Body>
    </Card>

  );
};

export default ClubInfo;
