import React, {useEffect, useState} from 'react';
import {Card, Dropdown, Button, Col, Form, Row, Modal} from 'react-bootstrap';
import SitesTableHeader from "./SitesTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import axios from "axios";

const SitesTable = () => {

    const _sites = [
        {
            id: 1,
            name: "",
            slug: ""
        }
    ]

    const [sites, setSites] = useState(_sites)

    useEffect(() => {
        axios.get('/sites')
            .then(response => {
                setSites(response.data)
            })

    }, [])

    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    <SitesTableHeader table  sites={sites} setSites={setSites}/>
                </Card.Header>
                <Card.Body className="pt-0">
                    <div className="table-responsive">
                    <table className="fs--1 mb-0 table table-sm table-striped overflow-hidden" role="table">
                        <thead className="bg-200 text-900 text-nowrap align-middle">
                        <tr>
                            <th>Region</th>
                            <th>Slug</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            sites.map(site => (
                                <tr key={site.id}>
                                    <td><a href={`/regions/edit/${site.id}`}>{site.name}</a></td>
                                    <td>{site.slug}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                        </div>
                </Card.Body>
                <Card.Footer>
                    {/*<AdvanceTablePagination table/>*/}
                </Card.Footer>
            </Card>


        </>
    );
};

export default SitesTable;
