import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './assets/custom-style.css';
import Layout from './layouts/Layout';
import AuthWizardProvider from "./components/wizard/AuthWizardProvider";

const App = () => {
    return (
        <Router basename={'/'}>
            <AuthWizardProvider>
                <Layout/>
            </AuthWizardProvider>
        </Router>
    );
};

export default App;