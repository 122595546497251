import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Row} from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
import {greetingItems} from 'data/dashboard/projectManagement';
import {response_error} from "../../../helpers/functions";
import AppContext, {AuthWizardContext} from "../../../context/Context";
import axios from "axios";
import DashboardWelcome from "./DashboardWelcome";
import Flex from "../../../components/common/Flex";
import Calendar from "../../../components/common/Calendar";
import {Link} from "react-router-dom";
import HomeGroupCard from "./HomeGroupCard";
import SoftBadge from "../../../components/common/SoftBadge";
import Background from 'components/common/Background';
import bg3 from 'assets/img/icons/spot-illustrations/corner-2.png';
import PointsCard from "./PointsCard";
import moment from "moment";
import AuthWizardProvider from "../../../components/wizard/AuthWizardProvider";

const MemberDashboard = () => {

    const [member, setMember] = useState({});
    const [meetings, setMeetings] = useState([]);
    const {user, isLoading}  = useContext(AuthWizardContext);

    useEffect(() => {
        getMember();
        getMeeting();

    }, []);

    const getMember = () => {
        axios.get('/member')
            .then((res) => {
                setMember(res.data);
            })
            .catch((err) => {
                response_error(err);
            });
    };

    const getMeeting = () => {
        axios.get('/member/meetings')
            .then((res) => {
                setMeetings(res.data);
            })
            .catch((err) => {
                response_error(err);
            });
    };

    const MemberShipView = (
        <Row className="g-0 mb-3">
            <Col lg={12}>
                <Card className="overflow-hidden card mb-3" style={{minHeight: '200px'}}>
                    <Background image={bg3} className="bg-card"/>
                    <Card.Header className="z-index-1">
                        <h5 className="text-primary">Membership</h5>
                    </Card.Header>
                    <Card.Body className="z-index-1">
                        <Row className="g-2 h-100 align-items-end">
                            <Col sm={6} md={5}>
                                <Flex className="position-relative">
                                    <div className="flex-1">
                                        <h6 className="text-800 mb-0">{member?.membership?.name}</h6>
                                        <p className="mb-0 fs--2 text-500 ">Membership</p>
                                    </div>
                                </Flex>
                            </Col>
                            <Col sm={6} md={5}>
                                <Flex className="position-relative">
                                    <div className="flex-1">
                                        <h6 className="text-800 mb-0"><SoftBadge pill
                                                                                 bg='success'>{member.status ? member.status.toUpperCase() : ''}</SoftBadge>
                                        </h6>
                                        <p className="mb-0 fs--2 text-500 ">Membership Status</p>
                                    </div>
                                </Flex>
                            </Col>
                            <Col sm={6} md={5}>
                                <Flex className="position-relative">
                                    <div className="flex-1">
                                        <h6 className="text-800 mb-0">{member.membership_number}</h6>
                                        <p className="mb-0 fs--2 text-500 ">Membership Number</p>
                                    </div>
                                </Flex>
                            </Col>
                            <Col sm={6} md={5}>
                                <Flex className="position-relative">
                                    <div className="flex-1">
                                        <h6 className="text-800 mb-0">{member.app_code}</h6>
                                        <p className="mb-0 fs--2 text-500 ">App Code</p>
                                    </div>
                                </Flex>
                            </Col>
                            <Col sm={6} md={5}>
                                <Flex className="position-relative">
                                    <div className="flex-1">
                                        <h6 className="text-800 mb-0">{member.joined_date ? moment(member.joined_date).format('LL') : ''}</h6>
                                        <p className="mb-0 fs--2 text-500 ">Joined Date</p>
                                    </div>
                                </Flex>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

            </Col>
        </Row>
    );

    const MeetingViewCard = (
        <Row className="g-0 mb-3">
            <Col lg={12} >
                <Card className="h-lg-100">
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <Card.Header>
                                <Row>
                                    <Col xs={6}>
                                       <h5>Meetings</h5>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="text-end">
                                            <Link to="/meetings">
                                            <Button
                                                variant="falcon-default"
                                            >
                                                Edit Meetings
                                            </Button>
                                                </Link>
                                        </div>
                                    </Col>
                                    </Row>
                            </Card.Header>
                        </Col>
                    </Row>
                    <div className="border-dashed-bottom"></div>
                    <SimpleBarReact style={{maxHeight: '40rem'}}>
                        <Card.Body className="bg-light">
                            {
                                meetings.length > 0 && meetings.map((item, index) => (
                                    <Flex className="hover-actions-trigger">
                                        <Calendar month={moment(item.event.event_date).format('MMM')} day={moment(item.event.event_date).format('DD')}/>
                                        <div className="flex-1 position-relative ps-3">
                                            <h6 className="fs-0 mb-0">
                                                <Link to="#">
                                                    <span className="me-1">{item?.group?.name}</span>
                                                    <span className="badge rounded-pill"></span>
                                                </Link>
                                            </h6>
                                            <h6 className="text-800 mb-1">Attending as: {item?.attending_as}</h6>
                                            <h6 className="text-800 mb-1">Attending: {item?.attending}</h6>
                                            <h6 className="text-800 mb-1">Time: {item?.event?.event_time}</h6>
                                            {/*<div className="border-dashed-bottom my-3"></div>*/}
                                            {index === meetings.length - 1 ? null : <div className="border-dashed-bottom my-3"></div>}
                                        </div>
                                    </Flex>
                                ))
                            }
                        </Card.Body>
                    </SimpleBarReact>
                </Card>
            </Col>
        </Row>
    );

    return (
        <>
            {

                member && (
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={4}>
                            <DashboardWelcome data={greetingItems}/>
                            <HomeGroupCard groups={member.groups && member.groups.map(group => group.name).join(', ')}/>
                            <PointsCard credit={member.point}/>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={4}>
                            {MemberShipView}
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={4}>
                            {MeetingViewCard}
                        </Col>
                    </Row>
                )
            }
        </>
    );
};

export default MemberDashboard;