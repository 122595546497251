import React, {useEffect, useState} from 'react';
import {Card, Dropdown, Button, Col, Form, Row, Modal} from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import CardDropdown from "../../../../components/common/CardDropdown";
import {next_icon, previous_icon, response_error} from "../../../../helpers/functions";
import Select from "react-select";
import IconButton from "../../../../components/common/IconButton";
import DatePicker from "react-datepicker";
import axios from 'axios';
import {eventBanner} from 'assets/img/generic/13.jpg';

const NationalEvents = () => {
    const [modalShow, setModalShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [year, setYear] = useState(null);
    const [type, setType] = useState({value: "", label: "Type"});
    const [showDescription, setShowDescription] = useState(false);
    const [showDatePlayerQuantity, setShowDatePlayerQuantity] = useState(false);
    const [date, setDate] = useState(null);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [groupEvent, setGroupEvent] = useState(null);
    const [addOn, setAddOn] = useState(null);
    const [status, setStatus] = useState(null);
    const [nationalEvents, setNationalEvents] = useState([]);
    const [editingEventId, setEditingEventId] = useState(null);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [deletingEventId, setDeletingEventId] = useState(null);
    const [formData, setFormData] = useState({
        type: type.value,
        name: '',
        year: '',
        status: status,
        add_ons: addOn,
        player: null,
        date: date,
        price: '',
        quantity: null,
        group_events: groupEvent,
        club_name: '',
        invitation: false,
        address: '',
        description: '',
        sold: 0
    });

    const getNationalEvents = (page, reset) => {
        let url = `/national-events?page=${page}`
        if (reset) {
            url = `/national-events?page=${page}`
        } else {
            year && year.length && (url += `&year=[${year.map(item => item.value)}]`)
        }
        axios.get(url)
            .then(response => {
                setNationalEvents(response.data.nationalEvents.data)
                let total_page = parseInt(response.data.nationalEvents.total) / parseInt(response.data.nationalEvents.per_page);
                total_page = total_page > 0 ? Math.ceil(total_page) : 0;
                setPageCount(total_page);
            })
            .catch(error => {
                response_error(error);
            })
    }

    useEffect(() => {
        getNationalEvents(page)
    }, [page])

    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            type: type.value,
            status: status && status.value,
            add_ons: addOn && addOn.map(item => item.value),
            group_events: groupEvent && groupEvent.map(item => item.value),
            date: date && convertDateFormat(date),
        }));
    }, [type, status, addOn, groupEvent, date]);

    const handleInputsChange = (event) => {
        const {id, value} = event.target;
        setFormData({...formData, [id]: value});
    };

    const handleRadioChange = (event) => {
        const {name, id} = event.target;
        setFormData({...formData, [name]: id === "yes" ? true : false});
    };

    const convertDateFormat = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const addOns = nationalEvents.filter((event) => event.type === "add-on").map((event) => {
        return {value: event.id, label: event.name}
    });

    const groupEvents = nationalEvents.filter((event) => event.type === "event").map((event) => {
        return {value: event.id, label: event.name}
    });

    const types = [
        {value: 'group', label: 'Group'},
        {value: 'add-on', label: 'Add On'},
        {value: 'event', label: 'Event'}
    ];

    const statuses = [
        {value: 'active', label: 'Active'},
        {value: 'disabled', label: 'Disabled'}
    ]

    const currentYear = new Date().getFullYear();
    const years = [
        {value: currentYear - 3, label: `${currentYear - 3}`},
        {value: currentYear - 2, label: `${currentYear - 2}`},
        {value: currentYear - 1, label: `${currentYear - 1}`},
        {value: currentYear, label: `${currentYear}`}
    ];

    const handleTypeChange = (selectedOption) => {
        const value = selectedOption.value;
        if (value === 'add-on') {
            setShowDescription(true);
            setShowDatePlayerQuantity(false);
        } else if (value === 'event') {
            setShowDescription(false);
            setShowDatePlayerQuantity(true);
        } else {
            // Handle other cases if necessary
            setShowDescription(false);
            setShowDatePlayerQuantity(false);
        }
    }

    const handlePageClick = page => {
        setPage(page.selected + 1);
    }

    const Filter = (
        <Row>
            <Col lg={12}>
                <Form.Group
                    as={Row}
                >
                    <Col lg="4">
                        <Select
                            placeholder='Select year'
                            classNamePrefix="react-select"
                            options={years}
                            isMulti
                            value={year}
                            onChange={value => {
                                setYear(value)
                                value.length === 0 && getNationalEvents(page, true);
                            }}
                        />
                    </Col>
                    <Col lg="4">
                        <Button
                            variant="falcon-default"
                            className="me-2"
                            type="button"
                            onClick={() => {
                                setPage(1)
                                getNationalEvents(page)
                            }}
                        >
                            Search
                        </Button>
                    </Col>

                    <Col lg="4" className="text-end">
                        <div id="orders-actions">
                            <IconButton
                                variant="falcon-default"
                                icon="plus"
                                transform="shrink-3"
                                onClick={() => setModalShow(true)}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Add Event</span>
                            </IconButton>
                        </div>
                    </Col>
                </Form.Group>
            </Col>
        </Row>
    );

    const handleSaveButtonClick = (data) => {
        axios.post("/national-events", data)
            .then(() => {
                getNationalEvents(page)
                resetForm()
                setModalShow(false)
            })
            .catch(error => {
                response_error(error);
            })
    }

    const getEditingEventValues = async (eventId) => {
        let editingEvent = null
        const response = await axios.get(`/national-events/${eventId}`)
        editingEvent = response.data.nationalEvent
        setEditingEventId(editingEvent.id)

        setType({
            value: editingEvent.type,
            label: editingEvent.type === "add-on" ? "Add On" : editingEvent.type === "group" ? "Group" : "Event"
        });
        setShowDescription(editingEvent.type === "add-on" ? true : false);
        setShowDatePlayerQuantity(editingEvent.type === "event" ? true : false);
        setDate(new Date(editingEvent.date));
        if (editingEvent.events) {
            setGroupEvent(editingEvent.events.map(event => {
                return {value: event.id, label: event.name}
            }))
        } else {
            setGroupEvent(null)
        }
        if (editingEvent.optional_items) {
            setAddOn(editingEvent.optional_items.map(event => {
                return {value: event.id, label: event.name}
            }))
        } else {
            setAddOn(null)
        }
        setStatus({value: editingEvent.status, label: editingEvent.status === "active" ? "Active" : "Disabled"});

        if (editingEvent.invitation) {
            const invitation = document.getElementById('yes')
            invitation.checked = true;
        } else {
            const invitation = document.getElementById('no')
            invitation.checked = true;
        }

        setFormData({
            ...formData,
            name: editingEvent.name,
            year: editingEvent.year,
            player: editingEvent.player,
            price: editingEvent.price,
            quantity: editingEvent.quantity,
            club_name: editingEvent.club_name,
            address: editingEvent.address,
            description: editingEvent.description,
            sold: 0
        })
    }

    const handleEditEvent = (formData) => {
        let data = {...formData, id: editingEventId}
        axios.put(`/national-events/${editingEventId}`, data)
            .then(() => {
                getNationalEvents(page)
                resetForm()
                setModalShow(false)
                setIsEdit(false)
            })
            .catch(error => {
                response_error(error);
            })
    }

    const handleDeleteEvent = (eventId) => {
        axios.delete(`/national-events/${eventId}`)
            .then(setDeleteModalShow(false))
            .then(getNationalEvents(page))
            .catch(error => {
                response_error(error);
            })
    }

    const resetForm = () => {
        setType({value: "", label: "Type"});
        setShowDescription(false);
        setShowDatePlayerQuantity(false);
        setDate(null);
        setGroupEvent(null);
        setAddOn(null);
        setStatus(null);

        setFormData({
            ...formData,
            name: '',
            year: '',
            player: null,
            price: '',
            quantity: null,
            club_name: '',
            invitation: false,
            address: '',
            description: '',
            sold: 0
        })
    };

    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    <h5 className="fs-0 mb-3 text-nowrap py-2 py-xl-0">Events</h5>
                    {Filter}
                </Card.Header>
                <Card.Body className="pt-0">
                    <div className="table-responsive">
                        <table className="fs--1 mb-0 table table-sm table-striped overflow-hidden" role="table">
                            <thead className="bg-200 text-900 text-nowrap align-middle">
                            <tr>
                                <th>Id</th>
                                <th>Event Name</th>
                                <th>Date</th>
                                <th>Price</th>
                                <th>Type</th>
                                <th>Limit</th>
                                <th>Players</th>
                                <th>Available</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                nationalEvents.map((event) => {
                                    return (
                                        <tr className="align-middle white-space-nowrap" role="row" key={event.id}>
                                            <td role="cell">{event.id}</td>
                                            <td role="cell">{event.name}</td>
                                            <td role="cell">{event.date ? event.date : "-"}</td>
                                            <td role="cell">{event.price ? event.price : "-"}</td>
                                            <td role="cell">{event.type}</td>
                                            <td role="cell">{event.quantity ? event.quantity : "-"}</td>
                                            <td role="cell">{event.player ? event.player : "-"}</td>
                                            <td role="cell">{event.status}</td>
                                            <td role="cell" className="text-end">
                                                <CardDropdown iconClassName="fs--1" drop="start">
                                                    <div className="py-2">
                                                        <Dropdown.Item onClick={() => {
                                                            getEditingEventValues(event.id)
                                                            setModalShow(true)
                                                            setIsEdit(true)
                                                        }}>Edit</Dropdown.Item>
                                                        <Dropdown.Divider as="div"/>
                                                        <Dropdown.Item className="text-danger" onClick={() => {
                                                            setDeletingEventId(event.id)
                                                            setDeleteModalShow(true)
                                                        }}>
                                                            Delete
                                                        </Dropdown.Item>
                                                    </div>
                                                </CardDropdown>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <ReactPaginate
                        previousLabel={previous_icon()}
                        nextLabel={next_icon()}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination mb-0 mx-1'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </Card.Footer>
            </Card>

            <Modal
                show={modalShow}
                onHide={() => {
                    isEdit && resetForm()
                    setModalShow(false)
                    setIsEdit(false)
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="contained-modal-title-vcenter">{isEdit ? "Edit Event" : "Add New Event"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="type">
                                <Form.Label>Type</Form.Label>
                                <Select
                                    placeholder='Type'
                                    options={types}
                                    value={type}
                                    onChange={value => {
                                        setType({...value});
                                        handleTypeChange(value)
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Event Name</Form.Label>
                                <Form.Control type="text" placeholder="Event Name" value={formData.name}
                                              onChange={handleInputsChange}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="year">
                                <Form.Label>Year</Form.Label>
                                <Form.Control type="text" placeholder="Year" value={formData.year}
                                              onChange={handleInputsChange}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="status">
                                <Form.Label>Status</Form.Label>
                                <Select
                                    placeholder='Status'
                                    classNamePrefix="react-select"
                                    options={statuses}
                                    value={status}
                                    onChange={value => setStatus(value)}
                                />
                            </Form.Group>
                        </Col>
                        {type.value !== "add-on" && <Col sm={6}>
                            <Form.Group className="mb-3" controlId="add_on">
                                <Form.Label>Add On</Form.Label>
                                <Select
                                    placeholder='Add On'
                                    classNamePrefix="react-select"
                                    isMulti
                                    options={addOns}
                                    value={addOn}
                                    onChange={value => setAddOn(value)}
                                />
                            </Form.Group>
                        </Col>}
                        {type.value === "group" && <Col sm={6}>
                            <Form.Group className="mb-3" controlId="date">
                                <Form.Label>Date</Form.Label>
                                <DatePicker
                                    selected={date}
                                    onChange={(date) => setDate(date)}
                                    className='form-control'
                                    locale="es"
                                    placeholderText="Select Date"
                                />
                            </Form.Group>
                        </Col>}
                        {type.value === "add-on" && <Col sm={6}>
                            <Form.Group className="mb-3" controlId="price">
                                <Form.Label>Price</Form.Label>
                                <Form.Control type="text" placeholder="Price" value={formData.price}
                                              onChange={handleInputsChange}/>
                            </Form.Group>
                        </Col>}
                        {showDatePlayerQuantity && (
                            <>
                                <Col sm={6}>
                                    <Form.Group className="mb-3" controlId="player">
                                        <Form.Label>Player</Form.Label>
                                        <Form.Control type="text" placeholder="Player"
                                                      value={formData.player ? formData.player : ""}
                                                      onChange={handleInputsChange}/>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group className="mb-3" controlId="date">
                                        <Form.Label>Date</Form.Label>
                                        <DatePicker
                                            selected={date}
                                            onChange={(date) => setDate(date)}
                                            className='form-control'
                                            locale="es"
                                            placeholderText="Select Date"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group className="mb-3" controlId="price">
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control type="text" placeholder="Price" value={formData.price}
                                                      onChange={handleInputsChange}/>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group className="mb-3" controlId="quantity">
                                        <Form.Label>Quantity</Form.Label>
                                        <Form.Control type="text" placeholder="Quantity"
                                                      value={formData.quantity ? formData.quantity : ""}
                                                      onChange={handleInputsChange}/>
                                    </Form.Group>
                                </Col>
                            </>
                        )}
                        {type.value !== "add-on" && type.value !== "event" && <Col sm={6}>
                            <Form.Group className="mb-3" controlId="groupEvent">
                                <Form.Label>Group Event</Form.Label>
                                <Select
                                    placeholder='Event'
                                    classNamePrefix="react-select"
                                    isMulti
                                    options={groupEvents}
                                    value={groupEvent}
                                    onChange={value => setGroupEvent(value)}
                                />
                            </Form.Group>
                        </Col>}
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="club_name">
                                <Form.Label>Club Name</Form.Label>
                                <Form.Control type="text" placeholder="Club Name" value={formData.club_name}
                                              onChange={handleInputsChange}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="invitation">
                                <Form.Label>Invitation</Form.Label> <br/>
                                <Form.Check
                                    inline
                                    type='radio'
                                    id='yes'
                                    label='Yes'
                                    name='invitation'
                                    onChange={handleRadioChange}
                                />
                                <Form.Check
                                    inline
                                    type='radio'
                                    id='no'
                                    label='No'
                                    defaultChecked
                                    name='invitation'
                                    onChange={handleRadioChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="mb-3" controlId="address">
                                <Form.Label>Address</Form.Label>
                                <Form.Control as="textarea" rows={3} value={formData.address}
                                              onChange={handleInputsChange}/>
                            </Form.Group>
                        </Col>
                        {showDescription && (
                            <Col sm={12}>
                                <Form.Group className="mb-3" controlId="description">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} value={formData.description}
                                                  onChange={handleInputsChange}/>
                                </Form.Group>
                            </Col>
                        )}
                        <Col sm={12} className="text-center">
                            <Button
                                size="sm"
                                variant="falcon-default"
                                className="me-2"
                                type="button"
                                onClick={() => isEdit ? handleEditEvent(formData) : handleSaveButtonClick(formData)}
                            >
                                Save
                            </Button>
                        </Col>

                    </Row>
                </Modal.Body>
            </Modal>
            <Modal
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                size="sm"
                aria-labelledby="contained-delete-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-delete-modal-title-vcenter">Delete Event?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='d-flex justify-content-center'>
                        <Col className='d-flex gap-4' style={{flex: '0'}}>
                            <Button variant="danger" onClick={() => handleDeleteEvent(deletingEventId)}>Yes</Button>
                            <Button variant="secondary" onClick={() => setDeleteModalShow(false)}>No</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default NationalEvents;