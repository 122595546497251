import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import events from './data/events';
import PersonalInfo from './sections/PersonalInfo';
import BillingAddress from './sections/BillingAddress';
import ShippingAddress from './sections/ShippingAddress';
import PersonNotes from './sections/PersonNotes';
import Other from './sections/Other';
import HomeGroup from './sections/HomeGroupMarkup';
import Membership from './sections/Membership';
import RightEvents from './sections/Events';
import DangerZone from './sections/DangerZone';
import MemberReports from './sections/MemberReports';
import { MemberContext } from 'context/Context';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import SaveBar from '../../../components/custom/save-bar';
import toast from 'react-hot-toast';
import { response_error } from '../../../helpers/functions';
import Confirm from "../../../components/custom/confirm";
import RedShirt from "./sections/RedShirt";
import ResetPassword from "./sections/ResetPassword";

const MemberForm = () => {
  let { memberId } = useParams();
  const [show, setShow] = useState(false);
  const [memberData, setMemberData] = useState();

  const [member, setMember] = useState({
    id: memberId,
    gender: '',
    first_name: '',
    last_name: '',
    company: '',
    email: '',
    alternative_email: '',
    phone1: '',
    phone2: '',
    profession: '',
    job_title: '',
    shirt_size: '',
    linkedin: '',
    facebook: '',
    lead_source: '',
    handicap: '',
    is_ambassador: '',
    joined_date: '',
    leaving_date: '',
    membership_number: '',
    app_code: '',
    password: '',
    status: 'active',
    contact_type: '',
    hughsiem_meeting: '',
    rejoined: false,
    referring_member: {
      first_name: '',
      last_name: '',
      email: ''
    },
    red_shirts: [
      {
        confirmed_at: '',
        next_attempt_at: '',
        attempt: '',
        type: '',
        id: ''

      }
    ]
  });

  const memberHandleChange = e =>
    setMember(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  const selectChangeHandler = (e, meta) => {
    setMember(prevState => ({ ...prevState, [meta.name]: e.value }));
  };

  const [billingAddress, setBillingAddress] = useState({
    id: 1,
    type: 'billing',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postal_code: '',
    country: ''
  });

  const [shippingAddress, setShippingAddress] = useState({
    id: 2,
    type: 'shipping',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postal_code: '',
    country: ''
  });

  const [notes, setNotes] = useState([]);
  const [ambassadorGroups, setAmbassadorGroups] = useState([
    {
      name: 'Bath',
      id: 1
    }
  ]);

  const [otherInfo, setOtherInfo] = useState({
    wonDate: '',
    hasWon: false
  });
  const otherInfoHandleChange = e =>
    setOtherInfo(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  const otherInfoSelectChangeHandler = (e, meta) => {
    setOtherInfo(prevState => ({ ...prevState, [meta.name]: e.value }));
  };


  const [homeGroups, setHomeGroups] = useState([]);

  const saveHandle = () => {
    axios
      .put(`/members/${memberId}`, {
        ...member,
        billing_address: billingAddress,
        shipping_address: shippingAddress,
        winner: otherInfo
      })
      .then(response => {
        toast.success(response.data.message);
      })
      .catch(error => {
        response_error(error);
      });
  };

  const handleConfirm = () => {
    axios.get(`/members/${memberId}/send-golden-ticket`)
        .then(response => toast.success(response.data.message))
        .catch(error => response_error(error));
    setShow(false);
  }
  const handleShow = () => setShow(true);

  useEffect(() => {
    // Member Details
    axios
      .get(`/members/${memberId}`)
      .then(response => {
        setMemberData(response.data);
        setMember(response.data.member);

        if (response.data.notes.length > 0) {
          setNotes(response.data.notes);
        }

        if (response.data.member.addresses.length > 0) {
          let billing = response.data.member.addresses.filter(
            address => address.type === 'billing'
          );
          let shipping = response.data.member.addresses.filter(
            address => address.type === 'shipping'
          );
          if (billing.length > 0) {
            setBillingAddress(...billing);
          }
          if (shipping.length > 0) {
            setShippingAddress(...shipping);
          }

          if(response.data.hasOwnProperty('winner')){
            setOtherInfo(response.data.winner);
          }

          if(response.data.hasOwnProperty('ambassador_groups')){
            setAmbassadorGroups(response.data.ambassador_groups);
          }

        }
      })
      .catch(error => {
        response_error(error);
      });
  }, [memberId]);

  return (
    <MemberContext.Provider
      value={{
        memberData,
        setMemberData,
        member,
        setMember,
        billingAddress,
        setBillingAddress,
        shippingAddress,
        setShippingAddress,
        notes,
        setNotes,
        homeGroups,
        setHomeGroups,
        otherInfo,
        setOtherInfo,
        selectChangeHandler,
        memberHandleChange,
        otherInfoHandleChange,
        otherInfoSelectChangeHandler,
        ambassadorGroups,
        memberId
      }}
    >
      <SaveBar
        title="Member"
        saveAction={saveHandle}
        buttonCaption={'Save Member'}
        handleShow={handleShow}
      />
      <Confirm body="Do you want to send Golden Ticket Link with stripe payment?" title="Golden Ticket Link" show={show} setShow={setShow} handleConfirm={handleConfirm}/>
      <Row className="g-0 mt-3">
        <Col lg={8} className="pe-lg-2">
          <PersonalInfo
            member={member}
            memberHandleChange={memberHandleChange}
            selectChangeHandler={selectChangeHandler}
            setOtherInfo={setOtherInfo}
          />
          <BillingAddress />
          <ShippingAddress />
          <Other />
          <PersonNotes />
          <RedShirt />

        </Col>
        <Col lg={4}>
          <HomeGroup />
          <Membership />
          <RightEvents
            cardTitle="Meetings"
            events={events.slice(2, 5)}
          />
          <ResetPassword memberId={memberId}/>
          <DangerZone />

        </Col>
        <Col lg={12}>
          <MemberReports member={member}/>
        </Col>
      </Row>
    </MemberContext.Provider>
  );
};

export default MemberForm;
