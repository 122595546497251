import {Button, Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import React, {useEffect, useState} from "react";
import axios from "axios";
import IconButton from "../../../components/common/IconButton";
import DatePicker from "react-datepicker";
import Flex from "../../../components/common/Flex";
import {response_error} from "../../../helpers/functions";

const WinnerFilter = ({param, setParam, getWinners, downloadWinners}) => {
    const [groupOptions, setGroupOptions] = useState([]);
    // Date range
    const [wonDate, setWonDate] = useState({
        start: null,
        end: null
    })
    const [confirmationDate, setConfirmationDate] = useState({
        start: null,
        end: null
    })
    const onChangeConfirmationDate = dates => {
        const [start, end] = dates;
        setConfirmationDate(prevState => ({...prevState, start: start}));
        setConfirmationDate(prevState => ({...prevState, end: end}));
        setParam(prevState => ({...prevState, confirmed_at_from: start, confirmed_at_to: end}))
    };
    const onChangeWonDate = dates => {
        const [start, end] = dates;
        setWonDate(prevState => ({...prevState, start: start}));
        setWonDate(prevState => ({...prevState, end: end}));
        setParam(prevState => ({...prevState, won_at_from: start, won_at_to: end}))
    };

    useEffect(() => {
        axios.get('/groups/forFilter')
            .then(response => {
                setGroupOptions(response.data.groups.map(group => {
                    return {value: group.id, label: group.name}
                }))
            })
            .catch(error => {
                response_error(error);
            })
    }, [])



    return (
        <Row>
            <Col lg={8}>
                <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                >
                    <Col lg="4">
                        <Form.Group className="mb-2">
                            <Form.Control type="text" placeholder="Name" onChange={event => setParam({...param, name: event.target.value})}/>
                        </Form.Group>
                    </Col>
                    <Col lg="4">
                        <Select
                            className="mb-2"
                            closeMenuOnSelect={false}
                            options={groupOptions}
                            placeholder='Select Groups...'
                            isMulti
                            classNamePrefix="react-select"
                            onChange={value => setParam({...param, group_ids: value})}
                        />
                    </Col>

                    <Col lg="4">
                        <Select
                            className="mb-2"
                            closeMenuOnSelect={false}
                            options={[
                                {value: 'yes', label: 'Yes'},
                                {value: 'no', label: 'No'},
                                {value: 'all', label: 'All'},
                            ]}
                            placeholder='Address Provied'
                            classNamePrefix="react-select"
                            onChange={option => setParam({...param, confirmed: option})}
                        />
                    </Col>


                    <Col lg="4">
                        <DatePicker
                            selected={wonDate.start}
                            placeholderText="Select Won Date"
                            onChange={onChangeWonDate}
                            startDate={wonDate.start}
                            formatWeekDay={day => day.toString().slice(0, 3)}
                            endDate={wonDate.end}
                            selectsRange
                            dateFormat="MMM dd yyyy"
                            className='form-control mb-2'
                        />
                    </Col>
                    <Col lg="4">
                        <DatePicker
                            placeholderText="Select Confirmation Date"
                            selected={confirmationDate.start}
                            onChange={onChangeConfirmationDate}
                            startDate={confirmationDate.start}
                            formatWeekDay={day => day.toString().slice(0, 3)}
                            endDate={confirmationDate.end}
                            selectsRange
                            dateFormat="MMM dd yyyy"
                            className='form-control mb-2'
                        />
                    </Col>
                </Form.Group>
            </Col>


            <Col lg="4">
                <Flex justifyContent='end' className="align-self-center">
                    <Button
                        variant="falcon-default"
                        className="me-2"
                        type="button"
                        onClick={() => getWinners(1)}
                    >
                        Search
                    </Button>

                    <Button
                        variant="falcon-default"
                        className="me-2"
                        type="button"
                        onClick={() => downloadWinners()}
                    >
                        Download
                    </Button>
                </Flex>

            </Col>

        </Row>
    )
}

export default WinnerFilter;