import React, {Component} from 'react';
import Section from "../../components/common/Section";
import {Col, Row} from "react-bootstrap";
import Logo from "../../components/common/Logo";

const Header = () => {
    return (
        <Section className="py-4">
            <Row>
                <Col sm={12}>
                    <Logo/>
                </Col>
            </Row>
        </Section>
    );
}

export default Header;