import React, {useContext, useEffect, useState} from 'react';
import {Card, Col, Row, Button, Modal, Form, Image} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FalconCloseButton from "../../../../components/common/FalconCloseButton";
import Select from "react-select";
import {MemberContext} from "../../../../context/Context";
import moment from "moment/moment";
import DatePicker from 'react-datepicker';
import axios from "axios";
import {response_error} from "../../../../helpers/functions";
import toast from "react-hot-toast";


const HomeGroupMarkup = () => {

    const {homeGroups, setHomeGroups, memberId} = useContext(MemberContext);
    const [groupOptions, setGroupOptions] = useState([]);
    const [eventOptions, setEventOptions] = useState([]);
    const [formData, setFormData] = useState({});
    const [show, setShow] = useState(false);
    const [showSwichModal, setShowSwichModal] = useState(false);
    const [groups, setGroups] = useState([]);
    const [switchingGroup, setSwitchingGroup] = useState({
        switching_from: '',
        switching_to: '',
        event_id: '',
        transfer_date: ''
    });

    const [switchingGroups, setSwitchingGroups] = useState([]);

    const src = {
        UK: '/assets/img/flag/uk.png',
        USA: '/assets/img/flag/usa.png',
        Ireland: '/assets/img/flag/ireland.png',
        Spain: '/assets/img/flag/spain.png',
        UAE: '/assets/img/flag/uae.png',
        Sweden: '/assets/img/flag/sweden.png',
    }

       const getEventOption = (group_id) => {
        axios.get(`/groups/${group_id}/events`)
            .then(response => {
                setEventOptions(response.data.map(event => {
                    return {value: event.id, label: event.event_date + ' ' + event.event_time};
                }));
            })
            .catch(error => {
                response_error(error);
            })
    }

     const getGroupOption = () => {
        axios.get('/groups/valid')
            .then(response => {
                setGroupOptions(response.data.groups.map(group => {
                    return {value: group.id, label: group.name};
                }));
            })
            .catch(error => {
                response_error(error);
            })
    }

    const addEventHandler = (switching_from) => {
        setShowSwichModal(true);
        getGroupOption();

        setSwitchingGroup(prevState => ({...prevState, switching_from: switching_from}));
    }

    const groupChangeHandler = (e, meta) => {
        getEventOption(e.value);
        setSwitchingGroup(prevState => ({...prevState, switching_to: e.value}))
    }

    const addHomeGroup = () => {
        setShow(true);
        axios.get('/groups/valid')
            .then(response => {
                setGroups(response.data.groups.map(group => ({value: group.id, label: group.name})));
            })
    }

    const selectChangeHandler = (e, meta) => {
        setFormData(prevState => ({...prevState, [meta.name]: e.value}))
    }

    const setEvent = (e) => {
        setSwitchingGroup(prevState => ({...prevState, event_id: e.value}))
    }

    const submitSwitchingGroup = (e) => {
        e.preventDefault();
        axios.post(`/members/${memberId}/switching-home-group`, switchingGroup)
            .then(response => {
                setShowSwichModal(false);
                toast.success(response.data.message);
            })
            .catch(error => {
                response_error(error);
            })
    }


    const addHomeGroupHandler = (e) => {
        e.preventDefault();
        axios.post(`/members/${memberId}/groups`, formData)
            .then(response => {
                setHomeGroups(response.data.home_groups);
                setShow(false);
            })
    }

    const deleteHomeGroup = (groupId) => {
        axios.delete(`/members/${memberId}/groups/${groupId}`)
            .then(response => {
                setHomeGroups(response.data.home_groups);
            }).catch(error => {
            response_error(error);
        })
    }

    const getHomeGroups = () => {
        axios.get(`/members/${memberId}/groups`)
            .then(response => {
                setHomeGroups(response.data.home_groups);
                setSwitchingGroups(response.data.switching_groups);
            })
            .catch(error => {
                response_error(error);
            })
    }

    useEffect(() => {
        // Home Groups
        getHomeGroups();
    }, [memberId])

    const Actions = (prop) => (
        <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
            <Button variant="light" size="sm" className="border-300 text-600"
                    onClick={() => deleteHomeGroup(prop.group_id)}>
                <FontAwesomeIcon icon="trash-alt"/>
            </Button>
        </div>
    );

    const group_name = homeGroups.find(group => group.id === switchingGroup.switching_from)?.name;

    return (
        <>
            <Row className="g-0 mb-3">
                <Col lg={12}>
                    <Card className="h-lg-100">
                        <Row>
                            <Col lg={6} md={6} sm={6}>
                                <Card.Header as="h5">Home Group</Card.Header>
                            </Col>
                            <Col lg={6} md={6} sm={6} className="text-end">
                                <Card.Header as="h5">
                                    <Button size="sm" variant="falcon-default" className="me-2"
                                            onClick={addHomeGroup}> Add Home Group </Button>
                                </Card.Header>
                            </Col>
                        </Row>
                        <div className="border-dashed-bottom"></div>
                        <Card.Body className="bg-light">
                            {
                                homeGroups &&
                                homeGroups.map((homeGroup, index) => {
                                    return (
                                        <div key={homeGroup.id}>
                                            <div className="flex-1 position-relative hover-actions-trigger">
                                                <h6 className="fs-0 mb-0">{homeGroup.name} <span className="flag">
                                                  <img className="m-lg-1" src={src[homeGroup?.region?.name]}
                                                       height='20px' width='20px'/>

                                                </span> <span
                                                    className="fs--1 text-secondary"> {homeGroup?.region?.name ?? ''} </span>
                                                    <Button size="sm" variant="light" className="swichingIcon border-300 text-600" onClick={() => addEventHandler(homeGroup.id)}  style={{float: "right",marginRight: "80px",position: "relative",top: "20px",cursor: "pointer"}}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path
                                                            d="m9.293 18.707 1.414-1.414L8.414 15H20v-2H3.586l5.707 5.707zM14.707 5.293l-1.414 1.414L15.586 9H4v2h16.414l-5.707-5.707z"/></svg></Button>

                                                </h6>
                                                <p className="mb-0 fs--1 text-secondary">Hard
                                                    Cap: {homeGroup.hard_cap} | Soft Cap: {homeGroup.soft_cap} |
                                                    Members: {homeGroup.members_count}</p>
                                                <div className="border-dashed-bottom my-3"/>
                                                <Actions group_id={homeGroup.id}/>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            {
                                switchingGroups && switchingGroups.map((_switchingGroup, index) => {
                                    return (
                                        <div key={_switchingGroup.id}>
                                            <p>New Home Group "{_switchingGroup?.switching_to?.name}" should be added on {moment(_switchingGroup.transfer_date).format('LL')}</p>
                                        </div>
                                    )
                                })
                            }

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal
                size=" "
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add Group
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={addHomeGroupHandler}>
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Group</Form.Label>
                                    <Select
                                        name="group"
                                        options={groups}
                                        onChange={selectChangeHandler}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3 text-end">
                                    <Button variant="falcon-default" size="sm" type="submit">Submit</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                size=" "
                show={showSwichModal}
                onHide={() => setShowSwichModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Switching From {group_name}
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShowSwichModal(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Group</Form.Label>
                                    <Select options={groupOptions}
                                            onChange={groupChangeHandler}
                                            name='group_id'
                                            value={
                                                groupOptions.filter(option =>
                                                    option.value === switchingGroup.switching_to)
                                            }
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Upcoming Meeting</Form.Label>
                                    <Select options={eventOptions}
                                            name='event_id'
                                            value={
                                                eventOptions.filter(option =>
                                                    option.value === switchingGroup.event_id)
                                            }
                                            onChange={setEvent}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Effective Date</Form.Label>
                                    <DatePicker
                                        selected={switchingGroup.transfer_date ? new Date(switchingGroup.transfer_date) : null }
                                        onChange={(date) => setSwitchingGroup(prevState => ({...prevState, transfer_date: moment(date).format('YYYY-MM-DD')}))}
                                        // formatWeekDay={day => day.slice(0, 3)}
                                        className='form-control'
                                        placeholderText="yyyy-MM-dd"
                                        dateFormat="yyyy-MM-dd"
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3 text-end">
                                    <Button variant="falcon-default" size="sm" type="submit" onClick={submitSwitchingGroup}>Switch</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default HomeGroupMarkup;