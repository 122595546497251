import React, {Component, useContext, useState} from 'react';
import {Form, Card, Col, Row, Button} from 'react-bootstrap';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import Select from 'react-select';
import {MemberContext} from "../../../context/Context";
import {useParams, useSearchParams} from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import {response_error} from "../../../helpers/functions";

const ShippingAddressEntry = () => {
    const [searchParam, setSearchParam] = useSearchParams();
    const country = [
        {value: 'UK', label: 'UK'},
        {value: 'USA', label: 'USA'},
        {value: 'UAE', label: 'UAE'},
        {value: 'Ireland', label: 'Ireland'},
        {value: 'Spain', label: 'Spain'},
        {value: 'UAE Dubai', label: 'UAE Dubai'}
    ];
    const shirtSize = [
        {value: 'Small', label: 'Small'},
        {value: 'Medium', label: 'Medium'},
        {value: 'Large', label: 'Large'},
        {value: 'X-Large', label: 'X-Large'},
        {value: 'XX-Large', label: 'XX-Large'}
    ];

    const handleSave = (e) => {
        e.preventDefault();
        let data = {
            uuid:searchParam.get('uuid'),
            address1: e.target.address1.value,
            address2: e.target.address2.value,
            city: e.target.city.value,
            state: e.target.state.value,
            postal_code: e.target.postal_code.value,
            country: e.target.country.value,
            shirt_size: e.target.shirt_size.value,
        }

        axios.put('/shipping-address', data)
            .then(response => {
                toast.success(response.data.message);
            })
            .catch(error => {
                response_error(error);
            });
    }

    return (
        <Section className="py-0">
            <form onSubmit={handleSave}>
            <Row className="flex-center py-6">
                <Col sm={12} md={8} lg={6} xl={6}>
                    <Logo/>
                    <Card className="h-lg-100">
                        <Card.Header as="h5">Shipping Address</Card.Header>
                        <div className="border-dashed-bottom"></div>
                        <Card.Body className="bg-light">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Select Shirt Size</Form.Label>
                                        <Select
                                            name={'shirt_size'}
                                            options={shirtSize}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={12}>
                                    <Form.Group className="mb-3">
                                    <b>Please note, our shirt sizes are slightly larger than the average. So if you
                                        are in between sizes we would recommend you select the smaller option.</b>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Address 1</Form.Label>
                                        <Form.Control
                                            placeholder="Address 1"
                                            name="address1"
                                            type="text"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Address 2</Form.Label>
                                        <Form.Control
                                            placeholder="Address 2"
                                            name="address2"
                                            type="text"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            placeholder="city"
                                            name="city"
                                            type="text"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>County / State</Form.Label>
                                        <Form.Control
                                            placeholder="county"
                                            name="state"
                                            type="text"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Postal Code</Form.Label>
                                        <Form.Control
                                            placeholder="Postal Code"
                                            name="postal_code"
                                            type="text"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Country</Form.Label>
                                        <Select
                                            name={'country'}
                                            options={country}
                                        />
                                    </Form.Group>
                                </Col>
                                <div className="text-center">
                                    <Button
                                        size="sm"
                                        variant="falcon-default"
                                        className="me-2"
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            </form>
        </Section>
    );
};

export default ShippingAddressEntry;
