import React, {useState} from 'react';
import {Card, Col, Form, Row, Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Select from "react-select";

const CapInfo = ({group, groupHandleChange, selectChangeHandler, regions, sites}) => {
    const [formData, setFormData] = useState({
        startDate: null,
        endDate: null,
        regDate: null,
        startTime: null,
        endTime: null
    });

    const region_options = regions.map(region => {
        return {label: region.name, value: region.id}
    })

    const site_options = sites.map(site => {
        return {label: site.name, value: site.id}
    })

    return (
        <Card className="mb-3">
            <Card.Header as="h5">Cap Info</Card.Header>
            <div className="border-dashed-bottom"></div>
            <Card.Body className="bg-light">
                <Row className="gx-2 gy-3">
                    <Col md="6">
                        <Form.Group controlId="soft_cap" className="mb-3">
                            <Form.Label>Number of Members</Form.Label>
                            <Form.Control
                                type="text"
                                name='soft_cap'
                                value={group.active_members_count}
                                readOnly
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="soft_cap" className="mb-3">
                            <Form.Label>Soft Cap</Form.Label>
                            <Form.Control
                                type="text"
                                name='soft_cap'
                                value={group.soft_cap}
                                onChange={groupHandleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="hard_cap" className="mb-3">
                            <Form.Label>Hard Cap</Form.Label>
                            <Form.Control
                                type="text"
                                name='hard_cap'
                                value={group.hard_cap}
                                onChange={groupHandleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="vip_cap" className="mb-3">
                            <Form.Label>VIP Cap</Form.Label>
                            <Form.Control
                                type="text"
                                name='vip_cap'
                                value={group.vip_cap}
                                onChange={groupHandleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group className="mb-3">
                            <Form.Label>Country</Form.Label>
                            <Select
                                options={region_options}
                                value={
                                    region_options.filter(option =>
                                        option.value === group.region_id)
                                }
                                onChange={selectChangeHandler}
                                name="region_id"
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group className="mb-3">
                            <Form.Label>Region</Form.Label>
                            <Select
                                options={site_options}
                                value={
                                    site_options.filter(option =>
                                        option.value === group.site_id)
                                }
                                onChange={selectChangeHandler}
                                name="site_id"
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>

    );
};

export default CapInfo;
