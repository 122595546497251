import React, {useState} from 'react';
import {Form, Card, Col, Row, Button, InputGroup} from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import FalconCardHeader from "../../../../components/common/FalconCardHeader";
import axios from "axios";
import toast from "react-hot-toast";
import {response_error} from "../../../../helpers/functions";

const ResetPassword = ({memberId}) => {

    const [password, setPassword] = useState('');

    const generatePassword = () => {
        const length = 12;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}|[]:;<>,.?/~";
        let password = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }

        setPassword(password);
    };

    
    const saveAndSendPassword = async (password) => {
        try {
            const response = await axios.post(`/members/${memberId}/send-new-password`, {
                password
            })
            console.log(response)
            toast(response.data.message)
        }catch (e){
            toast.error(e.message)
        }
    }

    return (
        <Row className="g-0 mb-3">
            <Col lg={12}>
                <Card>
                    <Card.Header as="h5">Reset Password</Card.Header>
                    <div className="border-dashed-bottom"></div>
                    <Card.Body>
                        <Row>
                            <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Generate Password</Form.Label>
                                      <InputGroup className="mb-3">
                                          <Form.Control
                                              placeholder="Generate Password"
                                              name="app_code"
                                              type="text"
                                              value={password}
                                          />
                                          <Button variant="outline-secondary" id="button-addon2" onClick={generatePassword}>
                                              Generate
                                          </Button>
                                      </InputGroup>

                                </Form.Group>
                                <Button variant="falcon-default" className="w-100"  onClick={() => saveAndSendPassword(password)}>
                                    Save & Sent Email
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

    );
};

export default ResetPassword;
