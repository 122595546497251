import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Form, Modal, Row} from 'react-bootstrap';
import SaveBar from "../../../components/custom/save-bar";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import SimpleBarReact from "simplebar-react";
import Flex from "../../../components/common/Flex";
import Calendar from "../../../components/common/Calendar";
import {Link} from "react-router-dom";
import FalconCloseButton from "../../../components/common/FalconCloseButton";
import Select from "react-select";
import {confirmAlert} from 'react-confirm-alert';
import axios from "axios";
import {response_error} from "../../../helpers/functions";
import moment from "moment/moment";
import Confirm from "../../../components/custom/confirm";
import toast from "react-hot-toast";
import {FaPlus} from "react-icons/all";

const MemberMeeting = () => {
    const [show, setShow] = useState(false);
    const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);
    const [meetings, setMeetings] = useState([]);
    const [groups, setGroups] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [eventOptions, setEventOptions] = useState([]);
    const defaultOption = {value: '0', label: (<><FaPlus /> Add Substitute</>)};
    const [substituteOptions,setSubstituteOptions] = useState([defaultOption]);
    const [formData, setFormData] = useState({});
    // const [substitutes, setSubstitutes] = useState(null);


    const getMeetings = () => {
        axios.get('/member/meetings')
            .then((res) => {
                setMeetings(res.data);
            })
            .catch((err) => {
                response_error(err);
            });
    };

    const getSubstitutes = () => {
        axios.get('/member/substitutes')
            .then((res) => {
                setSubstituteOptions([defaultOption, ...res.data.map(substitute => {
                    return {value: substitute.id, label: substitute.name}
                })]);
            })
            .catch((err) => {
                response_error(err);
            });
    };

    const getGroups = () => {
        axios.get('/common/groups/valid-groups-for-select-field')
            .then((res) => {
                setGroups(res.data.groups);

                let options = [];
                res.data.groups.map((item, index) => {
                    options.push({value: item.id, label: item.name});
                });
                setGroupOptions(options);

            })
            .catch((err) => {
                response_error(err);
            });
    };

    const getEventOption = (group_id) => {
        axios.get(`/groups/${group_id}/events`)
            .then(response => {
                setEventOptions(response.data.map(event => {
                    return {value: event.id, label: event.event_date + ' ' + event.event_time};
                }));
            })
            .catch(error => {
                response_error(error);
            })
    }

    const groupChangeHandler = (e, meta) => {
        getEventOption(e.value);
        setFormData(prevState => ({...prevState, group_id: e.value}))
    }

    const selectChangeHandler = (e, meta) => {
        setFormData(prevState => ({...prevState, [meta.name]: e.value}))
    }

    const substituteChangeHandler = (e, meta) => {
        setFormData(prevState => ({...prevState, substitute: {...prevState.substitute, id: e.value}}))
    }

    const inputHandler = (e) => {
        setFormData(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    const substituteInputHandler = (e) => {
        setFormData(prevState => ({...prevState, substitute: {...prevState.substitute, [e.target.name]: e.target.value}}))
    }

    useEffect(() => {
        getMeetings();
        getGroups();
        getSubstitutes();
    }, []);


    const EditMeetingModal = (id) => {
        setShow(true);

        meetings.find((item) => {
            if (item.id === id) {
                getEventOption(item.group_id)

                setFormData({
                    id: item.id,
                    group_id: item.group_id,
                    event_id: item.event_id,
                    attending: item.attending,
                    attending_as: item.attending_as,
                    substitute: item.substitute,
                });
            }
        })
    }

    const AddMeetingModal = () => {
        setShow(true);
        setFormData({});
    }

    const [meeting_id, setMeetingId] = useState(null);
    const showConfirmation = (meeting_id) => {
        setDeleteConfirmationShow(true)
        setMeetingId(meeting_id)
    };

    const deleteMeeting = () => {
        axios.delete(`/member/meetings/${meeting_id}`)
            .then((res) => {
                getMeetings();
                setDeleteConfirmationShow(false);
            })
            .catch((err) => {
                response_error(err);
            });
    }

    const saveEvent = (e) => {
        e.preventDefault();

        if (formData.id) {
            axios.put(`/member/meetings/${formData.id}`, {...formData})
                .then(response => {
                    toast.success(response.data.message);
                    setShow(false);
                    getMeetings();
                })
                .catch(error => {
                    response_error(error);
                })
        } else {
            axios.post(`/member/meetings`, {...formData})
                .then(response => {
                    toast.success(response.data.message);
                    setShow(false);
                    getMeetings();
                })
                .catch(error => {
                    response_error(error);
                })
        }
    }

    const Actions = ({meeting_id}) => (
        <div className="end-0 top-50 pe-3">
            <Button variant="light" size="sm" className="border-300 text-600 me-2">
                <FontAwesomeIcon icon="edit" onClick={() => EditMeetingModal(meeting_id)}/>
            </Button>
            <Button variant="light" size="sm" className="border-300 text-600"
                    onClick={() => showConfirmation(meeting_id)}>
                <FontAwesomeIcon icon="trash-alt"/>
            </Button>
        </div>
    );
    return (
        <>
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={6}>
                            <h5>Meetings</h5>
                        </Col>
                        <Col xs={6}>
                            <div className="text-end">
                                <Button variant="falcon-default" onClick={AddMeetingModal}>
                                    <FaPlus /> Add Flexi/Ultimate Meeting
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
            </Card>
            <Row className="g-3 mt-3">
                {
                    meetings.length > 0 && meetings.map((item, index) => (
                        <Col lg={4} key={index}>
                            <Card className="h-lg-100">
                                <SimpleBarReact style={{maxHeight: '19rem'}}>
                                    <Card.Body className="bg-light">
                                        <Flex>
                                            <Calendar month={moment(item.event.event_date).format('MMM')}
                                                      day={moment(item.event.event_date).format('DD')}/>
                                            <div className="flex-1 position-relative ps-3">
                                                <h6 className="fs-0 mb-0">
                                                    <Link to="#">
                                                        <span className="me-1">{item?.group?.name}</span>
                                                        <span className="badge rounded-pill"></span>
                                                    </Link>
                                                </h6>
                                                <p className="mb-1">Attending as: {item.attending_as}</p>
                                                <p className="mb-1">Attending: {item.attending}</p>
                                                <p className="mb-1">Time: {item.event.event_time}</p>
                                                {
                                                    item.substitute && (
                                                        <p className="mb-1">Substitute: {item.substitute.name}</p>
                                                    )
                                                }
                                            </div>
                                            <Actions meeting_id={item.id}/>
                                        </Flex>
                                    </Card.Body>
                                </SimpleBarReact>
                            </Card>
                        </Col>
                    ))
                }

            </Row>
            <Confirm body="Do you want to delete your meeting?" title="Delete!" show={deleteConfirmationShow}
                     setShow={setDeleteConfirmationShow} handleConfirm={deleteMeeting}/>
            <Modal
                size=" "
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Meeting
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={e => saveEvent(e)}>

                        <Row>
                            <Col lg={12}>
                                {
                                    (!formData.id || (formData.attending_as && (formData.attending_as === 'Member - Flexi' || formData.attending_as.includes('Member - Ultimate')))) && (
                                        <Form.Group className="mb-3">
                                            <Form.Label>Group</Form.Label>
                                            <Select options={groupOptions}
                                                    onChange={groupChangeHandler}
                                                    name='group_id'
                                                    value={
                                                        groupOptions.filter(option =>
                                                            option.value === formData.group_id)
                                                    }
                                            />
                                        </Form.Group>
                                    )
                                }


                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Event</Form.Label>
                                    <Select options={eventOptions}
                                            name='event_id'
                                            value={
                                                eventOptions.filter(option =>
                                                    option.value === formData.event_id)
                                            }
                                            onChange={selectChangeHandler}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Attending</Form.Label>
                                    <div className="d-block">
                                        <Form.Check inline type='radio' id='attending_yes' label='Yes'
                                                    name='attending'
                                                    value="YES"
                                                    checked={formData.attending === 'YES' ? 'checked' : ""}
                                                    onChange={inputHandler}
                                        />
                                        <Form.Check inline type='radio' id='attending_no' label='No'
                                                    name='attending'
                                                    value="NO"
                                                    checked={formData.attending === 'NO' ? 'checked' : ""}
                                                    onChange={inputHandler}
                                        />
                                        <Form.Check inline type='radio' id='attending_dont_know' label="Don't Know"
                                                    value={"DON'T KNOW"}
                                                    name='attending'
                                                    checked={formData.attending === "DON'T KNOW" ? 'checked' : ""}
                                                    onChange={inputHandler}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Substitute (Only for Corporate Member)</Form.Label>
                                    <Select options={substituteOptions}
                                            name='substitute_id'
                                            value={
                                                substituteOptions.filter(option =>
                                                    option.value === formData.substitute?.id)
                                            }
                                            onChange={substituteChangeHandler}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        {formData.substitute && formData.substitute?.id === '0' && (
                            <Row>
                                <Col lg={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            name='name'
                                            type="text"
                                            placeholder="Name"
                                            onChange={substituteInputHandler}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control
                                            name='phone'
                                            type="text"
                                            placeholder="Phone"
                                            onChange={substituteInputHandler}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            name='email'
                                            type="text"
                                            placeholder="Email"
                                            onChange={substituteInputHandler}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Handicap</Form.Label>
                                        <Form.Control
                                            name='handicap'
                                            type="text"
                                            placeholder="Handicap"
                                            onChange={substituteInputHandler}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-3 text-end">
                                    <Button variant="falcon-default" size="sm" type="submit">Submit</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default MemberMeeting;