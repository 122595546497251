import React, {Component, useContext, useEffect, useState} from 'react';
import {Form, Card, Col, Row, Dropdown} from 'react-bootstrap';
import CardDropdown from "../../../components/common/CardDropdown";
import SoftBadge from "../../../components/common/SoftBadge";
import ReactPaginate from "react-paginate";
import {next_icon, previous_icon, response_error} from "../../../helpers/functions";
import axios from "axios";
import Confirm from "../../../components/custom/confirm";
import toast from "react-hot-toast";
import {Link} from "react-router-dom";

const ProductAllTable = () => {
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
     const [products, setProducts] = useState([]);
     const [productId, setProductId] = useState(null);

      // Delete
    const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);
    const showConfirmation = (id) => {
        setProductId(id)
        setDeleteConfirmationShow(true)
    };
   const handleDeleteProduct = () => {
        axios.delete(`/products/${productId}`)
            .then(response => {
                toast.success(response.data.message);
                setDeleteConfirmationShow(false)
                setProductId(0)
            })
            .catch(error => {
                response_error(error);
            });
    }

    // End Delete
    const handlePageClick = page => {
        setPage(page.selected + 1);
    }
    const getProducts = () => {
        axios.get(`/products`)
            .then(response => {
                setProducts(response.data.products.data);
                // Pagination
                let total_page = parseInt(response.data.products.total) / parseInt(response.data.products.per_page);
                total_page = total_page > 0 ? Math.ceil(total_page) : 0;
                setPageCount(total_page);
                // End Pagination
            })
            .catch(error => {
                response_error(error);
            });
    }
    useEffect(() => {
        getProducts();
    }, []);
    return (
        <Row className="g-0 mb-3">
            <Col lg={12} className="pe-lg-2">
                <Card className="h-lg-100">
                     <Confirm
                body="Do you want to delete this product ?"
                title="Delete!"
                show={deleteConfirmationShow}
                setShow={setDeleteConfirmationShow}
                handleConfirm={handleDeleteProduct}
            />
                    <Card.Header as="h5">Products All</Card.Header>
                    <div className="border-dashed-bottom"></div>
                    <Card.Body className="bg-light">
                        <div className="table-responsive">
                        <table className="fs--1 mb-0 table table-sm table-striped overflow-hidden" role="table">
                            <thead className="bg-200 text-900 text-nowrap align-middle">
                            <tr>
                                <th>Product Name</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Points</th>
                                <th className="text-center">Order Status</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                products.map((product, index) => (
                                    <tr className="align-middle white-space-nowrap" role="row" key={index}>
                                        <td role="cell" className="py-2">{product.name}</td>
                                        <td role="cell">{product.sub_title}</td>
                                        <td role="cell">{product.description}</td>
                                        <td role="cell">{product.point}</td>
                                        <td role="cell" className="fs-0">
                                            <SoftBadge pill bg={
                                                product.status === 'published' ? 'success' :
                                                    product.status === 'draft' ? 'warning' :
                                                        product.status === 'inactive' ? 'danger' :
                                                            ''
                                            } className='d-block text-capitalize'>{product.status}</SoftBadge>
                                        </td>
                                        <td role="cell" className="text-end">
                                            <CardDropdown drop="start" btnRevealClass="btn-reveal-sm">
                                        <div className="py-2">
                                            <Dropdown.Item href={`/products/${product.id}/edit`}>Edit</Dropdown.Item>
                                            <Dropdown.Divider as="div"/>
                                            <Dropdown.Item className="text-danger" onClick={() => showConfirmation(product.id)}>
                                                Delete
                                            </Dropdown.Item>
                                        </div>
                                    </CardDropdown>
                                </td>
                                    </tr>
                                ))
                            }

                            </tbody>
                        </table>
                            </div>
                    </Card.Body>
                    <Card.Footer>
                    <ReactPaginate
                        previousLabel={previous_icon()}
                        nextLabel={next_icon()}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination mb-0 mx-1'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </Card.Footer>
                </Card>
            </Col>
        </Row>
    );
};

export default ProductAllTable;