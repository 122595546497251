import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {Button, Card, Col, Form, Modal, Row, Tab, Table} from 'react-bootstrap';
import axios from "axios";
import {next_icon, previous_icon, response_error} from "../../../../helpers/functions";
import {MemberContext} from "../../../../context/Context";
import ActionButton from "../../../../components/common/ActionButton";
import FalconCloseButton from "../../../../components/common/FalconCloseButton";
import Confirm from "../../../../components/custom/confirm";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";


const Points = ({point}) => {

    // Pagination
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const handlePageClick = page => {
        setPage(page.selected + 1);
    }
    // End Pagination

    const [show, setShow] = useState(false);
    const [balance, setBalance] = useState(0);
    const [clicked, setClicked] = useState(false);
    const [points, setPoints] = useState([]);
    const [pointData, setPointData] = useState({});
    const [pointId, setPointId] = useState(null);

    const {memberId} = useContext(MemberContext);

    const getWarningClass = (value) => {
        const isNegative = value < 0;
        return isNegative ? 'py-2 text-danger' : 'py-2';
    };

    const get_points = () => {
        setClicked(true);

        axios.get(`/members/${memberId}/points`)
            .then(response => {
                setPoints(response.data.data);

                // Pagination
                let total_page = parseInt(response.data.total) / parseInt(response.data.per_page);
                total_page = total_page > 0 ? Math.ceil(total_page) : 0;
                setPageCount(total_page);
                // End Pagination
            })
            .catch(error => {
                response_error(error);
            });
    }

    // Delete
    const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);
    const showConfirmation = (id) => {
        setPointId(id)
        setDeleteConfirmationShow(true)
    };
    const handleDeletePoint = () => {
        axios.delete(`/members/${memberId}/points/${pointId}`)
            .then(response => {
                get_points();
                toast.success(response.data.message);
                setBalance(response.data.balance);
                setDeleteConfirmationShow(false)
                setPointId(0)
            })
            .catch(error => {
                response_error(error);
            });
    }
    // End Delete

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: {errors}
    } = useForm(
        {
            values: pointData
        }
    );


    const add_point = () => {
        setPointData({})
        setPointId(0)
        reset();
        setShow(true)
    }

    const onSubmit = data => {

        if (pointId > 0){
            axios.put(`/members/${memberId}/points/${pointId}`, data)
                .then(response => {
                    get_points();
                    setShow(false);
                    reset();
                    toast.success(response.data.message);
                    setBalance(response.data.balance);
                })
                .catch(error => {
                    response_error(error);
                });
        }
        else{
            axios.post(`/members/${memberId}/points`, data)
                .then(response => {
                    get_points();
                    setShow(false);
                    toast.success(response.data.message);
                    setBalance(response.data.balance);
                })
                .catch((err) => {
                    response_error(err);
                })
        }

    };

    // Edit
    const handleEdit = (id) => {
        setPointId(id);

        setPointData(points.find(point => point.id === id));

        setShow(true);
    }
    // End Edit

    return (
        <Fragment>
            <Row className="g-0 mt-3">
                <Col lg={12}>
                    <Row className="g-0">
                        <Col lg={12}>
                            <Card className="h-lg-100">
                                <Card.Header as="h5">
                                    <Row>
                                        <Col xs={4}>
                                            {!clicked && (
                                                <Button variant="falcon-default" onClick={get_points}>Load
                                                    Points</Button>
                                            )}
                                            {clicked && (
                                            <Button variant="falcon-default" onClick={add_point}>
                                                Add Points
                                            </Button>
                                            )}
                                            <p>Balance: {balance ? balance : point}</p>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    {clicked  && points.length > 0 && (
                                            <Row>
                                                <Col sm={8}>
                                                    <Table borderless hover
                                                           className="fs--2 mb-0 overflow-hidden table table-sm table-striped"
                                                           role="table">
                                                        <thead className="bg-200 text-900 text-nowrap align-middle">
                                                        <tr>
                                                            <th>Points</th>
                                                            <th className="text-center">Notes</th>
                                                            <th className="text-end">Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            points.map((point, index) => (
                                                                <tr key={index}>
                                                                    <td role="cell" className={getWarningClass(point.point)}>{point.point}</td>
                                                                    <td role="cell">{point.note}</td>
                                                                    <td className="text-end">
                                                                        <ActionButton icon="edit" title="Edit" variant="action"
                                                                                      className="p-0 me-2 fs--2"
                                                                                      onClick={() => handleEdit(point.id)}/>
                                                                        <ActionButton icon="trash-alt" title="Delete"
                                                                                      variant="action" className="p-0 fs--2"
                                                                                      onClick={() => showConfirmation(point.id)}/>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    {
                                        clicked && points.length === 0 && (
                                            <div className="text-center">
                                                <h4>No Points Found</h4>
                                            </div>
                                        )
                                    }
                                </Card.Body>
                                {clicked  && points.length > 0 && (
                                <Card.Footer>
                                    <ReactPaginate
                                        previousLabel={previous_icon()}
                                        nextLabel={next_icon()}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={'pagination mb-0 mx-1'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />
                                </Card.Footer>
                                    )}
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Confirm
                body="Do you want to delete your points ?"
                title="Delete!"
                show={deleteConfirmationShow}
                setShow={setDeleteConfirmationShow}
                handleConfirm={handleDeletePoint}
            />
            <Modal
                size=" "
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add Points
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)}/>
                </Modal.Header>
                <Modal.Body>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Point</Form.Label>
                                    <Form.Control
                                        placeholder="point"
                                        name="point"
                                        type="text"
                                        isInvalid={!!errors.point}
                                        {...register('point', {
                                            required: 'Point field is required'
                                        })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.point && errors.point.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Notes</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Type Your Notes"
                                        name="note"
                                        isInvalid={!!errors.note}
                                        {...register('note', {
                                            required: 'Please type your notes'
                                        })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.note && errors.note.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="mb-3 text-end">
                                    <Button variant="falcon-default" size="sm" type="submit">Submit</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>

                </Modal.Body>
            </Modal>
        </Fragment>
    );
}

export default Points;