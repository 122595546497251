import React, {useContext, useEffect, useState} from 'react';
import {Card, Col, Row, Tab, Tabs, Form, ButtonGroup, Button} from "react-bootstrap";
import AdvanceTablePagination from "../../../../components/common/advance-table/AdvanceTablePagination";
import axios from "axios";
import {useParams} from "react-router-dom";
import {next_icon, previous_icon, response_error} from "../../../../helpers/functions";
import {MemberContext} from "../../../../context/Context";
import ReactPaginate from "react-paginate";
import MemberActivitiesLog from "./MemberActivitiesLog";
import Points from "./Points";
import member from "../Member";

const MemberReports = ({member}) => {
    const reffered_member = [
        {
            id: 1,
            member_name: 'Rayhan',
            member_group: 'Group A',
            member_email: 'rayhan@opcodespace.com',
            event_date: 'January, 21, 2022',
            event_time: '10:02 AM',
            attedent: 'Yes',
            jacket_color: 'Red',
        },
        {
            id: 2,
            member_name: 'Mehedee',
            member_group: 'Group B',
            member_email: 'mehedee@opcodespace.com',
            event_date: 'February, 21, 2024',
            event_time: '10:02 AM',
            attedent: 'No',
            jacket_color: 'Black',
        },
        {
            id: 3,
            member_name: 'Jhone Due',
            member_group: 'Group C',
            member_email: 'jhon@opcodespace.com',
            event_date: 'March, 21, 2023',
            event_time: '10:02 AM',
            attedent: 'Yes',
            jacket_color: 'Yellow',
        },
    ];
    const { memberId } = useContext(MemberContext);
    const [referredMembers, setReferredMembers] = useState([]);
    const [completedEvents, setCompletedEvents] = useState([]);
    const [reportMessage, setReportMessage] = useState(false);
    const [totalReferredMember, setTotalReferredMember] = useState(0);
    const [activeMember, setActiveMember] = useState(true);
    const [clicked, setClicked] = useState(false);
    const referred_members_click = (key) => {
        if(key === 'referred_member'){
            get_referred_members(activeMember);
        }
        if(key === 'attendee_history'){
            get_completed_events();
        }
    }

    const get_referred_members = (status) => {
        setClicked(true);
        axios.get(`/members/${memberId}/referred-members`, {params: {active_member: +status}})
            .then(response => {
                setReferredMembers(response.data.data);
                setTotalReferredMember(response.data.total);

                if(response.data.total === 0){
                    setReportMessage('No Result.');
                }
            })
            .catch(error => {
                response_error(error);
            })
    }

    const get_completed_events = () => {
        axios.get(`/members/${memberId}/completed-events`)
            .then(response => {
                setCompletedEvents(response.data);
                if(response.data.total === 0){
                    setReportMessage('No Result.');
                }
            })
            .catch(error => {
                response_error(error);
            })
    }


    const active_member = (e) => {
        setActiveMember(prevState => !prevState);
        get_referred_members(e.target.checked);
    }

    return (
        <>
            <Row className="g-0 mb-3">
                <Card className="h-lg-100">
                    <Card.Body className="bg-light">
                        <Tabs defaultActiveKey="referred_member" id="member_report" onSelect={(key) => referred_members_click(key)}>
                            <Tab eventKey="referred_member" title="Referred Member"
                                 className='border-bottom border-x p-3'>
                                {
                                    referredMembers.length > 0 ? (
                                        <>
                                            <Form.Check
                                                type='checkbox'
                                                id='liveMember'
                                                label='Only active members'
                                                defaultChecked
                                                onChange={active_member}
                                            />
                                            <h6 className="fs-0 mb-0">Total: {totalReferredMember}</h6>

                                            <Row className="gx-2 gy-3 pt-3">
                                                {referredMembers.map(referredMember => (
                                                    <Col md="4" key={referredMember.id}>
                                                        <Card className="mb-3 card_no_shadow">
                                                            <Card.Body>
                                                                <div className="flex-1 position-relative hover-actions-trigger">
                                                                    <h6 className="fs-0 mb-0">{referredMember.first_name} {referredMember.last_name}</h6>
                                                                    <p className="mb-0 fs--1 text-secondary">{referredMember.email}</p>
                                                                    <p className="mb-0 fs--1 text-secondary">{referredMember.home_groups.map(group => group.name).join(', ')}</p>
                                                                    <p className="mb-0 fs--1 text-secondary">{referredMember.joined_date}
                                                                    </p>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </>
                                    )
                                        : (
                                           <p>{reportMessage}</p>
                                    )
                                }
                                {!clicked && (
                                    <Button variant="falcon-default" onClick={() => get_referred_members(activeMember)}>Load Referred Member</Button>
                                )}

                            </Tab>
                            <Tab eventKey="attendee_history" title="Attendee History"
                                 className='border-bottom border-x p-3'>
                                <Row className="gx-2 gy-3">
                                    {completedEvents.map(event => (
                                        <Col md="4" key={event.id}>
                                            <Card className="mb-3 card_no_shadow">
                                                <Card.Body>
                                                    <div className="flex-1 position-relative hover-actions-trigger">
                                                        <h6 className="fs-0 mb-0">{event.group.name}</h6>
                                                        <p className="mb-0 fs--1 text-secondary">{event.event_date_format}
                                                            <span>{event.event_time}</span></p>
                                                        <p className="mb-0 fs--1 text-secondary">Attendant: {event.pivot.attending}</p>
                                                        <p className="mb-0 fs--1 text-secondary">Attendant as: {event.pivot.attending_as}</p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>

                            </Tab>
                            <Tab eventKey="foreball_booking" title="4 Ball Booking"
                                 className='border-bottom border-x p-3'>
                                <h6 className="fs-0 mb-0">4 Ball Booked: 70%</h6>
                                <Row className="gx-2 gy-3 pt-3">
                                    {reffered_member.map(memberInfo => (
                                        <Col md="4" key={memberInfo.id}>
                                            <Card className="mb-3 card_no_shadow">
                                                <Card.Body>
                                                    <div className="flex-1 position-relative hover-actions-trigger">
                                                        <div
                                                            className="fs-0 mb-0">{memberInfo.event_date}<span>{memberInfo.event_time}</span>
                                                        </div>
                                                        <div
                                                            className="fs-0 mb-0">{memberInfo.event_date}<span>{memberInfo.event_time}</span>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Tab>
                            <Tab eventKey="national_events_booking" title="National Events Booking"
                                 className='border-bottom border-x p-3'>
                                <Row className="gx-2 gy-3 pt-3">
                                    {reffered_member.map(memberInfo => (
                                        <Col md="4" key={memberInfo.id}>
                                            <Card className="mb-3 card_no_shadow">
                                                <Card.Body>
                                                    <div className="flex-1 position-relative hover-actions-trigger">
                                                        <h6 className="fs-0 mb-0">{memberInfo.jacket_color} Jacket</h6>
                                                        <div className="fs-0 mb-0">{memberInfo.event_date}
                                                            <span>{memberInfo.event_time}</span></div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Tab>
                            <Tab eventKey="member_activities_logs" title="Member Activities Logs"
                                 className='border-bottom border-x p-3'>
                                <Row className="gx-2 gy-3 pt-3">
                                    <MemberActivitiesLog/>
                                </Row>
                            </Tab>
                            <Tab eventKey="credits" title="Points"
                                 className='border-bottom border-x p-3'>
                                <Row className="gx-2 gy-3 pt-3">
                                   <Points point={member.point}/>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>
            </Row>
        </>
    );
}

export default MemberReports;