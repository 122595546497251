import React, {Fragment, useEffect, useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {input_data, response_error} from "../../../../helpers/functions";
import axios from "axios";
import {useParams} from "react-router-dom";
import {toast} from "react-hot-toast";

const MembershipTypeForm = () => {
    const default_membership_type = {
        id: '',
        name: '',
        no_reminder_email: false,
        not_visible_on_acp: false
    };
    const [membershipType, setMembershipType] = useState(default_membership_type);
    const {membershipTypeId} = useParams();

    useEffect(() => {
        if(membershipTypeId > 0){
            axios.get(`/membership/${membershipTypeId}`)
                .then(response => setMembershipType(response.data))
                .catch(error => response_error(error))
        }

    }, [])

    const save = () => {
        if(membershipType.id> 0){
            axios.put(`/membership/${membershipTypeId}`, membershipType)
                .then(response => {
                    toast.success(response.data.message);
                })
                .catch(error => response_error(error))
        }
        else{
            axios.post(`/membership`, membershipType)
                .then(response => {
                    toast.success(response.data.message);
                    setMembershipType(default_membership_type);
                })
                .catch(error => response_error(error))
        }
    }

    return (
        <Fragment>
            <Row className="g-3">
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <Row className="flex-between-center">
                                <Col md>
                                    <h5 className="mb-2 mb-md-0">Create Membership Type</h5>
                                </Col>
                                <Col xs="auto">
                                    <Button
                                        size="sm"
                                        variant="falcon-default"
                                        className="me-2"
                                        type="button"
                                        onClick={save}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="g-0 mt-3">
                <Col lg={12} className="pe-lg-2">
                    <Row className="g-0">
                        <Col lg={12} className="pe-lg-2">
                            <Card className="h-lg-100">
                                <Card.Header as="h5">Membership Type</Card.Header>
                                <div className="border-dashed-bottom"></div>
                                <Card.Body>
                                    <Row>
                                        <Col md="6">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    value={membershipType.name}
                                                    onChange={(e) => input_data(e, setMembershipType)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="3">
                                            <Form.Group className="mb-3">
                                                <Form.Label>No Reminder email (attendee list email)</Form.Label>
                                                <div className="d-block">
                                                    <Form.Check inline type='radio' value="1" id='visible_email_yes'
                                                                label='Yes'
                                                                name='no_reminder_email'
                                                                defaultChecked={membershipType.no_reminder_email}
                                                                onClick={(e) => input_data(e, setMembershipType, true)}

                                                    />
                                                    <Form.Check inline type='radio' value="0" id='visible_email_no'
                                                                label='No'
                                                                name='no_reminder_email'
                                                                defaultChecked={!membershipType.no_reminder_email}
                                                                onClick={(e) => input_data(e, setMembershipType, true)}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md="3">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Not Visible on</Form.Label>
                                                <div className="d-block">
                                                    <Form.Check inline type='radio' value="1" id='visible_acp_yes'
                                                                label='Yes'
                                                                name='not_visible_on_acp'
                                                                defaultChecked={membershipType.not_visible_on_acp}
                                                                onClick={(e) => input_data(e, setMembershipType, true)}
                                                    />
                                                    <Form.Check inline type='radio' value="0" id='visible_acp_no'
                                                                label='No'
                                                                name='not_visible_on_acp'
                                                                defaultChecked={!membershipType.not_visible_on_acp}
                                                                onClick={(e) => input_data(e, setMembershipType, true)}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Fragment>);
}

export default MembershipTypeForm;