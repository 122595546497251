import React, {useState} from 'react';
import {Card, Col, Form, Row, Button} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import Select from "react-select";
import moment from "moment";

const GroupInfo = ({group, setGroup, groupHandleChange, selectChangeHandler}) => {

    const memberStatus = [
        {value: 'active', label: 'Active'},
        {value: 'inactive', label: 'Inactive'},
        {value: 'test', label: 'Test'},
    ];

    return (
        <Card className="mb-3">
            <Card.Header as="h5">Group Info</Card.Header>
            <div className="border-dashed-bottom"></div>
            <Card.Body className="bg-light">
                <Row className="gx-2 gy-3">
                    <Col md="12">
                        <Form.Group className="mb-3">
                            <Form.Label>Group Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={group.name}
                                onChange={groupHandleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Status</Form.Label>
                            <Select
                                options={memberStatus}
                                value={
                                    memberStatus.filter(option =>
                                        option.value === group.status)
                                }
                                onChange={selectChangeHandler}
                                name="status"
                            />
                        </Form.Group>
                    </Col>
                    <Col md="12">
                        <Form.Group className="mb-0">
                            <Form.Label>Publish At</Form.Label>
                            <DatePicker
                                selected={Date.parse(group.publish_at.replace(' ', 'T'))}
                                onChange={(eventTime) => setGroup(prevState => ({...prevState, publish_at: moment(eventTime).format('YYYY-MM-DD HH:mm:ss')}))}
                                className='form-control'
                                placeholderText="Select Date & Time"
                                timeIntervals={5}
                                dateFormat="yyyy-MM-dd H:mm:ss"
                                showTimeSelect
                                fixedHeight
                                name='publish_at'
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group className="mb-0">
                            <Form.Label>Hide on golden ticket</Form.Label>
                            <div className="d-block">
                                <Form.Check inline type='radio' value="1" id='hide_golden_ticket_yes' label='Yes'
                                            name='golden_ticket'
                                            checked={group.golden_ticket}
                                            onClick={groupHandleChange}
                                />
                                <Form.Check inline type='radio' value="0" id='hide_golden_ticket_no' label='No'
                                            name='golden_ticket'
                                            checked={!group.golden_ticket}
                                            onClick={groupHandleChange}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group className="mb-0">
                            <Form.Label>Activate VIPS</Form.Label>
                            <div className="d-block">
                                <Form.Check inline type='radio' value="1" id='activate_vips_yes' label='Yes'
                                            name='vip'
                                            checked={group.vip}
                                            onClick={groupHandleChange}
                                />
                                <Form.Check inline type='radio' value="0" id='activate_vips_no' label='No'
                                            name='vip'
                                            checked={!group.vip}
                                            onClick={groupHandleChange}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>

    );
};

export default GroupInfo;
