import {Button, Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import IconButton from "../../../../components/common/IconButton";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {response_error} from "../../../../helpers/functions";

const GroupFilterForm = ({param, setParam, getGroups, setModalShow}) => {
    const [groupOptions, setGroupOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);


    useEffect(() => {
        axios.get('/groups/options', {params: {include_inactive: 1}})
            .then(response => {
                setGroupOptions(response.data.map(group => {
                    return {value: group.id, label: group.name}
                }))
            })
            .catch(error => {
                response_error(error);
            })

        axios.get('/regions')
            .then(response => {
                setRegionOptions(response.data.regions.map(region => {
                    return {value: region.id, label: region.name}
                }))
            })
            .catch(error => {
                response_error(error);
            })

    }, [])
    return (
        <Row>
            <Col lg={12}>
                <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                >
                    <Col lg="2">
                        <Select
                            closeMenuOnSelect={false}
                            options={groupOptions}
                            placeholder='Groups'
                            isMulti
                            classNamePrefix="react-select"
                            value={param.groups}
                            onChange={value => setParam({...param, groups: value})}
                        />
                    </Col>
                    <Col lg="2">
                        <Select
                            closeMenuOnSelect={false}
                            options={regionOptions}
                            placeholder='Country'
                            isMulti
                            classNamePrefix="react-select"
                            value={param.regions}
                            onChange={value => setParam({...param, regions: value})}
                        />
                    </Col>
                    <Col lg="2" className="pt-2">
                        <Form.Check
                            type='checkbox'
                            id='includeInactive'
                            label='Include Inactive'
                            onChange={event => setParam({...param, include_inactive: +event.target.checked})}
                        />
                    </Col>


                    <Col lg="2">
                        <Button
                            variant="falcon-default"
                            className="me-2"
                            type="button"
                            onClick={getGroups}
                        >
                            Search
                        </Button>
                    </Col>

                    <Col lg="4" className="text-end">
                        <div id="orders-actions">
                            <IconButton
                                variant="falcon-default"
                                icon="plus"
                                transform="shrink-3"
                                onClick={() => setModalShow(true)}
                            >
                                <span className="d-none d-sm-inline-block ms-1">New Group</span>
                            </IconButton>
                        </div>
                    </Col>
                </Form.Group>
            </Col>
        </Row>
    )
}

export default GroupFilterForm;