import {Button, Card, Col, Row} from "react-bootstrap";
import React from "react";

const saveBar = ({saveAction, title, buttonCaption, handleShow}) => {
    return (
        <Row className="g-3">
            <Col xs={12}>
                <Card>
                    <Card.Body>
                        <Row className="flex-between-center">
                            <Col md>
                                <h5 className="mb-2 mb-md-0">{title}</h5>
                            </Col>
                            {
                                saveAction && (
                                    <Col xs="auto">
                                        <Button
                                            size="sm"
                                            variant="falcon-default"
                                            className="me-2"
                                            type="button"
                                            onClick={saveAction}
                                        >
                                            {buttonCaption}
                                        </Button>
                                        {
                                            handleShow && (
                                                <Button
                                                    size="sm"
                                                    variant="falcon-default"
                                                    className="me-2"
                                                    type="button"
                                                    onClick={handleShow}
                                                >
                                                    Send Golden Ticket
                                                </Button>
                                            )
                                        }
                                    </Col>
                                )
                            }


                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default saveBar;