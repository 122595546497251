import React, {Fragment, useEffect, useState} from 'react';
import {Form, Card, Col, Row, Button, Modal} from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import moment from 'moment';
import {toast} from 'react-hot-toast';
import {response_error} from '../../../../helpers/functions';
import {useParams} from 'react-router-dom';
import TinymceEditor from "../../../../components/common/TinymceEditor";

const EventForm = () => {
    const [modalShow, setModalShow] = React.useState(false);

    const [value, setValue] = useState(null);
    const default_event = {
        id: '',
        group_id: '',
        event_date: '',
        event_time: '',
        is_valid: 1,
        is_closed: 0,
        event_dates: [],
        reset_attendee_status: 0
    };

    const [event, setEvent] = useState(default_event);
    // const inputChangeHandler = e => setEvent(prevState => ({...prevState, [e.target.name]: e.target.value}));
    const selectChangeHandler = (e, meta) => {
        setEvent(prevState => ({...prevState, [meta.name]: e.value}));
    };

    const multipleDateHandler = e => {
        let dates = e.target.value.split(',');
        dates = dates.map( date => date.trim());
        setEvent(prevState => ({...prevState, [e.target.name]: dates}));
    };

    const handleOnchange = e => {
        setEmailTemplate(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleOnchangeEditor = value => {
        setEmailTemplate(prevState => ({
            ...prevState,
            'body': value
        }))
    }

    const [groups, setGroups] = useState([]);
    const [emailTemplate, setEmailTemplate] = useState({
        subject: '',
        body: ''
    })

    const isValid = [
        {value: 1, label: 'YES'},
        {value: 0, label: 'NO'}
    ];
    const isClosed = [
        {value: 1, label: 'YES'},
        {value: 0, label: 'NO'}
    ];

    const resetAttendeeStatusOptions = [
        {value: 1, label: 'YES'},
        {value: 0, label: 'NO'}
    ];



    const {eventId} = useParams();

    useEffect(() => {
        axios.get('/groups/valid').then(response => {
            let group_options = response.data.groups.map(group => {
                return {value: group.id, label: group.name};
            });

            setGroups(group_options);
        });

        if (eventId > 0) {
            axios.get(`/events/${eventId}`).then(response => {
                let event = response.data.event;
                event.event_dates = [];
                setEvent(event);
            });
        }
    }, []);

    const saveEvent = () => {
        if (event.id > 0) {
            axios
                .put(`/events/${event.id}`, event)
                .then(response => {
                    toast.success(response.data.message);
                    setEvent(default_event);
                })
                .catch(error => {
                    response_error(error);
                });
        } else {
            axios
                .post('/events', event)
                .then(response => {
                    toast.success(response.data.message);
                })
                .catch(error => {
                    response_error(error);
                });
        }
    };

    const get_email_template = () => {
        setModalShow(true);
        axios.get('/email-templates', {params: {page: 1, type: 'event-change-email'}})
            .then(response => {
                setEmailTemplate({
                    subject: response.data.data[0].subject,
                    body: response.data.data[0].body
                })
            })
            .catch(error => {
                response_error(error);
            });
    }

    const book_members = () => {
        axios.post(`/events/${eventId}/book-members`)
            .then(response => {
                toast.success(response.data.message)
            })
            .catch(error => {
                response_error(error);
            });
    }

    const send_email_for_new_event_date = () => {
        axios.post(`/events/${eventId}/send-email`, {
            type: 'event-change-email',
            subject: emailTemplate.subject,
            body: emailTemplate.body
        })
            .then(response => {
                toast.success(response.data.message)
            })
            .catch(error => {
                response_error(error);
            });
    }

    return (
        <Fragment>
            <Row className="g-3">
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <Row className="flex-between-center">
                                <Col md>
                                    <h5 className="mb-2 mb-md-0">Create Event</h5>
                                </Col>
                                <Col xs="auto">
                                    <Button
                                        size="sm"
                                        variant="falcon-default"
                                        className="me-2"
                                        type="button"
                                        onClick={saveEvent}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        size="sm"
                                        variant="falcon-default"
                                        className="me-2"
                                        type="button"
                                        onClick={() => get_email_template()}
                                    >
                                        Send email for new event date
                                    </Button>
                                    <Button
                                        size="sm"
                                        variant="falcon-default"
                                        className="me-2"
                                        type="button"
                                        onClick={() => book_members()}
                                    >
                                        Book Members
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="g-0 mt-3">
                <Col lg={12}>
                    <Row className="g-0">
                        <Col lg={12}>
                            <Card className="h-lg-100">
                                <Card.Header as="h5">Event Info</Card.Header>
                                <div className="border-dashed-bottom"></div>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Group</Form.Label>
                                                <Select
                                                    name="group_id"
                                                    options={groups}
                                                    value={groups.filter(
                                                        group => group.value === event.group_id
                                                    )}
                                                    onChange={selectChangeHandler}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Event Date</Form.Label>
                                                <DatePicker
                                                    name="event_date"
                                                    selected={event.event_date ? Date.parse(event.event_date) : ''}
                                                    onChange={eventTime =>
                                                        setEvent(prevState => ({
                                                            ...prevState,
                                                            event_date: eventTime ? moment(eventTime).format('YYYY-MM-DD') : ''
                                                        }))
                                                    }
                                                    className="form-control"
                                                    placeholderText="yyyy-MM-dd"
                                                    dateFormat="yyyy-MM-dd"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group>
                                                <Form.Label>Event Time</Form.Label>
                                                <DatePicker
                                                    name="event_time"
                                                    value={event.event_time}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={5}

                                                    onChange={eventTime => setEvent(prevState => ({
                                                        ...prevState, event_time: moment(eventTime).format('h:mm a')
                                                    }))}

                                                    className="form-control"
                                                    timeCaption="Time"
                                                    dateFormat="h:mm a"
                                                    placeholderText="h:mm a"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Is Valid</Form.Label>
                                                <Select
                                                    name="is_valid"
                                                    options={isValid}
                                                    value={isValid.filter(
                                                        valid => valid.value === event.is_valid
                                                    )}
                                                    onChange={selectChangeHandler}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Is Closed</Form.Label>
                                                <Select
                                                    name="is_closed"
                                                    options={isClosed}
                                                    value={isClosed.filter(
                                                        closed => closed.value === event.is_closed
                                                    )}
                                                    onChange={selectChangeHandler}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Reset Meeting Status</Form.Label>
                                                <Select
                                                    name="reset_attendee_status"
                                                    options={resetAttendeeStatusOptions}
                                                    value={resetAttendeeStatusOptions.filter(
                                                        option => option.value === event.reset_attendee_status
                                                    )}
                                                    onChange={selectChangeHandler}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Multiple Event date</Form.Label>
                                                <Form.Control
                                                    name="event_dates"
                                                    as="textarea"
                                                    rows={2}
                                                    value={event.event_dates.join(',')}
                                                    onChange={multipleDateHandler}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Send Email</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg={12}>
                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                        Subject
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" name="subject" placeholder="Subject" value={emailTemplate.subject} onChange={handleOnchange}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                        Message
                                    </Form.Label>
                                    <Col sm={10}>
                                        <TinymceEditor
                                            name="body"
                                            handleChange={handleOnchangeEditor}
                                            value={emailTemplate.body}
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col lg={12} className="text-end">
                                <Button variant='falcon-default' className='me-2 mb-1' size="sm" type="button" onClick={send_email_for_new_event_date}>Send</Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
        </Fragment>
    );
};
export default EventForm;
