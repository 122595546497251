import axios from 'axios';
import { RegionContext } from 'context/Context';
import React, { useEffect, useState } from 'react';
import RegionsTable from './RegionsTable';

const Regions = () => {

    const [regions, setRegions] = useState([]);

    const [region, setRegion] = useState({
        name: '',
        is_country_name: '',
        payment_method: '',
        regions: []
    });

    const [modalShow, setModalShow] = useState(false);

    const getRegions = () => {
        axios.get('/regions')
            .then(response => {
                // console.log(response);
                setRegions(response.data.regions);
            })
    }

    useEffect(() => {
        getRegions();
    }, [])


    return (
        <RegionContext.Provider
            value={
                {
                    regions,
                    setRegions,
                    getRegions,
                    region,
                    setRegion,
                    modalShow,
                    setModalShow
                }
            }
        >
            <RegionsTable regions={regions} />
        </RegionContext.Provider>
    )
}

export default Regions;