import React, {Component, useContext, useEffect, useState} from 'react';
import {Form, Card, Col, Row, Button} from 'react-bootstrap';
import Select from 'react-select';
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import {response_error} from "../../../helpers/functions";
import Flex from "../../../components/common/Flex";
import cloudUpload from "../../../assets/img/icons/cloud-upload.svg";
import {useDropzone} from "react-dropzone";
import CardDropdown from "../../../components/common/CardDropdown";
import {getSize} from "../../../helpers/utils";
import { Dropdown } from 'react-bootstrap';
import classNames from "classnames";
import toast from "react-hot-toast";
import {useParams} from "react-router-dom";

const CreateProduct = () => {
    const {
        register,
        handleSubmit,
        watch,
         reset,
        formState: {errors},
        control,
        setValue,
        clearErrors
    } = useForm();

    const {productId} = useParams();
    useEffect(() => {
        if (productId) {
            axios.get(`/products/${productId}`)
                .then(response => {
                    const product = response.data.product;
                    setValue('name', product.name);
                    setValue('sub_title', product.sub_title);
                    setValue('point', product.point);
                    setValue('status', product.status);
                    setValue('description', product.description);
                    setFiles([{path: product.media[0]?.file_name, size: product.media[0]?.size, preview: product.media[0]?.original_url}])
                })
                .catch((error) => {
                    response_error(error);
                });
        }
    }, [productId]);

    const onSubmit = data => {
        data.image = data.uploadedFiles[0];
        if(productId) {
            axios.post(`/products/${productId}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    toast.success(response.data.message);
                })
                .catch((error) => {
                    response_error(error);
                });
            return;
        }
        else{
            axios.post(`/products`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            })
                .then(response => {
                    reset();
                    toast.success(response.data.message);
                })
                .catch((error) => {
                    response_error(error);
                });
        }

    };

    const status = [
        {value: 'published', label: 'Published'},
        {value: 'draft', label: 'Draft'},
        {value: 'inactive', label: 'Inactive'}
    ];

    // Dropzone
    const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        multiple: false,
        maxFiles: 1,
        onDrop: acceptedFiles => {
            if (errors.uploadedFiles) {
                clearErrors('uploadedFiles');
            }
            setValue('uploadedFiles', acceptedFiles);
            setFiles([
                ...files,
                ...acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            ]);
        }
    });

    const handleRemove = path => {
        const filteredFiles = files.filter(file => file.path !== path);
        setFiles(filteredFiles);
        setValue('uploadedFiles', filteredFiles.length ? filteredFiles : null);
    };
    // End Dropzone

    return (
        <Row className="g-0 mb-3">
            <Col lg={12} className="pe-lg-2">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Card className="h-lg-100">
                        <Card.Header as="h5">
                            <Row>
                                <Col lg={8}>Create Product</Col>
                                <Col lg={4} className="text-end">
                                    <Button
                                        variant="falcon-default"
                                        size="sm"
                                        className="me-2"
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <div className="border-dashed-bottom"></div>
                        <Card.Body className="bg-light">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            placeholder="Name"
                                            name="name"
                                            type="text"
                                            isInvalid={!!errors.name}
                                            {...register('name', {
                                                required: 'Name field is required'
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name && errors.name.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Sub Title</Form.Label>
                                        <Form.Control
                                            placeholder="Sub Title"
                                            name="sub_title"
                                            type="text"
                                            isInvalid={!!errors.sub_title}
                                            {...register('sub_title', {
                                                required: 'Sub Title field is required'
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.sub_title && errors.sub_title.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Point</Form.Label>
                                        <Form.Control
                                            placeholder="Point"
                                            name="point"
                                            type="text"
                                            isInvalid={!!errors.point}
                                            {...register('point', {
                                                required: 'Point field is required'
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.point && errors.point.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Status</Form.Label>
                                        <Controller
                                            name="status"
                                            control={control}
                                            render={({field}) => (
                                                <Select
                                                    {...field}
                                                    classNamePrefix="react-select"
                                                    className={errors.status ? 'is-invalid' : ''}
                                                    options={status}
                                                    value={status.find(c => c.value === field.value)}
                                                    onChange={e => field.onChange(e.value)}
                                                    ref={field.ref}
                                                />
                                            )}
                                            rules={{required: 'Status is required'}}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.status && errors.status.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            placeholder="Description"
                                            as="textarea" rows={3}
                                            name="description"
                                            isInvalid={!!errors.description}
                                            {...register('description', {
                                                required: 'Description field is required'
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.description && errors.description.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>Product Image</Form.Label>
                                        <div
                                            {...getRootProps({
                                                className: `${classNames('dropzone-area py-6', {
                                                    'is-invalid': errors.uploadedFiles
                                                })}`
                                            })}
                                        >
                                            <input name="uploadedFiles" {...getInputProps()}/>
                                            <Flex justifyContent="center">
                                                <img src={cloudUpload} alt="" width={25} className="me-2"/>
                                                <p className="fs-9 mb-0 text-700">Drop your images here</p>
                                            </Flex>
                                        </div>
                                        <div className="invalid-feedback">{errors.uploadedFiles?.message}</div>
                                        <div>
                                            {files.map(file => (
                                                <Flex
                                                    alignItems="center"
                                                    className="py-3 border-bottom btn-reveal-trigger"
                                                    key={file.path}
                                                >
                                                    <img
                                                        className="rounded"
                                                        width={40}
                                                        height={40}
                                                        src={file.preview}
                                                        alt={file.path}
                                                    />

                                                    <Flex
                                                        justifyContent="between"
                                                        alignItems="center"
                                                        className="ms-3 flex-1"
                                                    >
                                                        <div>
                                                            <h6>{file.path}</h6>
                                                            <Flex className="position-relative" alignItems="center">
                                                                <p className="mb-0 fs-10 text-400 line-height-1">
                                                                    <strong>{getSize(file.size)}</strong>
                                                                </p>
                                                            </Flex>
                                                        </div>
                                                    </Flex>
                                                    <CardDropdown>
                                                        <div className="py-2">
                                                            <Dropdown.Item
                                                                className="text-danger"
                                                                onClick={() => handleRemove(file.path)}
                                                            >
                                                                Remove
                                                            </Dropdown.Item>
                                                        </div>
                                                    </CardDropdown>
                                                </Flex>
                                            ))}
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Form>
            </Col>
        </Row>
    );
};

export default CreateProduct;