import React, {Component, useContext, useState} from 'react';
import {Button, Card, Col, Container, Form, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import PaymentMethod from "../../components/app/e-commerce/checkout/PaymentMethod";
import Header from "./Header";
import {ProductContext} from "../../context/Context";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import cards from "../../assets/img/icons/icon-payment-methods-grid.png";
import paypal from "../../assets/img/icons/icon-paypal-full.png";
import shield from "../../assets/img/icons/shield.png";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";


const stripePromise = loadStripe('pk_test_51IWk2vEFIthhNSspFY9Y4qQawoEZeEezjpeld5Qr9s4e7zxn37ybKlcB3Dx9h1DJSFZrzDcc8eXhlwCab2hqPsU900WSmeM67H');

const Payment = () => {
    let payableTotal;

    const [searchParam, setSearchParam] = useSearchParams();

    const options = {
        // passing the client secret obtained from the server
        clientSecret: searchParam.get('client_secret'),
    };

    const [method, setMethod] = useState('credit-card');
    const { productsDispatch } = useContext(ProductContext);
    const { register, handleSubmit } = useForm();

    const onSubmit = data => {
        productsDispatch({
            type: 'CHECKOUT'
        });
        toast(
            <div className="text-700">
                <h5 className="text-success fs-0 mb-0">Payment success!</h5>
                <hr className="my-2" />
                Total: <strong>${payableTotal}</strong>
                <br />
                Payment method:{' '}
                <strong className="text-capitalize">
                    {method.split('-').join(' ')}
                </strong>
            </div>
        );
        method === 'credit-card'
            ? console.log(data)
            : console.log('Calling paypal api');
    };

    return (
        <>
             <Header/>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <Card>
                            <Card.Header className="bg-light">
                                <h5 className="mb-0">Payment Method</h5>
                            </Card.Header>
                            <Card.Body>
                                <Elements stripe={stripePromise} options={options}>
                                    <CheckoutForm />
                                </Elements>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Payment;