import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import {next_icon, previous_icon, response_error} from "../../../helpers/functions";
import axios from "axios";
import {useSearchParams} from "react-router-dom";
import {format} from "date-fns";

const MyPoints = () => {
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [points, setPoints] = useState([]);

    const memberId = useSearchParams()

    const handlePageClick = page => {
        setPage(page.selected + 1);
    }
    const getWarningClass = (value) => {
        const isNegative = value < 0;
        return isNegative ? 'py-2 text-danger' : 'py-2';
    };

    const getPoints = () => {
        axios.get(`/member/points?page=${page}`)
            .then(response => {
                setPoints(response.data.data);
                // Pagination
                let total_page = parseInt(response.data.total) / parseInt(response.data.per_page);
                total_page = total_page > 0 ? Math.ceil(total_page) : 0;
                setPageCount(total_page);
                // End Pagination
            })
            .catch((err) => {
                response_error(err);
            })
    }
    useEffect(() => {
        getPoints();
    }, [page]);


    return (
        <Row className="g-0 mb-3">
            <Col lg={12} className="pe-lg-2">
                <Card className="h-lg-100">
                     <Card.Header className="pb-0">
                        <h5 className="fs-0 mb-3 text-nowrap py-2 py-xl-0">My Points</h5>
                    </Card.Header>
                    <Card.Body className="pt-0">
                        <div className="table-responsive">
                        <table className="fs--1 mb-0 table table-sm table-striped overflow-hidden" role="table">
                            <thead className="bg-200 text-900 text-nowrap align-middle">
                            <tr>
                                <th>Points</th>
                                <th>Description</th>
                                <th>Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                points.map((point, index) => (
                                    <tr className="align-middle white-space-nowrap" role="row" key={index}>
                                        <td role="cell" className={getWarningClass(point.point)}>{point.point}</td>
                                        <td role="cell" className="py-2">{point.note}</td>
                                        <td role="cell" className="py-2">{format(new Date(point.created_at), 'yyyy-MM-dd')}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                            </div>
                    </Card.Body>
                    <Card.Footer>
                        <ReactPaginate
                            previousLabel={previous_icon()}
                            nextLabel={next_icon()}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination mb-0 mx-1'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    );
};

export default MyPoints;

