import React, { useEffect, useState} from 'react';
import { Card, Col, Row, Button} from 'react-bootstrap';
import Confirm from "../../../components/custom/confirm";
import axios from "axios";
import {response_error} from "../../../helpers/functions";
import toast from "react-hot-toast";


const RedeemPoints = () => {
    const [products, setProducts] = useState([]);
    const [orderConfirmationShow, setOrderConfirmationShow] = useState(false);
    const [productId, setProductId] = useState(null)

    const showConfirmation = (id) => {
        setProductId(id)
        setOrderConfirmationShow(true)
    };
    const handleCreateOrder = () => {
        axios.post(`/member/orders`, {product_ids: [productId]})
            .then(response => {
                toast.success(response.data.message);
                setOrderConfirmationShow(false)
                setProductId(0)
            })
            .catch(error => {
                response_error(error);
            });
    }
    const product = products.find(product => product.id == productId);
    const points = product ? product.point : '';
    const message = <span>It will take <strong className="text-dark">{points}</strong> from your account</span>;

    const getProducts = () => {
        axios.get(`/member/products`)
            .then(response => {
                setProducts(response.data.data);
            })
            .catch(error => {
                response_error(error);
            });
    }
    useEffect(() => {
        getProducts();
    }, []);
    return (
        <Row className="g-0 mb-3">
            <Col lg={12} className="pe-lg-2">
                <Card className="h-lg-100">
                    <Card.Header className="pb-0">
                        <h5 className="fs-0 mb-3 text-nowrap py-2 py-xl-0">Redeem Points</h5>
                    </Card.Header>
                    <Card.Body className="pt-0">
                        <Confirm
                            body={message}
                            title="Do you want to purchase this product?"
                            show={orderConfirmationShow}
                            setShow={setOrderConfirmationShow}
                            handleConfirm={handleCreateOrder}
                        />
                        <Row>
                            {
                                products.map((product, index) => (

                                    <Col lg={3} className="mb-3" key={index}>
                                        <Card>
                                            <Card.Img src={product.media[0]?.original_url} variant='top'/>
                                            <Card.Body>
                                                <Card.Title as='h5' className="text-1000">
                                                    {product.sub_title}
                                                </Card.Title>
                                                <Card.Text>
                                                    {product.description}
                                                </Card.Text>
                                                <Row className="mb-3">
                                                    <Col lg={12}>
                                                        <span
                                                            className="text-dark"><b>Points :</b></span> {product.point}
                                                    </Col>
                                                </Row>
                                                <div className="text-center">
                                                    <Button color="primary" size="sm"
                                                            onClick={() => showConfirmation(product.id)}>
                                                        Redeem Now
                                                    </Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default RedeemPoints;