import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import {logout} from "../../../helpers/functions";
import './ProfileName.css';
import {AuthWizardContext} from "../../../context/Context";

const ProfileDropdown = () => {
    const {user, isLoading} = useContext(AuthWizardContext);
    return (
        <Dropdown navbar={true} as="li">
            <Dropdown.Toggle
                bsPrefix="toggle"
                as={Link}
                to="#!"
                className="pe-0 ps-2 nav-link"
            >
                {
                    !isLoading && (
                        user.media && user.media.length > 0 ? (
                            <Avatar src={user.media[0].original_url}/>
                        ) : (
                            <div className="UserName">{user.first_name ? user.first_name.slice(0,2) : ''}</div>
                        )

                    )
                }

            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
                <div className="bg-white rounded-2 py-2 dark__bg-1000">
                    {/*<Dropdown.Item className="fw-bold text-warning" href="#!">*/}
                    {/*  <FontAwesomeIcon icon="crown" className="me-1" />*/}
                    {/*  <span>Go Pro</span>*/}
                    {/*</Dropdown.Item>*/}
                    {/*<Dropdown.Divider />*/}
                    {/*<Dropdown.Item href="#!">Set status</Dropdown.Item>*/}
                    <Dropdown.Item as={Link} to="/profile">
                        Profile
                    </Dropdown.Item>
                    {/*<Dropdown.Item href="#!">Feedback</Dropdown.Item>*/}
                    {/*<Dropdown.Divider />*/}
                    {/*<Dropdown.Item as={Link} to="/user/settings">*/}
                    {/*  Settings*/}
                    {/*</Dropdown.Item>*/}
                    <Dropdown.Item onClick={logout}>
                        Log out
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ProfileDropdown;
