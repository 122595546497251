import React, {Component, useEffect, useState} from 'react';
import {Form, Card, Col, Row, Button} from 'react-bootstrap';
import Select from 'react-select';
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import {response_error} from "../../../helpers/functions";
import {profession} from 'data/memberInfoData/profession';
import {shirtSizes} from 'data/memberInfoData/shirtSizes';
import {country} from 'data/memberInfoData/country';
import {toast} from "react-hot-toast";
import MemberProfileImageView from "./MemberProfileImageView";
import MemberActivitiesLog from "./MemberActivitiesLog";

const MemberPersonalInfo = () => {
    const [defaultValues, setDefaultValues] = useState({});
    const [member, setMember] = useState({});

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: {errors},
        control

    } = useForm({values: defaultValues});

    const [formData, setData] = useState({});
    const onSubmit = data => {
        let address = [];
        if(data.billing){
            data.billing.type = 'billing';
            address.push(data.billing);
        }
        if(data.shipping){
            data.shipping.type = 'shipping';
            address.push(data.shipping);
        }
        data.addresses = address;

        axios.put('/member', data)
            .then((response) => {
                toast.success(response.data.message);
            })
            .catch((error) => {
                response_error(error);
            });
    };
    const getProfile =  () => {
         axios.get('/member')
            .then((response) => {
                setMember(response.data);
                response.data.shipping = response.data.addresses.filter((address) => address.type === 'shipping').shift();
                response.data.billing = response.data.addresses.filter((address) => address.type === 'billing').shift();
                setDefaultValues(response.data)
            })
            .catch((error) => {
                response_error(error);
            });
    }

    useEffect(() => {
         getProfile();
    }, []);

    const MemberBillingAddressView = (
        <Row className="g-0 mb-3">
            <Col lg={12} className="pe-lg-2">
                <Card className="h-lg-100">
                    <Card.Header as="h5">Billing Address</Card.Header>
                    <div className="border-dashed-bottom"></div>
                    <Card.Body className="bg-light">
                         <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Address 1</Form.Label>
                                    <Form.Control
                                        placeholder="Address 1"
                                        name="billing[address1]"
                                        type="text"
                                        isInvalid={!!errors.billing?.address1}
                                        {...register('billing[address1]', {
                                            required: 'Address is required'
                                        })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.billing?.address1 && errors?.billing?.address1?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Address 2</Form.Label>
                                    <Form.Control
                                        placeholder="Address 2"
                                        name="billing[address2]"
                                        type="text"
                                        {...register('billing[address2]')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        placeholder="city"
                                        name="billing[city]"
                                        type="text"
                                        isInvalid={!!errors.billing?.city}
                                        {...register('billing[city]', {
                                            required: 'City is required'
                                        })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.billing?.city && errors?.billing?.city?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>County / State</Form.Label>
                                    <Form.Control
                                        placeholder="county"
                                        name='billing[state]'
                                        type="text"
                                        isInvalid={!!errors.billing?.state}
                                        {...register('billing[state]', {
                                            required: 'County is required'
                                        })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.billing?.state && errors?.billing?.state?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Postal Code</Form.Label>
                                    <Form.Control
                                        placeholder="Postal Code"
                                        name="billing[postal_code]"
                                        type="text"
                                        isInvalid={!!errors.billing?.postal_code}
                                        {...register('billing[postal_code]', {
                                            required: 'Postal code is required'
                                        })}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                         {errors.billing?.postal_code && errors?.billing?.postal_code?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Country</Form.Label>
                                    <Controller
                                        name="billing[country]"
                                        control={control}
                                        render={({field, ref}) => (
                                            <Select
                                                {...field}
                                                classNamePrefix="react-select"
                                                className={!!errors.billing?.country ? 'is-invalid' : ''}
                                                options={country}
                                                value={country.find((c) => c.value === field.value)}
                                                onChange={(e) => field.onChange(e.value)}
                                                ref={ref}

                                            />
                                        )}
                                        rules={{required: true, message: 'Country is required'}}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.billing?.country && errors?.billing?.country?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );

    const MemberShippingAddressView = (
        <Row className="g-0 mb-3">
            <Col lg={12} className="pe-lg-2">
                <Card className="h-lg-100">
                    <Card.Header as="h5">Shipping Address</Card.Header>
                    <div className="border-dashed-bottom"></div>
                    <Card.Body className="bg-light">
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Address 1</Form.Label>
                                    <Form.Control
                                        placeholder="Address 1"
                                        name="shipping[address1]"
                                        type="text"
                                        isInvalid={!!errors.shipping?.address1}
                                        {...register('shipping[address1]')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.shipping?.address1 && errors?.shipping?.address1?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Address 2</Form.Label>
                                    <Form.Control
                                        placeholder="Address 2"
                                        name="shipping[address2]"
                                        type="text"
                                        {...register('shipping[address2]')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        placeholder="city"
                                        name="shipping[city]"
                                        type="text"
                                        {...register('shipping[city]')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>County / State</Form.Label>
                                    <Form.Control
                                        placeholder="county"
                                        name="shipping[state]"
                                        type="text"
                                        {...register('shipping[state]')}
                                    />

                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Postal Code</Form.Label>
                                    <Form.Control
                                        placeholder="Postal Code"
                                        name="shipping[postal_code]"
                                        type="text"
                                        {...register('shipping[postal_code]')}
                                    />

                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Country</Form.Label>
                                    <Controller
                                        name="shipping[country]"
                                        control={control}
                                        render={({field, ref}) => (
                                            <Select
                                                {...field}
                                                classNamePrefix="react-select"
                                                options={country}
                                                value={country.find((c) => c.value === field.value)}
                                                onChange={(e) => field.onChange(e.value)}
                                                ref={ref}

                                            />
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );

    const profileForm = (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-0 mb-3">
                <Col lg={12} className="pe-lg-2">
                    <Card className="h-lg-100">
                        <Card.Header as="h5">
                            <Row>
                                <Col xs={6}>
                                    Personal Info
                                </Col>
                                <Col xs={6}>
                                    <div className="text-end">
                                        <Button
                                            variant="primary"
                                            className="me-2"
                                            type="submit"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <div className="border-dashed-bottom"></div>
                        <Card.Body className="bg-light">
                            <Row>
                                <Col lg={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Gender</Form.Label>
                                        <div className="d-block">
                                            <Form.Check
                                                inline
                                                type='radio'
                                                id='male'
                                                label='Male'
                                                name='gender'
                                                value='male'
                                                isInvalid={!!errors.gender}
                                                {...register('gender', {
                                                    required: 'Please Select Gender'
                                                })}
                                            />
                                            <Form.Check
                                                inline
                                                type='radio'
                                                id='female'
                                                label='Female'
                                                name='gender'
                                                value='female'
                                                isInvalid={!!errors.gender}
                                                {...register('gender', {
                                                    required: 'Please Select Gender'
                                                })}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="first name"
                                            name="first_name"
                                            isInvalid={!!errors.first_name}
                                            {...register('first_name', {
                                                required: 'First name is required'
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.first_name && errors.first_name.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            placeholder="last name"
                                            name="last_name"
                                            type="text"
                                            isInvalid={!!errors.last_name}
                                            {...register('last_name', {
                                                required: 'Last name is required'
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.last_name && errors.last_name.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Company Name</Form.Label>
                                        <Form.Control
                                            placeholder="Company name"
                                            name="company"
                                            type="text"
                                            isInvalid={!!errors.company}
                                            {...register('company', {
                                                required: 'Company name is required'
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.company && errors.company.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            placeholder="Email Address"
                                            name="email"
                                            type="text"
                                            isInvalid={!!errors.email}
                                            {...register('email', {
                                                required: 'Email name is required'
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email && errors.email.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Vpar Email</Form.Label>
                                        <Form.Control
                                            placeholder="Vpar Email Address"
                                            name="alternative_email"
                                            type="text"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <Form.Control
                                            placeholder="Mobile Number"
                                            name="phone1"
                                            type="text"
                                            isInvalid={!!errors.phone1}
                                            {...register('phone1', {
                                                required: 'Phone number is required'
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.phone1 && errors.phone1.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Landline Number</Form.Label>
                                        <Form.Control
                                            placeholder="Landline Number"
                                            name="phone2"
                                            type="text"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Profession</Form.Label>
                                        <Controller
                                            name="profession"
                                            control={control}
                                            render={({field, ref}) => (
                                                <Select
                                                    {...field}
                                                    classNamePrefix="react-select"
                                                    className={!!errors.country ? 'is-invalid' : ''}
                                                    options={profession}
                                                    value={profession.find((c) => c.value === field.value)}
                                                    onChange={(e) => field.onChange(e.value)}
                                                    ref={ref}

                                                />
                                            )}
                                            rules={{ required: true, message: 'Profession is required'}}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.profession && errors.profession.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Job Title</Form.Label>
                                        <Form.Control
                                            placeholder="Job Title"
                                            name="job_title"
                                            type="text"
                                            isInvalid={!!errors.job_title}
                                            {...register('job_title', {
                                                required: 'Job Title is required'
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.job_title && errors.job_title.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Shirt Size</Form.Label>
                                        <Controller
                                            name="shirt_size"
                                            control={control}
                                            render={({field, ref}) => (
                                                <Select
                                                    {...field}
                                                    classNamePrefix="react-select"
                                                    className={!!errors.country ? 'is-invalid' : ''}
                                                    options={shirtSizes}
                                                    value={shirtSizes.find((c) => c.value === field.value)}
                                                    onChange={(e) => field.onChange(e.value)}
                                                    ref={ref}

                                                />
                                            )}
                                            rules={{ required: true, message: 'Shirt Size is required'}}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.shirt_size && errors.shirt_size.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Linkedin Profile</Form.Label>
                                        <Form.Control
                                            placeholder="Linkedin Profile"
                                            name="linkedin"
                                            type="text"

                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Facebook Profile</Form.Label>
                                        <Form.Control
                                            placeholder="Facebook Profile"
                                            name="facebook"
                                            type="text"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Handicap</Form.Label>
                                        <Form.Control
                                            placeholder="Handicap"
                                            name="handicap"
                                            type="text"
                                            isInvalid={!!errors.handicap}
                                            {...register('handicap', {
                                                required: 'Handicap is required'
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.handicap && errors.handicap.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Referring Member</Form.Label>
                                        <Form.Control
                                            placeholder="Referring Member"
                                            name="handicap"
                                            type="text"
                                            readOnly={true}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>About Your Business</Form.Label>
                                        <Form.Control as="textarea"
                                                      name="details_of_business"
                                                      rows={3}
                                                      isInvalid={!!errors.details_of_business}
                                                      {...register('details_of_business', {
                                                          required: 'Details of your business is required',
                                                          minLength: {value: 100, message: 'Minimum 100 characters'},
                                                          maxLength: {value: 1000, message: 'Maximum 500 characters'}
                                                      })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.details_of_business && errors.details_of_business.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {MemberBillingAddressView}
            {MemberShippingAddressView}
        </Form>
    )


    return (
        <>
            <Row className="g-0 mt-3">
                <Col lg={8} className="pe-lg-2">
                    {profileForm}
                </Col>
                <Col lg={4}>
                    <MemberProfileImageView/>
                    <MemberActivitiesLog member={member}/>
                </Col>
            </Row>
        </>
    );
}

export default MemberPersonalInfo;