import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Nav, Row, Tab, Table} from 'react-bootstrap';
import JSONView from "react-json-view";
import axios from "axios";
import {response_error} from "../../../../helpers/functions";
import {MemberContext} from "../../../../context/Context";

const obj1 = `{
    "objectType": "list 1",
    "uri": "",
    "data": [
        {
        "id": "57dafe43317467e7b0ef5276",
        "projectId": "54c17e92e4b0bc49e27d9398",
        "number": 5,
        "startingUserId": "57a28b28961401f240812caf",
        "queued": 1473969731915,
        "started": 1473969754283,
        "completed": 1473969762970,
        "status": "Succeeded",
        "commitId": "1c6e8aa47951e39f9a905f0077af9355c35b712b",
        "executor": "us-west-2:i-007942020ce7baeb5",
        "outputCommitId": "73fe9481fe635bada713246c3c739beefa424e8c",
        "title": "Run with great R^2 of 1!",
        "isArchived": false,
        "postProcessedTimestamp": 1473969767822,
        "diagnosticStatistics": {
            "isError": false,
            "data": [
            {
                "key": "R^2",
                "value": "1.000"
            }
            ]
        },
        "isCompleted": true,
        "hardwareTierId": "gpu"
        }
    ]
}`;
const obj2 = `{
    "objectType": "Heading Two",
    "uri": "",
    "data": [
        {
        "id": "57dafe43317467e7b0ef5276",
        "projectId": "54c17e92e4b0bc49e27d9398",
        "number": 5,
        "startingUserId": "57a28b28961401f240812caf",
        "queued": 1473969731915,
        "started": 1473969754283,
        "completed": 1473969762970,
        "status": "Succeeded",
        "commitId": "1c6e8aa47951e39f9a905f0077af9355c35b712b",
        "executor": "us-west-2:i-007942020ce7baeb5",
        "outputCommitId": "73fe9481fe635bada713246c3c739beefa424e8c",
        "title": "Run with great R^2 of 1!",
        "isArchived": false,
        "postProcessedTimestamp": 1473969767822,
        "diagnosticStatistics": {
            "isError": false,
            "data": [
            {
                "key": "R^2",
                "value": "1.000"
            }
            ]
        },
        "isCompleted": true,
        "hardwareTierId": "gpu"
        }
    ]
}`;

const contentData = [obj1, obj2];

const MemberActivitiesLog = () => {
    const [clicked, setClicked] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [selectedRowIndex, setSelectedRowIndex] = useState(0);

    const [activities, setActivities] = useState([]);
    const [params, setParams] = useState({
        models: [],
        causer_id: null,
        date: null
    });

    const { memberId } = useContext(MemberContext);

    const handleRowClick = (index) => {
        activities.map((activity) => {
            if (activity.id === index) {
                setSelectedRow(JSON.stringify(activity.properties));
            }
        });

        // setSelectedRow(contentData[index]);
        setSelectedRowIndex(index);
    };

    const rowData = [
        {name: 'Updated Item 1', date: '00', time: '7:30 am', updatedBy: 'Rayhan'},
        {name: 'Updated Item 2', date: '2023-10-10', time: '7:30 am', updatedBy: 'Tayhan'},
        // Add more rows as needed
    ];

    const load_activity_log = () => {
        setClicked(true);

        axios.get('/activities/member/' +memberId, {
            params: params
        })
            .then(res => {
                setActivities(res.data.data);
            })
            .catch(error => {
                response_error(error);
            });

    }

    return (
        <Fragment>
            <Row className="g-0 mt-3">
                <Col lg={12}>
                    <Row className="g-0">
                        <Col lg={12}>
                            <Card className="h-lg-100">
                                <Card.Header as="h5">Member Activities Log</Card.Header>
                                {/*<div className="border-dashed-bottom"></div>*/}
                                <Card.Body>
                                    {!clicked && (
                                    <Row>
                                        <Col sm={12}>

                                                <Button variant="falcon-default" onClick={load_activity_log}>Load Activity Log</Button>

                                        </Col>
                                    </Row>
                                    )}
                                    {clicked && (

                                    <Row>
                                        <Col sm={5}>
                                            <Table borderless hover
                                                   className="fs--2 mb-0 overflow-hidden table table-sm table-striped"
                                                   role="table">
                                                <thead className="bg-200 text-900 text-nowrap align-middle">
                                                <tr>
                                                    <th>Updated Item Name</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Updated By</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {activities && activities.map((activity) => (
                                                    <tr
                                                        key={activity.id}
                                                        onClick={() => handleRowClick(activity.id)}
                                                        className={selectedRowIndex === activity.id ? 'table-primary' : ''}
                                                        style={{cursor: 'pointer'}}
                                                    >
                                                        <td role="cell">{activity.subject_type}</td>
                                                        <td role="cell">{activity.description}</td>
                                                        <td role="cell">{activity.updated_at}</td>
                                                        <td role="cell">{activity.causer.first_name} {activity.causer.last_name}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col sm={7}>
                                            {selectedRow && (
                                                <Card.Body className="pt-0">
                                                    <JSONView
                                                        src={JSON.parse(selectedRow)}
                                                        theme="monokai"
                                                    />
                                                </Card.Body>
                                            )}
                                        </Col>
                                    </Row>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Fragment>
    );
}

export default MemberActivitiesLog;