import React, {Component, useContext, useState} from 'react';
import {Form, Card, Col, Row, Dropdown, Button} from 'react-bootstrap';
import CardDropdown from "../../../components/common/CardDropdown";
import SoftBadge from "../../../components/common/SoftBadge";
import ReactPaginate from "react-paginate";
import {next_icon, previous_icon} from "../../../helpers/functions";
import {Link} from "react-router-dom";
import axios from "axios";
import moment from "moment";

const GoldenTicketShared = () => {
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [goldenTickes, setGoldenTickets] = useState([]);


    const handlePageClick = page => {
        setPage(page.selected + 1);
    }

    React.useEffect(() => {
        getGoldenTicket();
    }, [page]);

    const getGoldenTicket = () => {
        axios.get(`/member/reports/golden-tickets?page=${page}`)
            .then(response => {
                console.log(response.data);
                setGoldenTickets(response.data.data);
                setPageCount(response.data.last_page);
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <Row className="g-0 mb-3">
            <Col lg={12} className="pe-lg-2">
                <Card className="h-lg-100">
                    <Card.Header as="h5">
                        <Row>
                            <Col lg={8}>Golden Tickets</Col>
                            <Col lg={4} className="text-end">
                                <Button
                                    variant="falcon-default"
                                    size="sm"
                                    className="me-2"
                                    type="button"
                                >
                                    <Link to="https://www.fore-business.com/member-books-a-guest" target="_blank">Refer
                                        a New Member</Link>
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-0">
                        <div className="table-responsive">
                            {
                                goldenTickes.length === 0 && (
                                    <div className="text-center">No Golden Ticket Found</div>
                                )
                            }
                            {
                                goldenTickes.length > 0 && (
                                    <table className="fs--1 mb-0 table table-sm table-striped overflow-hidden" role="table">
                                        <thead className="bg-200 text-900 text-nowrap align-middle">
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Group</th>
                                            <th className="text-center">Membership Status</th>
                                            <th>Status</th>
                                            <th>1st Meeting Date</th>
                                            <th>Point Received Date</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            goldenTickes.map((goldenTicket, index) => {

                                                let status = "Golden Ticket Sent";
                                                if(goldenTicket?.last_new_joiner?.credited) {
                                                    status = goldenTicket.last_new_joiner.point + " Points Awarded";
                                                }
                                                else if(goldenTicket?.last_new_joiner?.paid_at) {
                                                    status = "50 Points Pending";
                                                }
                                                else if(goldenTicket?.last_new_joiner?.cardless_mandate) {
                                                    status = "GT Redeemed";
                                                }


                                                return (
                                                    <tr className="align-middle white-space-nowrap" role="row"
                                                        key={index}>
                                                        <td role="cell">{goldenTicket.first_name + ' ' + goldenTicket.last_name}</td>
                                                        <td role="cell">{goldenTicket.email}</td>
                                                        <td role="cell"
                                                            className="py-2">{goldenTicket.groups.map(group => group.name).join(', ')}</td>
                                                        <td role="cell" className="fs-0 text-center">
                                                            <SoftBadge pill
                                                                       bg={goldenTicket.status === 'active' ? 'success' : 'danger'}>{goldenTicket.status}</SoftBadge>
                                                        </td>
                                                        <td>{status}</td>
                                                        <td>{goldenTicket.joined_date ? moment(goldenTicket.joined_date, 'YYYY-MM-DD').format('ll') : ''}</td>
                                                        <td>{goldenTicket.joined_date ? moment(goldenTicket.joined_date, 'YYYY-MM-DD').add(4, 'months').format('ll') : ''}</td>
                                                    </tr>
                                                )
                                                }
                                            )
                                        }

                                        </tbody>
                                    </table>
                                )
                            }

                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <ReactPaginate
                            previousLabel={previous_icon()}
                            nextLabel={next_icon()}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination mb-0 mx-1'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    );
};

export default GoldenTicketShared;
