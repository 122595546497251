import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import {Button, Col, Form, Row} from "react-bootstrap";
import shield from "../../assets/img/icons/shield.png";
import {Link, useSearchParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

const CheckoutForm = () => {
    let payableTotal = 0;
    const stripe = useStripe();
    const elements = useElements();

    const [searchParam, setSearchParam] = useSearchParams();

    if (searchParam.has('payable_amount')) {
        payableTotal = searchParam.get('payable_amount');
    }

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: "/payment-success",
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement/>

            <div className="border-dashed-bottom my-5"></div>
            <Row>
                <Col
                    md={7}
                    xl={12}
                    xxl={7}
                    className="px-md-3 mb-xxl-0 position-relative"
                >
                    <div className="d-flex">
                        <img
                            src={shield}
                            alt="protection"
                            width="60"
                            height="60"
                            className="me-3"
                        />
                        <div className="flex-1">
                            <h5 className="mb-2">Buyer Protection</h5>
                            <Form.Check id="full-refund" className="mb-0 form-check">
                                <Form.Check.Input
                                    className="mb-0"
                                    type="checkbox"
                                    defaultChecked
                                />
                                <Form.Check.Label className="mb-0">
                                    <strong>Full Refund</strong> If you don't{' '}
                                    <br className="d-none d-md-block d-lg-none"/> receive your
                                    order
                                </Form.Check.Label>
                            </Form.Check>
                            <Form.Check id="partial-refund" className="form-check">
                                <Form.Check.Input
                                    className="mb-0"
                                    type="checkbox"
                                    defaultChecked
                                />
                                <Form.Check.Label className="mb-0">
                                    <strong>Full or Partial Refund,</strong> If the product is
                                    not as described in details
                                </Form.Check.Label>
                            </Form.Check>
                            <Link to="#!" className="fs--1 ms-3 ps-2">
                                Learn More
                                <FontAwesomeIcon
                                    icon="caret-right"
                                    className="ms-1"
                                    transform="down-2"
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="vertical-line d-none d-md-block d-xl-none d-xxl-block"></div>
                </Col>
                <Col
                    md={5}
                    xl={12}
                    xxl={5}
                    className="ps-xxl-5 text-center text-md-start text-xl-center text-xxl-start"
                >
                    <div className="border-dashed-bottom d-block d-md-none d-xl-block d-xxl-none my-4"></div>
                    <div className="fs-2 fw-semi-bold">
                        All Total: <span className="text-primary">${payableTotal}</span>
                    </div>
                    <Button
                        variant="success"
                        className="mt-3 px-5"
                        type="submit"
                        disabled={!stripe}
                    >
                        Confirm &amp; Pay
                    </Button>
                    <p className="fs--1 mt-3 mb-0">
                        By clicking <strong>Confirm &amp; Pay </strong> button you agree
                        to the <Link to="#!">Terms &amp; Conditions</Link>
                    </p>
                </Col>
            </Row>
        </form>
    )
};

export default CheckoutForm;