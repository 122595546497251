import React, {Component, useContext, useEffect, useState} from 'react';
import {Form, Card, Col, Row, Dropdown, Button, ListGroup} from 'react-bootstrap';
import Select from 'react-select';
import CardDropdown from "../../../components/common/CardDropdown";
import SoftBadge from "../../../components/common/SoftBadge";
import ReactPaginate from "react-paginate";
import {next_icon, previous_icon, response_error} from "../../../helpers/functions";
import DatePicker from "react-datepicker";
import axios from "axios";
import Confirm from "../../../components/custom/confirm";
import {format} from "date-fns";
import toast from "react-hot-toast";

const Orders = () => {
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [orders, setOrders] = useState([]);
    const [orderId, setOrderId] = useState(null);
    const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);
    const [productOptions, setProductOptions] = useState([]);
    const [param, setParam] = useState({
        product_id: '',
        email: '',
        status: '',
        order_date_start: '',
        order_date_end: ''
    });

    const showConfirmation = (id) => {
        setOrderId(id)
        setDeleteConfirmationShow(true)
    };

    const handlePageClick = page => {
        setPage(page.selected + 1);
    }
    const date = new Date();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const dateOnChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setParam({
            ...param,
            order_date_start: start ? format(new Date(start), 'yyyy-MM-dd') : '',
            order_date_end: start ? format(new Date(end), 'yyyy-MM-dd') : ''
        })
    };

    const statusOption = [
    { value: 'completed', label: 'Completed' },
    { value: 'pending', label: 'Pending' },
    { value: 'cancelled', label: 'Cancelled' },
  ];

    useEffect(() => {
        axios.get('/products')
            .then(response => {
                setProductOptions(response.data.products.data.map(product => ({
                    value: product.id,
                    label: product.name
                })));
            })
            .catch(error => {
                response_error(error);
            });
    }, []);
 const handleDeleteOrder = () => {
        axios.delete(`/orders/${orderId}`)
            .then(response => {
                toast.success(response.data.message);
                setDeleteConfirmationShow(false)
                setOrderId(0)
            })
            .catch(error => {
                response_error(error);
            });
    }

    const getOrders = () => {
        axios.get(`/orders`, {params: param})
            .then(response => {
                setOrders(response.data.orders.data);
                // Pagination
                let total_page = parseInt(response.data.orders.total) / parseInt(response.data.orders.per_page);
                total_page = total_page > 0 ? Math.ceil(total_page) : 0;
                setPageCount(total_page);
                // End Pagination
            })
            .catch(error => {
                response_error(error);
            });
    }

    useEffect(() => {
        getOrders();
    }, [param, page]);

    const OrderFilter = (
        <Row lassName="mb-3" style={{alignItems: "baseline"}}>
            <Col lg={12}>
                <Form.Group
                    as={Row}
                    className="mb-3"
                >
                    <Col lg="2">
                        <Select
                            className="mb-2"
                            closeMenuOnSelect={true}
                            options={productOptions}
                            placeholder='Product Name'
                            onChange={(selectedOption) => {
                                setParam({
                                    ...param,
                                    product_id: selectedOption ? selectedOption.value : ''
                                })
                            }}
                        />
                    </Col>
                    <Col lg="2">
                        <Select
                            className="mb-2"
                            closeMenuOnSelect={true}
                            options={statusOption}
                            placeholder='Status'
                            onChange={(selectedOption) => {
                                setParam({
                                    ...param,
                                    status: selectedOption ? selectedOption.value : ''
                                })
                            }}
                        />
                    </Col>
                    <Col lg="2">
                        <Form.Control
                            type="text"
                            className="mb-2"
                            placeholder="Member Email"
                        />
                    </Col>
                    <Col lg="2">
                        <DatePicker
                            selected={startDate}
                            onChange={dateOnChange}
                            startDate={startDate}
                            // formatWeekDay={day => day.slice(0, 3)}
                            endDate={endDate}
                            selectsRange
                            dateFormat="MMM dd yyyy"
                            className='form-control'
                            placeholderText="Order date"
                        />
                    </Col>
                    <Col lg="2">
                        <Button
                            variant="falcon-default"
                            className="me-2"
                            type="button"
                        >
                            Search
                        </Button>
                    </Col>
                </Form.Group>
            </Col>
        </Row>
    );

    return (
        <Row className="g-0 mb-3">
            <Col lg={12} className="pe-lg-2">
                <Card className="h-lg-100">
                    <Confirm
                body="Do you want to delete this order ?"
                title="Delete!"
                show={deleteConfirmationShow}
                setShow={setDeleteConfirmationShow}
                handleConfirm={handleDeleteOrder}
            />
                    <Card.Header as="h5">Orders</Card.Header>
                    <div className="border-dashed-bottom"></div>
                    <Card.Body className="bg-light">
                        {OrderFilter}
                        <div className="table-responsive">
                            <table className="fs--1 mb-0 table table-sm table-striped overflow-hidden" role="table">
                                <thead className="bg-200 text-900 text-nowrap align-middle">
                                <tr>
                                    <th>Product Name</th>
                                    <th>Member Name</th>
                                    <th>Member Email</th>
                                    <th>Member Phone</th>
                                    <th>Total Points</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    orders.map((order, index) => (
                                        <tr className="align-middle white-space-nowrap" key={index}>
                                            <td role="cell" className="py-2">
                                                {order.items.map(item => (
                                                    <div key={item.id}>
                                                        <p className="mb-0"><b>{item.product.name} : </b>(
                                                            Point {item.point} )</p>
                                                    </div>
                                                ))}
                                            </td>

                                            <td role="cell">{order.member.user.first_name} {order.member.user.last_name}</td>
                                            <td role="cell">{order.member.user.email}</td>
                                            <td role="cell">{order.member.phone1}</td>
                                            <td role="cell">{order.total_point}</td>
                                            <td role="cell" className="fs-0">
                                                <SoftBadge pill bg={
                                                    order.status === 'completed' ? 'success' :
                                                        order.status === 'draft' ? 'warning' :
                                                            order.status === 'cancelled' ? 'danger' :
                                                                ''
                                                } className='text-capitalize'>{order.status}</SoftBadge>
                                            </td>
                                            <td role="cell" className="text-end">
                                                <CardDropdown iconClassName="fs--1" drop="start">
                                                    <div className="py-2">
                                                        <Dropdown.Item className="text-danger"
                                                                       onClick={() => showConfirmation(order.id)}>
                                                            Cancel
                                                        </Dropdown.Item>
                                                    </div>
                                                </CardDropdown>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <ReactPaginate
                            previousLabel={previous_icon()}
                            nextLabel={next_icon()}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination mb-0 mx-1'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    );
};

export default Orders;