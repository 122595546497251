import {Button, Col, Form, Row} from "react-bootstrap";
import Select from "react-select";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {response_error} from "../../../helpers/functions";
import {contactTypeOptions} from "../../../data/options";
import DatePicker from "react-datepicker";
import {profession} from 'data/memberInfoData/profession';
import {format} from "date-fns";

const MemberSearchFilter = ({param, setParam, getMembers, downloadMembers}) => {
    const ambassadorTitles = [
        {value: 'ambassador', label: 'Ambassador'},
        {value: 'deputy_ambassador', label: 'Deputy Ambassador'},
        {value: 'social_ambassador', label: 'Social Ambassador'},
        {value: 'regional_manager', label: 'Regional Manager'}
    ];
    const membershipStatuses = [
        {value: 'pending', label: 'Pending'},
        {value: 'active', label: 'Active'},
        {value: 'suspended', label: 'Suspended'},
        {value: 'lapsed', label: 'Lapsed'}
    ];

    const [regionOptions, setRegionOptions] = useState([]);
    const [siteOptions, setSiteOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);

    const [membershipOptions, setMembershipOptions] = useState([]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getMembers(1)
        }
    }
    const date = new Date();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const dateOnChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setParam({
            ...param,
            leaving_date_start: start ? format(new Date(start), 'yyyy-MM-dd') : '',
            leaving_date_end: start ? format(new Date(end), 'yyyy-MM-dd') : ''
        })
    };

useEffect(() => {
        axios.get('/groups/forFilter')
            .then(response => {
                setGroupOptions(response.data.groups.map(group => {
                    return {value: group.id, label: group.name}
                }))
            })
            .catch(error => {
                response_error(error);
            })

    }, [])
    return (
        <>
            <Row>
                <Col lg={12}>
                    <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextEmail"

                    >
                        <Col lg="2">
                            <Form.Control
                                type="text"
                                className="mb-2"
                                placeholder="Name"
                            />
                        </Col>
                        <Col lg="2">
                            <Form.Control
                                type="text"
                                className="mb-2"
                                placeholder="City"
                            />
                        </Col>
                        <Col lg="2">
                            <Select
                                className="mb-2"
                                closeMenuOnSelect={false}
                                options={groupOptions}
                                placeholder="Groups"
                                isMulti
                                classNamePrefix="react-select"
                            />
                        </Col>
                        <Col lg="2">
                            <Select
                                options={profession}
                                name="profession"
                                placeholder='Profession'
                            />
                        </Col>
                        <Col lg="2">
                            <Form.Control
                                type="text"
                                className="mb-2"
                                placeholder="Company Services"
                            />
                        </Col>
                        <Col lg="2">
                            <Button
                                variant="falcon-default"
                                className="me-2"
                                type="button"
                            >
                                Search
                            </Button>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}

export default MemberSearchFilter;