import { Card, Col, Row } from 'react-bootstrap';
import React from 'react';
import { Link } from 'react-router-dom';

const Member = ({ members }) => {
  return (
    <Row className="g-4">
      {members.map((member, index) => {
        const groups = member.home_groups.map(group => {
          return group.name;
        });

        return (
          <Col key={member.id} sm={6} lg={4}>
            <Card className="mb-4 custom_card_height">
              <Card.Body>
                <Link to={`/members/edit/${member.id}`}>
                  <Card.Title as="div" className="text-primary mb-3 fs-3 fw-bold">
                    {member.first_name} {member.last_name} <br/>
                  </Card.Title>

                </Link>
                <Card.Text>
                  <Row>
                    <Col lg={6} className="mb-3">
                      <div className="text-400 fs--1">Company</div>
                      <div className="text-1000 fs-0">{member.company}</div>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <div className="text-400 fs--1">State (Billing)</div>
                      <div className="text-1000 fs-0">{member.state}</div>
                    </Col>
                    {/*<Col lg={6} className="mb-3">*/}
                    {/*  <div className="text-400 fs--1">Date Booked For</div>*/}
                    {/*  <div className="text-1000 fs-0">5/7/22</div>*/}
                    {/*</Col>*/}
                    <Col lg={6} className="mb-3">
                      <div className="text-400 fs--1">Mobile</div>
                      <div className="text-1000 fs-0">{member.phone1}</div>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <div className="text-400 fs--1">Email</div>
                      <Link to={`/members/edit/${member.id}`}>
                        <div className="text-1000 fs-0">{member.email}</div>
                      </Link>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <div className="text-400 fs--1">Person Type</div>
                      <div className="text-1000 fs-0">
                        {member.contact_type}
                      </div>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <div className="text-400 fs--1">Membership</div>
                      <div className="text-1000 fs-0">
                        {member.membership_name}
                      </div>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <div className="text-400 fs--1">Membership Status</div>
                      <div className="text-1000 fs-0">
                        {member.status}
                      </div>
                    </Col>

                    <Col lg={6} className="mb-3">
                      <div className="text-400 fs--1">Group</div>
                      <div className="text-1000 fs-0">{groups.join(', ')}</div>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <div className="text-400 fs--1">Lead Source</div>
                      <div className="text-1000 fs-0">{member.lead_source}</div>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <div className="text-400 fs--1">Joined Date</div>
                      <div className="text-1000 fs-0">{member.joined_date}</div>
                    </Col>

                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default Member;
