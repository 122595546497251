// export const appRoutes = {
//   label: 'app',
//   children: [
//     {
//       name: 'Calendar',
//       icon: 'calendar-alt',
//       to: '/app/calendar',
//       active: true
//     },
//     {
//       name: 'Chat',
//       icon: 'comments',
//       to: '/app/chat',
//       active: true
//     },
//     {
//       name: 'Email',
//       icon: 'envelope-open',
//       active: true,
//       children: [
//         {
//           name: 'Inbox',
//           to: '/email/inbox',
//           active: true
//         },
//         {
//           name: 'Email detail',
//           to: '/email/email-detail',
//           active: true
//         },
//         {
//           name: 'Compose',
//           to: '/email/compose',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'Events',
//       icon: 'calendar-day',
//       active: true,
//       children: [
//         {
//           name: 'Create an event',
//           to: '/events/create-an-event',
//           active: true
//         },
//         {
//           name: 'Event detail',
//           to: '/events/event-detail',
//           active: true
//         },
//         {
//           name: 'Event list',
//           to: '/events/event-list',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'E Commerce',
//       icon: 'shopping-cart',
//       active: true,
//       children: [
//         {
//           name: 'Product',
//           active: true,
//           children: [
//             {
//               name: 'Product list',
//               to: '/e-commerce/product/product-list',
//               active: true
//             },
//             {
//               name: 'Product grid',
//               to: '/e-commerce/product/product-grid',
//               active: true
//             },
//             {
//               name: 'Product details',
//               to: '/e-commerce/product/product-details',
//               active: true
//             }
//           ]
//         },
//         {
//           name: 'Orders',
//           active: true,
//           children: [
//             {
//               name: 'Order list',
//               to: '/e-commerce/orders/order-list',
//               active: true
//             },
//             {
//               name: 'Order details',
//               to: '/e-commerce/orders/order-details',
//               active: true
//             }
//           ]
//         },
//         {
//           name: 'Customers',
//           to: '/e-commerce/customers',
//           active: true
//         },
//         {
//           name: 'Customer details',
//           to: '/e-commerce/customer-details',
//           active: true
//         },
//         {
//           name: 'Shopping cart',
//           to: '/e-commerce/shopping-cart',
//           active: true
//         },
//         {
//           name: 'Checkout',
//           to: '/e-commerce/checkout',
//           active: true
//         },
//         {
//           name: 'Billing',
//           to: '/e-commerce/billing',
//           active: true
//         },
//         {
//           name: 'Invoice',
//           to: '/e-commerce/invoice',
//           active: true
//         }
//       ]
//     },
//     {
//       name: 'Kanban',
//       icon: ['fab', 'trello'],
//       to: '/app/kanban',
//       active: true
//     },
//     {
//       name: 'Social',
//       icon: 'share-alt',
//       active: true,
//       children: [
//         {
//           name: 'Feed',
//           to: '/social/feed',
//           active: true
//         },
//         {
//           name: 'Activity log',
//           to: '/social/activity-log',
//           active: true
//         },
//         {
//           name: 'Notifications',
//           to: '/social/notifications',
//           active: true
//         },
//         {
//           name: 'Followers',
//           to: '/social/followers',
//           active: true
//         }
//       ]
//     }
//   ]
// };

export const groupRoutes = {
    label: 'Groups',
    children: [
        {
            name: 'Groups',
            icon: 'layer-group',
            active: true,
            children: [
                {
                    name: 'All Groups',
                    icon: 'layer-group',
                    to: '/groups',
                    active: true
                },
                // {
                //     name: 'Create Groups',
                //     icon: 'user',
                //     to: '/groups/edit',
                //     active: true
                // },
                {
                    name: 'All Countries',
                    icon: 'flag',
                    to: '/countries',
                    active: true
                },
                {
                    name: 'All Regions',
                    icon: 'comments',
                    to: '/regions',
                    active: true
                },
            ]
        }
    ]
};
export const memberRoutes = {
    label: 'Members',
    children: [
        {
            name: 'Members',
            icon: 'users',
            active: true,
            children: [
                {
                    name: 'All Members',
                    icon: 'users',
                    to: '/members',
                    active: true
                },
                {
                    name: 'Membership Types',
                    icon: 'user',
                    to: '/membership-types',
                    active: true
                },
            ]
        }
    ]
};
export const eventRoutes = {
    label: 'Events',
    children: [
        {
            name: 'Events',
            icon: 'calendar-day',
            active: true,
            children: [
                {
                    name: 'All Events',
                    icon: 'calendar-day',
                    to: '/events',
                    active: true
                },
                {
                    name: 'Create Event',
                    icon: 'calendar-alt',
                    to: '/events/create',
                    active: true
                },
                {
                    name: 'All Future Events',
                    icon: 'calendar-day',
                    to: '/events/table-view',
                    active: true
                },
            ]
        }
    ]
};
export const ProductRoutes = {
    label: 'Products',
    children: [
        {
            name: 'Products',
            icon: 'calendar-day',
            active: true,
            children: [
                {
                    name: 'Create',
                    icon: 'calendar-day',
                    to: '/products/create',
                    active: true,
                    exact: true
                },
                {
                    name: 'All',
                    icon: 'calendar-alt',
                    to: '/products',
                    active: true,
                    exact: true
                },
            ]
        }
    ]
};
export const OrdersRoutes = {
    label: 'Orders',
    children: [
        {
            name: 'Orders',
            icon: 'calendar-day',
            to: '/orders',
            active: true
        }
    ]
};

export const NationalEventRoutes = {
    label: 'National Events',
    children: [
        {
            name: 'National Events',
            icon: 'calendar-day',
            active: true,
            children: [
                {
                    name: 'Events',
                    icon: 'calendar-day',
                    to: '/national-events',
                    active: true
                },
                {
                    name: 'Bookings',
                    icon: 'calendar-day',
                    to: '/bookings',
                    active: true
                },
            ]
        }
    ]
};
export const pagesRoutes = {
    label: 'pages',
    children: [
        {
            name: 'Email',
            icon: 'envelope',
            to: '/email',
            active: true
        },
        {
            name: 'Team',
            icon: 'user',
            to: '/team',
            active: true
        },
        {
            name: 'Winner',
            icon: 'flag',
            to: '/winner',
            active: true
        },
        {
            name: 'New Joiners',
            icon: 'flag',
            to: '/new-joiners',
            active: true
        },
        {
            name: 'Golden Tickets',
            icon: 'flag',
            to: '/golden-tickets',
            active: true
        },
        {
            name: 'Coupons',
            icon: 'calendar-day',
            to: '/coupons',
            active: true
        },
    ]
};
export const activitiesRoutes = {
    label: 'Activities',
    children: [
        {
            name: 'Activities',
            icon: 'chart-line',
            to: '/activities',
            active: true
        },
    ]
};

export const currentMemberRoutes = {
    label: 'Member',
    children: [
        {
            name: 'Dashboard',
            active: true,
            icon: 'chart-pie',
            to: '/',
        },
        {
            name: 'Profile',
            icon: 'user',
            active: true,
            to: '/profile',
        },
        {
            name: 'Meetings',
            icon: 'calendar-day',
            to: '/meetings',
            active: true
        },
        {
            name: 'Redeem Points',
            icon: 'tags',
            to: '/redeem-points',
            active: true
        },
        {
            name: 'Order History',
            icon: 'cart-plus',
            to: '/order-history',
            active: true
        },
        {
            name: 'My Points',
            icon: ['fab', 'trello'],
            to: '/my-points',
            active: true
        },
        {
            name: '4 Ball Bookings',
            icon: 'globe',
            to: '/4ball-bookings',
            active: true
        },
        {
            name: 'Golden Ticket Shared',
            icon: 'file-alt',
             to: '/golden-ticket',
            active: true
        },
        {
            name: 'Members',
            icon: 'users',
             to: '/members',
            active: true
        },
    ]
};


export default [
    memberRoutes,
    ProductRoutes,
    OrdersRoutes,
    currentMemberRoutes,
    eventRoutes,
    groupRoutes,
    pagesRoutes,
    NationalEventRoutes,
    activitiesRoutes
];
