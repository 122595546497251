import React from 'react';
import { Card } from 'react-bootstrap';
import Background from 'components/common/Background';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';

const HomeGroupCard = ({groups}) => {

  return (
    <Card className="overflow-hidden card mb-3">
      <Background image={bg1} className="bg-card" />
      <Card.Body className="position-relative">
        <h6>Home Group</h6>
        <div className="text-warning display-4 fs-5 mb-2 fw-normal font-sans-serif">
            <span>{groups}</span>
        </div>
      </Card.Body>
    </Card>
  );
};

export default HomeGroupCard;

