import React, {useContext, useEffect, useState} from 'react';
import { Card, Col, Form, Row, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Flex from '../../../../components/common/Flex';
import Select from "react-select";
import { GroupContext } from 'context/Context';
import FalconCloseButton from 'components/common/FalconCloseButton';
import axios from "axios";

const Ambassador = () => {
    const { ambassadors, setAmbassadors, groupId } = useContext(GroupContext);
    const [show, setShow] = useState(false);
    const [memberOptions, setMemberOptions] = useState([]);

    useEffect(() => {
        axios.get('/ambassadors')
            .then(response => {
                let options = response.data.map(ambassador => {
                    return {value: ambassador.id, label: `${ambassador.first_name} ${ambassador.last_name} - ${ambassador.email}`}
                })

                setMemberOptions(options);
            })

    }, [])



    const title = [
        { value: 'ambassador', label: 'Ambassador' },
        { value: 'deputy_ambassador', label: 'Deputy Ambassador' },
        { value: 'social_ambassador', label: 'Social Ambassador' },
        { value: 'regional_ambassador', label: 'Regional Ambassador' },
        { value: 'regional_director', label: 'Regional Director' }
    ];

    const [newAmbassador, setNewAmbassador] = useState({});


    const ambassadorHandleChange = (e, meta) => {
        let selected_ambassador = e.label.split('-');
        setNewAmbassador(prevState => ({ ...prevState, member_id: e.value, first_name: selected_ambassador[0],last_name: '', email: selected_ambassador[1] }));
    }

    const ambassadorTitleHandleChange = (e, meta) => {
        setNewAmbassador(prevState => ({ ...prevState, type: e.value, group_id: groupId }));
    }

    const addAmbassador = () => {
        if(newAmbassador.member_id && newAmbassador.group_id && newAmbassador.type){
            setAmbassadors(prevState => ([...prevState, newAmbassador]));
            setShow(false);
        }

    }

    const deleteAmbassador = (id) => {
        let newAmbassadors = ambassadors.filter(ambassador => ambassador.id !== id)

        setAmbassadors(newAmbassadors);
    }

    const editAmbassador = (id) => {
        let newAmbassadors = ambassadors.filter(ambassador => ambassador.id === id)

        setNewAmbassador(...newAmbassadors);
        setShow(true);
    }

    const Actions = (prop) => {
        return (
            <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
                <Button onClick={() => editAmbassador(prop.id)} variant="light" size="sm" className="border-300 me-1 text-600">
                    <FontAwesomeIcon icon="edit" />
                </Button>
                <Button variant="light" size="sm" className="border-300 text-600" onClick={() => deleteAmbassador(prop.id)}>
                    <FontAwesomeIcon icon="trash-alt" />
                </Button>
            </div>

        );
    };

    return (
        <>
            <Card className="mb-3">
                <Card.Header as="h5">
                    <Row className="flex-between-center">
                        <Col md>
                            <h5 className="mb-2 mb-md-0"> Ambassador</h5>
                        </Col>
                        <Col xs="auto">
                            <Button
                                onClick={() => setShow(true)}
                                size="sm"
                                variant="falcon-default"
                                className="me-2"
                                type="button"
                            >
                                Add Ambassador
                            </Button>
                        </Col>
                    </Row>
                </Card.Header>
                <div className="border-dashed-bottom"></div>
                <Card.Body className="bg-light">
                    <Row className="gx-2 gy-3">
                        {
                            ambassadors.map(ambassador => {
                                return (
                                    <Col md="4" key={ambassador.member_id}>
                                        <Card className="mb-3 card_no_shadow">
                                            <Card.Body>
                                                <div className="flex-1 position-relative hover-actions-trigger">
                                                    <h6 className="fs-0 mb-0">{ambassador.first_name} {ambassador.last_name}</h6>
                                                    <p className="text-secondary mb-0 fs-0">{title.filter(a => ambassador.type === a.value)[0]['label']}<br /> {ambassador.email}</p>
                                                    <Actions  id={ambassador.id}/>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Card.Body>
            </Card>
            <Modal
                size=" "
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add Ambassador
                    </Modal.Title>
                    <FalconCloseButton onClick={() => setShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Ambassador</Form.Label>
                                <Select
                                options={memberOptions}
                                onChange={ambassadorHandleChange}
                                value={newAmbassador && memberOptions.filter(option => option.value === newAmbassador.member_id)}
                                name='id'
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Title</Form.Label>
                                <Select
                                options={title}
                                onChange={ambassadorTitleHandleChange}
                                value={newAmbassador && title.filter(option => option.value === newAmbassador.type)}
                                name='title'
                                />
                            </Form.Group>
                        </Col>
                        <Col className="text-end">
                            <Button
                                size="sm"
                                variant="falcon-default"
                                className="me-2"
                                type="button"
                                onClick={addAmbassador}
                            >
                                Add Ambassador
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Ambassador;