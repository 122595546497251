import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
// axios.defaults.withCredentials = true;

ReactDOM.render(
  <React.StrictMode>
    <Main>
    <div><Toaster/></div>
      <App />
    </Main>
  </React.StrictMode>,
  document.getElementById('main')
);
