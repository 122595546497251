import React from 'react';
import {Button, Card, Col, Row} from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import FalconCardHeader from "../../../../components/common/FalconCardHeader";
import axios from "axios";
import toast from "react-hot-toast";
import {response_error} from "../../../../helpers/functions";

const DangerZone = () => {
    let { memberId } = useParams();
    const delete_member = (member_id) => {
        axios.delete(`/members/${member_id}`)
            .then(response => {
                toast.success(response.data.message);
                location.href = '/members';
            })
            .catch(error => {
                response_error(error);
            });
    }

    const submit = () => {
        confirmAlert({
          title: 'Confirm to Delete',
          message: 'Are you sure to DELETE ?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => delete_member(memberId)
            },
            {
              label: 'No'
            }
          ]
        });
      };
    return (
        <Row className="g-0 mb-3">
            <Col lg={12}>
                <Card>
                    <Card.Header as="h5">Danger Zone</Card.Header>
                    <div className="border-dashed-bottom"></div>
                    <Card.Body>
                        <h5 className="mb-0">Delete this member</h5>
                        <p className="fs--1">
                            Once you delete a account, there is no going back. Please be certain.
                        </p>
                        <Button variant="falcon-danger" className="w-100" onClick={submit}>
                            Delete Member
                        </Button>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

    );
};

export default DangerZone;
