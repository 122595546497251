import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fuse from 'fuse.js';
import {Link, useNavigate, useRoutes} from 'react-router-dom';
import { isIterableArray } from 'helpers/utils';
import Flex from 'components/common/Flex';
import FalconCloseButton from 'components/common/FalconCloseButton';
import {DebounceInput} from "react-debounce-input";

const MediaSearchContent = ({ item }) => {
  return (
    <Dropdown.Item className="px-card py-2" as={Link} to={item.url}>
      <Flex alignItems="center">
        <div className="ms-2">
          <h6 className="mb-0">{item.title}</h6>
          <p
            className="fs--2 mb-0"
            dangerouslySetInnerHTML={{ __html: item.text || item.time }}
          />
        </div>
      </Flex>
    </Dropdown.Item>
  );
};

const SearchBox = ({ autoCompleteItem, getResult }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [resultItem, setResultItem] = useState(autoCompleteItem);

  const fuseJsOptions = {
    includeScore: true,
    keys: ['title', 'text', 'breadCrumbTexts']
  };

  let searchResult = new Fuse(autoCompleteItem, fuseJsOptions)
    .search(searchInputValue)
    .map(item => item.item);

  // const recentlyBrowsedItems = resultItem.filter(
  //   item => item.catagories === 'recentlyBrowsedItems'
  // );
  //
  // const suggestedFilters = resultItem.filter(
  //   item => item.catagories === 'suggestedFilters'
  // );
  //
  // const suggestionFiles = resultItem.filter(
  //   item => item.catagories === 'suggestionFiles'
  // );
  //
  // const suggestionMembers = resultItem.filter(
  //   item => item.catagories === 'suggestionMembers'
  // );

  const search = (e) => {
    e.preventDefault();
    location.href = `/members?search=${searchInputValue}`;
  }

  // const toggle = () => setDropdownOpen(!dropdownOpen);
  const focus = () => setDropdownOpen(true);


  useEffect(() => {
    if (searchInputValue) {
      getResult(searchInputValue);
      // setResultItem(searchResult);
      // isIterableArray(searchResult)
      //   ? setDropdownOpen(true)
      //   : setDropdownOpen(false);
    } else {
      setResultItem(autoCompleteItem);
    }
    // eslint-disable-next-line
  }, [searchInputValue]);

  useEffect(() => {
    setResultItem(autoCompleteItem);
    isIterableArray(autoCompleteItem)
        ? setDropdownOpen(true)
        : setDropdownOpen(false);
  }, [autoCompleteItem])

  return (
    <Dropdown onFocus={focus} className="search-box">
      <Dropdown.Toggle
        as="div"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        bsPrefix="toggle"
      >
        <Form className="position-relative" onSubmit={search}>
          <DebounceInput
            type="search"
            placeholder="Search..."
            aria-label="Search"
            className="rounded-pill search-input form-control"
            value={searchInputValue}
            minLength={2}
            debounceTimeout={500}
            onChange={({ target }) => setSearchInputValue(target.value)}
          />
          <FontAwesomeIcon
            icon="search"
            className="position-absolute text-400 search-box-icon"
          />
          {searchInputValue && (
            <div
              className="search-box-close-btn-container"
              // style={{ right: '10px', top: '8px' }}
            >
              <FalconCloseButton
                size="sm"
                noOutline
                onClick={() => setSearchInputValue('')}
              />
            </div>
          )}
        </Form>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="scrollbar py-3" style={{ maxHeight: '24rem' }}>
        {isIterableArray(resultItem) && (
            <>
              {resultItem.map(item => (
                <MediaSearchContent item={item} key={item.id} />
              ))}
            </>
          )}

          {resultItem.length === 0 && (
            <p className="fs-1 fw-bold text-center mb-0">No Result Found.</p>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};


export default SearchBox;
