import React, {useEffect, useState} from 'react';
import {Card, Dropdown, Button, Col, Form, Row, Modal, ListGroup, ListGroupItem} from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import CardDropdown from "../../../../components/common/CardDropdown";
import {next_icon, previous_icon, response_error} from "../../../../helpers/functions";
import Select from "react-select";
import SoftBadge from 'components/common/SoftBadge';
import IconButton from "../../../../components/common/IconButton";
import DatePicker from "react-datepicker";
import axios from "axios";
import moment from "moment";
import EventChart from "./EventChart";

const Bookings = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [param, setParam] = useState({
        search: '',
        year: '',
        national_event_id: [],
        status: [],
        page: 1
    });

    const [viewModalShow, setViewModalShow] = React.useState(false);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [year, setYear] = useState(null);
    const [event, setEvent] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [eventOptions, setEventOptions] = useState([]);
    const [events, setEvents] = useState([]);

    const [yearOptions, setYearOptions] = useState([]);

    const [bookings, setBookings] = useState([]);

    const getBookings = () => {
        axios.get(`/national-events/bookings?page=${page}`, {params: param})
            .then(response => {
                setBookings(response.data.nationalEventBookings.data);
                let total_page = parseInt(response.data.nationalEventBookings.total) / parseInt(response.data.nationalEventBookings.per_page);
                total_page = total_page > 0 ? Math.ceil(total_page) : 0;
                setPageCount(total_page);
            })
            .catch(error => {
                response_error(error);
            })
    }

    const getNationalEvents = () => {

        axios.get('/national-events?year=' + moment().year())
            .then(response => {
                let _eventOptions = [];
                response.data.nationalEvents.data.map(event => {
                    _eventOptions.push({value: event.id, label: event.name + ' (' + event.year + ')' });
                });

                setEvents(response.data.nationalEvents.data);

                setEventOptions(_eventOptions);

            }).catch(error => {
            response_error(error);
        })
    }

    useEffect(() => {
        getBookings();
        getNationalEvents();
    }, [page, param]);

    const payment = [
        {value: 'pending', label: 'Pending'},
        {value: 'paid', label: 'Paid'},
        {value: 'refunded', label: 'Refunded'}
    ];


    useEffect(() => {
        let _yearOptions = [];
        for (let i = moment().year(); i >= moment().year() - 3; i--) {
            _yearOptions.push({value: i, label: i});
        }

        setYearOptions(_yearOptions);
    }, []);

    const handleYearChange = (value) => {
        setYear(value);
        setParam({...param, year: value ? value.value : ''});
    }

    const handleEventChange = (value) => {
        setEvent(value);
        let event_ids = [];
        value.map(event => {
            event_ids.push(event.value);
        });
        setParam({...param, national_event_id: event_ids});
    }

    const handlePaymentStatusChange = (value) => {
        setPaymentStatus(value);
        let payment_status = [];
        value.map(status => {
            payment_status.push(status.value);
        });
        setParam({...param, status: payment_status});
    }


    const handlePageClick = page => {
        setPage(page.selected + 1);
    }

    const Filter = (
        <Row>
            <Col lg={10}>
                <Form.Group
                    as={Row}
                >
                    <Col lg="6" className="mb-2">
                        <Select
                            isClearable={true}
                            placeholder='Select year'
                            options={yearOptions}
                            value={year}
                            onChange={value => handleYearChange(value)}
                        />
                    </Col>
                    <Col lg="6" className="mb-2">
                        <Select
                            placeholder='Select Event'
                            isMulti
                            classNamePrefix="react-select"
                            options={eventOptions}
                            value={event}
                            onChange={value => handleEventChange(value)}
                        />
                    </Col>
                    <Col lg="6" className="mb-2">
                        <Select
                            isClearable={true}
                            placeholder='Payment Status'
                            isMulti
                            classNamePrefix="react-select"
                            options={payment}
                            value={paymentStatus}
                            onChange={value => handlePaymentStatusChange(value)}
                        />
                    </Col>
                    <Col lg="6" className="mb-2">
                        <Form.Group className="mb-3" controlId="search">
                            <Form.Control type="text" placeholder="Search"
                                          onChange={e => setParam({...param, search: e.target.value})}/>
                        </Form.Group>
                    </Col>
                </Form.Group>
            </Col>
            <Col lg={2}>
                <div className="d-grid gap-2">
                    <Button
                        variant="falcon-default"
                        className="me-2"
                        type="button"
                    >
                        Search
                    </Button>
                    <Button
                        variant="falcon-default"
                        className="me-2"
                        type="button"
                    >
                        Download
                    </Button>
                </div>
            </Col>
        </Row>
    );

    return (
        <>
            <Row>
                <Col lg={9}>
                    <Card className="mb-3">
                        <Card.Header>
                            <h5 className="fs-0 mb-3 text-nowrap py-2 py-xl-0">National Event</h5>
                            {Filter}
                        </Card.Header>
                        <Card.Body className="pt-0">
                            <div className="table-responsive">
                            <table className="fs--1 mb-0 overflow-hidden table table-sm table-striped overflow-hidden" role="table">
                                <thead className="bg-200 text-900 align-middle">
                                <tr>
                                    <th>Name</th>
                                    <th>Email/Phone</th>
                                    <th>Events</th>
                                    {/*<th>4Ball/player</th>*/}
                                    <th>Total Price</th>
                                    <th>Payment Status</th>
                                    <th>Add-on</th>
                                    <th>Discount</th>
                                    <th>2nd Player</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>

                                {
                                    bookings.map((booking, index) => {
                                            let national_events = booking.national_event_booking_events.map(event => event.national_event);
                                            let status_color = booking.status === 'pending' ? 'warning' : booking.status === 'paid' ? 'success' : 'danger';

                                            return (
                                                <tr className="align-middle" role="row" key={index}>
                                                    <td role="cell"
                                                        className="py-2">{booking.first_name + ' ' + booking.last_name}</td>
                                                    <td role="cell">
                                                        {booking.email}
                                                        <span className='d-block'>{booking.phone}</span>
                                                    </td>
                                                    <td role="cell">{national_events.filter(event => event.type === 'event').map(event => event.name).join('<br>')}</td>
                                                    <td role="cell">{booking.paid_amount}</td>
                                                    <td role="cell" className="py-2 text-center">
                                                        <SoftBadge pill bg={status_color}>{booking.status}</SoftBadge>
                                                    </td>
                                                    <td role="cell">{national_events.filter(event => event.type === 'add-on').map(event => event.name).join('<br>')}</td>
                                                    <td role="cell">{booking.discount}</td>
                                                    <td role="cell">{booking.national_event_booking_events.map(event => event.national_event_invitation ? event.national_event_invitation.name : '')}</td>
                                                    <td role="cell" className="text-end">
                                                        <CardDropdown iconClassName="fs--1" drop="start">
                                                            <div className="py-2">
                                                                <Dropdown.Item
                                                                    onClick={() => setViewModalShow(true)}>View</Dropdown.Item>
                                                                <Dropdown.Divider as="div"/>
                                                                <Dropdown.Item
                                                                    onClick={() => setModalShow(true)}>Edit</Dropdown.Item>
                                                                <Dropdown.Divider as="div"/>
                                                                <Dropdown.Item className="text-danger">
                                                                    Delete
                                                                </Dropdown.Item>
                                                            </div>
                                                        </CardDropdown>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )
                                }

                                </tbody>
                            </table>
                                </div>
                        </Card.Body>
                        <Card.Footer>
                            <ReactPaginate
                                previousLabel={previous_icon()}
                                nextLabel={next_icon()}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination mb-0 mx-1'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        </Card.Footer>
                    </Card>
                    <Modal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Add Bucket</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group className="mb-3" controlId="bucketName">
                                        <Form.Label>Bucket Name</Form.Label>
                                        <Form.Control type="text" placeholder="Bucket Name"/>
                                    </Form.Group>
                                </Col>
                                <Col sm={12}>
                                    <Form.Group className="mb-3" controlId="year">
                                        <Form.Label>Year</Form.Label>
                                        <Form.Control type="text" placeholder="Year"/>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} className="text-center">
                                    <Button
                                        size="sm"
                                        variant="falcon-default"
                                        className="me-2"
                                        type="button"
                                    >
                                        Add
                                    </Button>
                                </Col>

                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal
                        show={viewModalShow}
                        onHide={() => setViewModalShow(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        {/*<Modal.Header closeButton>*/}
                        {/*    <Modal.Title id="contained-modal-title-vcenter">View All</Modal.Title>*/}
                        {/*</Modal.Header>*/}
                        <Modal.Body>
                            <Row>
                                <Col xs={6} md={4}
                                     className="border-200 border-bottom border-end pb-4 pt-2 col-md-4 col-6">
                                    <h6 className="pb-1 text-primary">Company Name</h6>
                                    <h6 className="fs-9 mb-1 text-400">Sapphire Services (UK) Ltd</h6>
                                </Col>
                                <Col xs={6} md={4}
                                     className="border-200 border-bottom border-end pb-4 pt-2 col-md-4 col-6">
                                    <h6 className="pb-1 text-primary">Address</h6>
                                    <h6 className="fs-9 mb-1 text-400">2 Barclay Gardens Stevenage Hertfordshire SG1
                                        3BF</h6>
                                </Col>
                                <Col xs={6} md={4}
                                     className="border-200 border-bottom border-end pb-4 pt-2 col-md-4 col-6">
                                    <h6 className="pb-1 text-primary">Remaining 4Ball/player</h6>
                                    <h6 className="fs-9 mb-1 text-400">National Pairs 2024: 55</h6>
                                </Col>
                                <Col xs={6} md={4} className="border-200 border-end pb-4 pt-2 col-md-4 col-6">
                                    <h6 className="pb-1 text-primary">2nd player price</h6>
                                    <h6 className="fs-9 mb-1 text-400">$500</h6>
                                </Col>
                                <Col xs={6} md={4} className="border-200 border-end pb-4 pt-2 col-md-4 col-6">
                                    <h6 className="pb-1 text-primary">Dietary</h6>
                                    <h6 className="fs-9 mb-1 text-400">test test</h6>
                                </Col>
                            </Row>

                            <div className="border-dashed border-bottom my-3"></div>
                            <h4 className="text-success mb-3">Players</h4>
                            <Row>
                                <Col xs={12} md={12}>
                                    <h6 className="pb-1 text-primary">National Pairs 2024</h6>
                                    <Row>
                                        <Col xs={6}>
                                            <div className="boxWrapper mb-1 border-end">
                                                <div className="d-flex">
                                                    <div><h6>1</h6></div>
                                                    <div className="flex-1 position-relative ps-3">
                                                        <h6 className="fs-9 mb-0">Joga Shoker</h6>
                                                        <span className="mb-1" style={{fontSize: '13px'}}>jogashoker@gmail.com | +447968734081</span>
                                                        <p className="mb-1" style={{fontSize: '13px'}}><span
                                                            className="text-info">+447968734081</span> | <span
                                                            className="text-warning">Handicap: 6.0</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                            <div className="boxWrapper mb-1">
                                                <div className="d-flex">
                                                    <div><h6>2</h6></div>
                                                    <div className="flex-1 position-relative ps-3">
                                                        <h6 className="fs-9 mb-0">Joga Shoker</h6>
                                                        <span className="mb-1" style={{fontSize: '13px'}}>jogashoker@gmail.com | +447968734081</span>
                                                        <p className="mb-1" style={{fontSize: '13px'}}><span
                                                            className="text-info">+447968734081</span> | <span
                                                            className="text-warning">Handicap: 6.0</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col lg={3}>
                    <EventChart events={events}/>
                </Col>
            </Row>
        </>
    );
}

export default Bookings;