import React, {useEffect, useState} from 'react';
import {Form, Card, Col, Row, Button, Container, Table} from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import Header from "../../../Containers/Public/Header";
import axios from "axios";
import {useForm} from "react-hook-form";
import {response_error} from "../../../helpers/functions";
import {toast} from "react-hot-toast";
import { v4 as uuid } from 'uuid';

const BookingForm = () => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: {errors}
    } = useForm();

    const [formData, setData] = useState({});

    const onSubmit = data => {
        data['uuid'] = uuid();
        data['national_event'][0]['quantity'] = 1;
        axios.post('/national-events/bookings', data).then((response) => {
            // toast.success(response.data.message);
            reset();
            if(response.data.payable_amount === 0){
                toast.success(response.data.message);
                return;
            }
            location.href = `/payment/?client_secret=${response.data.client_secret}&uuid=${data.uuid}&payable_amount=${response.data.payable_amount}`;
        })
            .catch((error) => {
                response_error(error);
            })

    };

    let payableTotal;
    const {eventId} = useParams();

    const [nationalEvent, setNationalEvent] = useState({});

    const [summary, setSummary] = useState({
        items: [],
        subTotal: 0,
        discount: 0,
        coupon: '',
        shipping: 0,
        total: 0,
    });

    const [hasPlayingPartner, setHasPlayingPartner] = useState(false);

    useEffect(() => {
        axios.get(`/national-events/${eventId}`).then((response) => {
            setNationalEvent(response.data.nationalEvent);

        });
    }, [eventId]);

    const handleItems = (e, event) => {
        if (e.target.checked) {
            addItem(e, event);
        } else {
            removeItem(event);
        }
    };

    const removeItem = (event) => {
        setSummary({
            ...summary,
            items: summary.items.filter((item) => item.id != event.id),
            subTotal: summary.subTotal - event.price
        });

    };

    const addItem = (e, event) => {
        const item = {
            id: e.target.id,
            name: event.name,
            price: event.price,
            description: event.description,
            event: event
        };

        if (event.type === 'event') {
            let items = summary.items.filter((item) => item.event.type !== 'event');
            items.unshift(item);

            setSummary({
                ...summary,
                items: items,
                subTotal: items.reduce((a, b) => a * 1 + b.price * 1, 0)
            });
            return;
        }

        setSummary({
            ...summary,
            items: [...summary.items, item],
            subTotal: summary.subTotal + item.price * 1
        });

    };

    useEffect(() => {
        calculation();
    }, [summary.subTotal, summary.discount]);

    const calculation = () => {
        let vat = ((summary.subTotal - summary.discount) * 0.2).toFixed(2) * 1;
        setSummary({
            ...summary,
            vat: vat,
            total: summary.subTotal + vat - summary.discount
        });
    };

    const getDiscount = (e) => {
        axios.get(`/coupons/discount`, {params: {
            coupon_code: e.target.value,
            }}).then((response) => {
                let discount = 0;
                if(response.data.discount > 0){
                    discount = response.data.discount;
                }
                else if(response.data.percent > 0){
                    discount = (summary.subTotal * response.data.percent / 100).toFixed(2) * 1;
                }
            setSummary({
                ...summary,
                discount: discount
            });
        }).catch((error) => {
            response_error(error);
        });
    };

    const BookingFormControl = (
        <>

            <Card className="mb-3">
                <Card.Header className="bg-light border-dashed-bottom">
                    <Row className="flex-between-center">
                        <Col sm="auto">
                            <h5 className="mb-0">Events</h5>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <p className="mb-0"><b>Please select your event.</b></p>
                    {
                        nationalEvent && nationalEvent.type === 'event' && (
                            <Form.Check
                                type='radio'
                                id={nationalEvent.id}
                                label={nationalEvent.name}
                                name='national_event[0][id]'
                                className="mb-0"
                                value={nationalEvent.id}
                                onClick={(e) => handleItems(e, nationalEvent)}
                                isInvalid={!!errors.national_event}
                                {...register('national_event[0][id]', {
                                    required: 'Please Select Event'
                                })}
                            />
                        )
                    }
                    {
                        nationalEvent && nationalEvent.type === 'group' && (
                            nationalEvent.events.map((event) => (
                                    <Form.Check
                                        key={event.id}
                                        type='radio'
                                        id={event.id}
                                        label={event.name}
                                        name='national_event[0][id]'
                                        className="mb-0"
                                        value={event.id}
                                        onClick={(e) => handleItems(e, event)}
                                        isInvalid={!!errors.national_event}
                                        {...register('national_event[0][id]', {
                                            required: 'Please Select Event'
                                        })}
                                    />
                                )
                            ))
                    }
                    {
                        nationalEvent && nationalEvent.hasOwnProperty('optional_items') && nationalEvent.optional_items && (
                            nationalEvent.optional_items.map((optionalItem) => (
                                <div key={optionalItem.id}>
                                    <p className="mb-0">{optionalItem.description}</p>
                                    <Form.Check

                                        type='checkbox'
                                        id={optionalItem.id}
                                        label={optionalItem.name}
                                        name='national_event[1][id]'
                                        className="mb-0"
                                        value={optionalItem.id}
                                        onClick={(e) => handleItems(e, optionalItem)}
                                        {...register('national_event[1][id]')}
                                    />
                                </div>
                            ))
                        )
                    }

                    {
                        nationalEvent && nationalEvent.invitation > 0 && (
                            <Form.Group className="mb-3" controlId="playing_partner">
                                <Form.Label>Do you have a playing partner?</Form.Label> <br/>
                                <Form.Check
                                    inline
                                    type='radio'
                                    id='yes'
                                    label='Yes'
                                    checked={hasPlayingPartner}
                                    onChange={() => setHasPlayingPartner(true)}
                                    name='playing_partner'
                                    {...register('playing_partner')}
                                />
                                <Form.Check
                                    inline
                                    type='radio'
                                    id='no'
                                    label='No'
                                    checked={!hasPlayingPartner}
                                    onChange={() => setHasPlayingPartner(false)}
                                    name='playing_partner'
                                    {...register('playing_partner')}
                                />
                                {hasPlayingPartner && (
                                    <Row>
                                        <Col xs={6}>
                                            <Form.Group className="mb-3" controlId="preferred_player_name">
                                                <Form.Label>Preferred Player Name</Form.Label>
                                                <Form.Control type="text" placeholder="Preferred Player Name"
                                                              name='national_event[0][invitation][name]'
                                                              {...register('national_event[0][invitation][name]')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Group className="mb-3" controlId="preferred_player_email">
                                                <Form.Label>Preferred Player email</Form.Label>
                                                <Form.Control type="text" placeholder="Preferred Player email"
                                                              name='national_event[0][invitation][email]'
                                                              {...register('national_event[0][invitation][email]')}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )}
                            </Form.Group>
                        )
                    }

                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Header className="bg-light border-dashed-bottom">
                    <Row className="flex-between-center">
                        <Col sm="auto">
                            <h5 className="mb-0">Registration Form</h5>
                            <p className="mb-0">Please complete the following form and payment to confirm your
                                attendance at the “FORE” Business {nationalEvent.name} {nationalEvent.year}. </p>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="first_name">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="First Name"
                                    name="first_name"
                                    isInvalid={!!errors.first_name}
                                    {...register('first_name', {
                                        required: 'Name field is required'
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.first_name && errors.first_name.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="last_name">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Last Name"
                                    name="last_name"
                                    isInvalid={!!errors.last_name}
                                    {...register('last_name', {
                                        required: 'Surname field is required'
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.last_name && errors.last_name.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Email"
                                    name="email"
                                    isInvalid={!!errors.email}
                                    {...register('email', {
                                        required: 'Email field is required'
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email && errors.email.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Phone"
                                    name="phone"
                                    isInvalid={!!errors.phone}
                                    {...register('phone', {
                                        required: 'Phone field is required'
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.phone && errors.phone.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="company_name">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Company Name"
                                    name="company_name"
                                    isInvalid={!!errors.company_name}
                                    {...register('company_name', {
                                        required: 'Company Name is required'
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.company_name && errors.company_name.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="address1">
                                <Form.Label>Street Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Street Address"
                                    name="address1"
                                    isInvalid={!!errors.address1}
                                    {...register('address1', {
                                        required: 'Street Address is required'
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.address1 && errors.address1.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="city">
                                <Form.Label>Town</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Town"
                                    name="city"
                                    isInvalid={!!errors.city}
                                    {...register('city', {
                                        required: 'Town is required'
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.city && errors.city.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="state">
                                <Form.Label>County</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="County"
                                    name="state"
                                    isInvalid={!!errors.state}
                                    {...register('state', {
                                        required: 'County is required'
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.state && errors.state.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="postal_code">
                                <Form.Label>Postal code/Zip</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Postal code/Zip"
                                    name="postal_code"
                                    isInvalid={!!errors.postal_code}
                                    {...register('postal_code', {
                                        required: 'Postal code is required'
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.postal_code && errors.postal_code.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="Country">
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Country"
                                    name="country"
                                    isInvalid={!!errors.country}
                                    {...register('country', {
                                        required: 'Country is required'
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.postal_code && errors.postal_code.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="handicap">
                                <Form.Label>Handicap</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Handicap"
                                    name="handicap"
                                    isInvalid={!!errors.handicap}
                                    {...register('handicap', {
                                        required: 'Handicap is required'
                                    })}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.handicap && errors.handicap.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="mb-3" controlId="dietaryRequirements">
                                <Form.Label>If you have any dietary requirements, please send us
                                    details.</Form.Label>
                                <Form.Control as="textarea" rows={3}/>
                            </Form.Group>
                        </Col>
                        <Col sm={12} className="text-end">
                            <Button
                                variant="primary"
                                className="me-2"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

        </>
    );

    const BookingSummary = (
        <>
            <Card>
                <Card.Header className="bg-light btn-reveal-trigger d-flex flex-between-center border-dashed-bottom">
                    <h5 className="mb-0">Order Summary</h5>
                    <Link
                        to="/e-commerce/shopping-cart"
                        className="btn btn-link btn-sm btn-reveal text-600"
                    >
                    </Link>
                </Card.Header>
                <Card.Body>
                    {
                        summary.items.length > 0 && (
                            <Table borderless className="fs--1 mb-0">
                                <tbody>

                                {
                                    summary.items.map((item) => (
                                        <tr className="border-bottom" key={item.event.id}>
                                            <th className="ps-0">
                                                {item.name}
                                                <div className="text-400 fw-normal fs--2">
                                                    {item.description}
                                                </div>
                                            </th>
                                            <th className="ps-0 text-end">
                                                {item.price}£
                                            </th>
                                        </tr>
                                    ))
                                }

                                <tr className="border-bottom">
                                    <th className="ps-0" colSpan="2">
                                        <Form.Control
                                            placeholder="Coupon"
                                            name="coupon_code"
                                            {...register('coupon_code')}
                                            onBlur={getDiscount}
                                        />
                                    </th>
                                </tr>

                                {
                                    summary.subTotal > 0 && (
                                        <tr className="border-bottom">
                                            <th className="ps-0">Subtotal</th>
                                            <th className="ps-0 text-end">{summary.subTotal}£</th>
                                        </tr>
                                    )
                                }

                                {
                                    summary.discount > 0 && (
                                        <tr className="border-bottom">
                                            <th className="ps-0 text-success">Discount</th>
                                            <th className="ps-0 text-end text-success">-{summary.discount}£</th>
                                        </tr>
                                    )
                                }

                                {
                                    summary.vat > 0 && (
                                        <tr className="border-bottom">
                                            <th className="ps-0">Vat</th>
                                            <th className="ps-0 text-end">{summary.vat}£</th>
                                        </tr>
                                    )
                                }

                                </tbody>
                            </Table>
                        )
                    }

                    {
                        summary.items.length === 0 && (
                            <div className="text-center">
                                <p className="mb-0">No items in the cart. Please select event.</p>
                            </div>
                        )
                    }
                </Card.Body>
                {
                    summary.items.length > 0 && (
                        <Card.Footer className="d-flex justify-content-between bg-light">
                            <div className="fw-semi-bold">Payable Total</div>
                            <div className="fw-bold" style={{paddingRight: '1.25rem'}}>{summary.total}£</div>
                        </Card.Footer>
                    )
                }

            </Card>
        </>
    );
    return (
        <>
            <Header/>
            <Container>
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="g-3">
                        <Col xl={{span: 4, order: 1}}>
                            {BookingSummary}
                        </Col>
                        <Col xl={8} className="pb-5">
                            {BookingFormControl}
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    );
};

export default BookingForm;
