import React, {Component, useContext, useState} from 'react';
import {Form, Card, Col, Row} from 'react-bootstrap';
import Select from 'react-select';
import {MemberContext} from "../../../../context/Context";
import {country} from 'data/memberInfoData/country';


const BillingAddress = () => {

    const {billingAddress, setBillingAddress} = useContext(MemberContext);


    const addressHandleChange = (e) => {
        setBillingAddress(prevState => ({ ...prevState, [e.target.name]: e.target.value}));
    }
 const countrySelectChangeHandler = (e, meta) => {
     setBillingAddress(prevState => ({...prevState, [meta.name]: e.value}));
    }
    return (

        <Row className="g-0 mb-3">
            <Col lg={12} className="pe-lg-2">
                <Card className="h-lg-100">
                    <Card.Header as="h5">Billing Address</Card.Header>
                    <div className="border-dashed-bottom"></div>
                    <Card.Body className="bg-light">
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Address 1</Form.Label>
                                    <Form.Control
                                        placeholder="Address 1"
                                        name="address1"
                                        type="text"
                                        value={billingAddress.address1 || ''}
                                         onChange={addressHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Address 2</Form.Label>
                                    <Form.Control
                                        placeholder="Address 2"
                                        name="address2"
                                        type="text"
                                        value={billingAddress.address2 || ''}
                                         onChange={addressHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        placeholder="city"
                                        name="city"
                                        type="text"
                                        value={billingAddress.city || ''}
                                         onChange={addressHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>County / State</Form.Label>
                                     <Form.Control
                                        placeholder="county"
                                        name="state"
                                        type="text"
                                        value={billingAddress.state || ''}
                                         onChange={addressHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Postal Code</Form.Label>
                                    <Form.Control
                                        placeholder="Postal Code"
                                        name="postal_code"
                                        type="text"
                                        value={billingAddress.postal_code || ''}
                                         onChange={addressHandleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Country</Form.Label>
                                    <Select
                                        name="country"
                                        options={country}
                                          value={
                                            country.filter(option =>
                                                option.value === billingAddress.country)
                                        }
                                        onChange = {countrySelectChangeHandler}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default BillingAddress;