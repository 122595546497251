import React, { useContext, useState } from 'react';
import { Button, Form, Card, Col, Row, Modal } from 'react-bootstrap';

import Flex from '../../../../components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import { MemberContext } from '../../../../context/Context';
import axios from 'axios';
import { response_error } from '../../../../helpers/functions';
import moment from 'moment/moment';

const PersonNotes = () => {
  const { notes, setNotes, memberId } = useContext(MemberContext);
  const [note, setNote] = useState({ id: '', note: '' });

  const noteHandleChange = e => {
    setNote(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const saveNote = () => {
    if(note.id > 0){
      axios
          .put(`/members/${memberId}/notes/${note.id}`, note)
          .then(response => {
            setNotes(response.data);
            setShow(false);
            setNote({ id: '', note: '' });
          })
          .catch(error => {
            response_error(error);
          });
    }
    else{
      axios
          .post(`/members/${memberId}/notes`, note)
          .then(response => {
            setNotes(response.data);
            setShow(false);
            setNote({ id: '', note: '' });
          })
          .catch(error => {
            response_error(error);
          });
    }

  };

  const [show, setShow] = useState(false);

  const deleteNotes = id => {
    axios
      .delete(`/members/${memberId}/notes/${id}`)
      .then(response => {
        setNotes(response.data);
      })
      .catch(error => {
        response_error(error);
      });
  };
  const editNotes = id => {
    setShow(true);
    let editNote = notes.filter(note => id === note.id);
    setNote(...editNote);
  };

  const openModel = () => {
    setShow(true);
    setNote({ id: '', note: '' });
  };

  const Actions = prop => (
    <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
      <Button
        variant="light"
        size="sm"
        className="border-300 me-1 text-600"
        onClick={() => editNotes(prop.id)}
      >
        <FontAwesomeIcon icon="edit" />
      </Button>
      <Button
        variant="light"
        size="sm"
        className="border-300 text-600"
        onClick={() => deleteNotes(prop.id)}
      >
        <FontAwesomeIcon icon="trash-alt" />
      </Button>
    </div>
  );
  return (
    <>
      <Row className="g-0 mb-3 row">
        <Col lg={12} className="pe-lg-2">
          <Card className="h-lg-100">
            <Card.Header as="h5">
              <Row>
                <Col lg={6} md={6} sm={6}>
                  Notes
                </Col>
                <Col lg={6} md={6} sm={6} className="text-end">
                  <Button
                    size="sm"
                    variant="falcon-default"
                    className="me-2"
                    onClick={() => openModel()}
                  >
                    {' '}
                    Add Note{' '}
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <div className="border-dashed-bottom"></div>
            <SimpleBarReact style={{ maxHeight: '19rem' }}>
              <Card.Body className="bg-light">
                <Card.Body className="p-0">
                  <SimpleBarReact className="ask-analytics">
                    <div className="mt-3">
                      {notes.map(item => (
                        <div
                          key={item.id}
                          className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3 hover-actions-trigger"
                        >
                          <Flex alignItems="center" className="mb-3">
                            <FontAwesomeIcon
                              icon="file-alt"
                              className="text-primary"
                            />
                            <Link
                              to="#!"
                              className="stretched-link text-decoration-none"
                            >
                              <h5 className="fs--1 text-600 mb-0 ps-3">
                                {item.created_by !== null
                                  ? item.created_by['first_name']
                                  : ''}{' '}
                                {item.created_by !== null
                                  ? item.created_by['last_name']
                                  : ''}{' '}
                                |{' '}
                                {moment(item.created_at).format('MMM DD, YYYY')}
                              </h5>
                            </Link>
                          </Flex>
                          <h5 className="fs--1 text-800">{item.note}</h5>
                          <Actions id={item.id} />
                        </div>
                      ))}
                    </div>
                  </SimpleBarReact>
                </Card.Body>
              </Card.Body>
            </SimpleBarReact>
          </Card>
        </Col>
      </Row>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Type Your Notes"
              name="note"
              value={note.note}
              onChange={noteHandleChange}
            />
          </Form.Group>
          <Form.Group className="text-end">
            <Button
              onClick={() => saveNote()}
              variant="falcon-default"
              className="me-2"
              size="sm"
            >
              Add
            </Button>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PersonNotes;
