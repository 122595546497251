import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import Background from 'components/common/Background';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import bg1 from 'assets/img/icons/spot-illustrations/corner-2.png';
import {Link} from "react-router-dom";
import axios from "axios";
import {toast} from "react-hot-toast";
import {response_error} from "../../../helpers/functions";
import Flex from "../../../components/common/Flex";

const PointsCard = ({credit}) => {

    const addLoyaltyCard = () => {
        axios.post('/member/loyalty-card/add')
            .then(response => {
                toast.success(response.data.message);
            })
            .catch(error => {
                response_error(error);
            });
    }

    return (
        <Card className="overflow-hidden card mb-3">
            <Background image={bg1} className="bg-card"/>
            <Card.Body className="position-relative">
                 <h6>Points</h6>
                <div className="text-info display-4 fs-5 mb-2 fw-normal font-sans-serif">
                    <span>{credit}</span>
                </div>
                <Row className="g-2 h-100">
                    <Col sm={4} md={4}>
                        <Link to="/points" className="fw-semi-bold fs--1 text-nowrap">
                            All Points
                            <FontAwesomeIcon
                                icon="angle-right"
                                className="ms-1"
                                transform="down-1"
                            />
                        </Link>
                    </Col>
                    <Col sm={8} md={8}>
                        <Flex className="position-relative align-items-center">
                            <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2 text-danger">
                                <FontAwesomeIcon icon="credit-card"/>
                            </div>
                            <div className="flex-1 cursor-pointer" onClick={() => addLoyaltyCard()}>
                                <div className="stretched-link">
                                    <h6 className="text-800 mb-0">Add a Loyalty Card to my wallet</h6>
                                </div>
                            </div>
                        </Flex>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default PointsCard;

