import React, {useContext, useEffect, useState} from 'react';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import { Col, Form, Row, Button, Modal } from 'react-bootstrap';
import Select from "react-select";
import axios from "axios";
import {toast} from "react-hot-toast";


const SitesTableHeader = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [value, setValue] = useState(null);
    const ambassadors = [
        {value: '1', label: ' Social Ambassador'},
        {value: '2', label: ' Regional Ambassador'},
        {value: '3', label: ' Regional Director'}
    ];

    const _site = {
        name: '',
        slug: ''
    };
    const [site, setSite] = useState(_site)

    const handleSiteSave = (e) => {
        setSite(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    const handleSubmit = () => {
        axios.post('/sites', site)
            .then(response => {
                toast.success(response.data.message);
                props.setSites(prevState => ([...props.sites, response.data.site]))
                setSite(_site)
                setModalShow(false)
            })
    }

    return (
        <>
            <Row className="flex-between-center">
                <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">All Regions</h5>
                </Col>
                <Col xs={8} sm="auto" className="ms-auto text-end ps-0">

                    <div id="orders-actions">
                        <IconButton

                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                        >
                            <span className="d-none d-sm-inline-block ms-1" onClick={() => setModalShow(true)}>Add Regions</span>
                        </IconButton>
                    </div>

                </Col>
            </Row>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Add Region</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Region</Form.Label>
                                <Form.Control placeholder="Region" name="name" type="text" onChange={handleSiteSave}/>
                            </Form.Group>
                        </Col>
                        <Col lg={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Slug</Form.Label>
                                <Form.Control placeholder="Slug" name="slug" type="text" onChange={handleSiteSave}/>
                            </Form.Group>
                        </Col>
                        <Col lg={12} className="text-end">
                            <Button variant='falcon-default' className='me-2 mb-1'
                                    size="sm" onClick={handleSubmit}>Add</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

SitesTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default SitesTableHeader;
