import React from 'react';
import ConfirmMailContent from 'components/authentication/ConfirmMailContent';
import AuthCardLayout from 'layouts/AuthCardLayout';

const ConfirmMail = () => {
    const email = sessionStorage.getItem('email');
    return (
        <AuthCardLayout>
            <div className="text-center">
                <ConfirmMailContent layout="card" email={email} titleTag="h3"/>
            </div>
        </AuthCardLayout>
    )
}

export default ConfirmMail;
