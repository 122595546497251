import React, {useEffect, useState} from 'react';
import {Card, Dropdown, Button, Col, Form, Row, Modal, ListGroup, ListGroupItem} from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import CardDropdown from "../../../../components/common/CardDropdown";
import {next_icon, previous_icon, response_error} from "../../../../helpers/functions";
import Select from "react-select";
import SoftBadge from 'components/common/SoftBadge';
import IconButton from "../../../../components/common/IconButton";
import DatePicker from "react-datepicker";
import axios from 'axios';

const Coupons = () => {
    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [deletingCouponId, setDeletingCouponId] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [year, setYear] = useState(null);
    const [event, setEvent] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [status, setStatus] = useState(null);
    const [applicable, setApplicable] = useState(null);
    const [usage, setUsage] = useState(null);
    const [coupons, setCoupons] = useState([]);
    const [editingCouponId, setEditingCouponId] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [formData, setFormData] = useState({
      code: '',
      discount: '',
      discount_amount: '',
      status: status,
      applicable_to: applicable,
      usage: usage,
      note: ''
  });

    const getCoupons = (page, query) => {
      axios.get(`/coupons?page=${page}&query=${query}`)
        .then(response => {
          setCoupons(response.data.coupons.data)
          let total_page = parseInt(response.data.coupons.total) / parseInt(response.data.coupons.per_page);
          total_page = total_page > 0 ? Math.ceil(total_page) : 0;
          setPageCount(total_page);
        })
        .catch(error => {
          response_error(error);
        })
    }

    useEffect(() => {
      getCoupons(page, searchQuery)
    }, [page])

    useEffect(() => {
      setFormData(prevFormData => ({
          ...prevFormData,
          status: status && status.value,
          applicable_to: applicable && applicable.value,
          usage: usage && usage.value
      }));
  }, [status, applicable, usage]);

  const handleInputsChange = (event) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSaveButtonClick = (data) => {
    axios.post("/coupons", data)
    .then(() => {
      getCoupons(page, searchQuery)
      resetForm()
      setModalShow(false)
    })
    .catch(error => {
      response_error(error);
    })
  }

  const getEditingCouponValues = async (couponId) => {
    let editingCoupon = null
    const response = await axios.get(`/coupons/${couponId}`)
    editingCoupon = response.data.coupon
    setEditingCouponId(editingCoupon.id)

    setStatus({value: editingCoupon.status, label: editingCoupon.status === "valid" ? "Valid" : "Invalid"} );
    setApplicable({value: editingCoupon.applicable_to, label: editingCoupon.applicable_to === "event" ? "Event" : editingCoupon.applicable_to === "shop" ? "Shop" : "Both"} );
    setUsage({value: editingCoupon.usage, label: editingCoupon.usage === "one_time" ? "One Time" : "Multiple Times"} );

    setFormData({
      ...formData,
      code: editingCoupon.code,
      discount: editingCoupon.discount ? editingCoupon.discount : "",
      discount_amount: editingCoupon.discount_amount ? editingCoupon.discount_amount : "",
      note: editingCoupon.note ? editingCoupon.note : ""
      })
  }

  const handleEditCoupon = (data) => {
    axios.put(`/coupons/${editingCouponId}`, data)
    .then(() => {
      getCoupons(page, searchQuery)
      resetForm()
      setModalShow(false)
      setIsEdit(false)
    })
    .catch(error => {
      response_error(error);
    })
  }

  const handleDeleteCoupon = (couponId) => {
    axios.delete(`/coupons/${couponId}`)
    .then(setDeleteModalShow(false))
    .then(getCoupons(page, searchQuery))
    .catch(error => {
      response_error(error);
    })
  }

  const resetForm = () => {
    setStatus(null);
    setApplicable(null);
    setUsage(null);

    setFormData({
      ...formData,
      code: '',
      discount: '',
      discount_amount: '',
      note: ''
    })
  }

    const payment = [
        {value: 'paid', label: 'Paid'},
        {value: 'not_paid', label: 'Not Paid'},
        {value: 'waiting', label: 'Waiting'}
    ];
    const events = [
        {value: 'event1', label: 'Event 1'},
        {value: 'event2', label: 'Event 2'}
    ];
    const years = [
        {value: 2000, label: '2000'},
        {value: 2001, label: '2001'},
        {value: 2002, label: '2002'},
        {value: 2003, label: '2003'}
    ];
    const statuses = [
        {value: 'valid', label: 'Valid'},
        {value: 'invalid', label: 'Invalid'}
    ];
    const applicables = [
        {value: 'event', label: 'Event'},
        {value: 'shop', label: 'Shop'},
        {value: 'both', label: 'Both'},
    ];
    const usages = [
        {value: 'multiple_times', label: 'Multiple Times'},
        {value: 'one_time', label: 'One Time'}
    ];
    const handlePageClick = page => {
        setPage(page.selected + 1);
    }

    const Filter = (
        <Row>
            <Col lg={12}>
                <Form.Group
                    as={Row}
                >
                    <Col lg="2">
                        <Form.Group className="mb-3" controlId="search">
                            <Form.Control type="text" placeholder="Search" onChange={(e) => {
                              setSearchQuery(e.target.value)
                              setPage(1)
                              getCoupons(page, e.target.value)
                            }}/>
                        </Form.Group>
                    </Col>
                    <Col lg="2">
                        <Button
                            variant="falcon-default"
                            className="me-2"
                            type="button"
                            onClick={() => {
                              setPage(1)
                              getCoupons(page, searchQuery)
                            }}
                        >
                            Search
                        </Button>
                    </Col>
                    <Col lg="8" className="text-end">
                        <Button
                            variant="falcon-default"
                            className="me-2"
                            type="button"
                            onClick={() => setModalShow(true)}
                        >
                            Add Coupon
                        </Button>
                    </Col>
                </Form.Group>
            </Col>
        </Row>
    );

    return (
        <>
            <Card className="mb-3">
                <Card.Header>
                    <h5 className="fs-0 mb-3 text-nowrap py-2 py-xl-0">Coupon Codes</h5>
                    {Filter}
                </Card.Header>
                <Card.Body className="pt-0">
                    <div className="table-responsive">
                    <table className="fs--1 mb-0 table table-sm table-striped overflow-hidden" role="table">
                        <thead className="bg-200 text-900 text-nowrap align-middle">
                        <tr>
                            <th>Code</th>
                            <th>Discount (%)</th>
                            <th>Discount Amount (fixed amount)</th>
                            <th>Status</th>
                            <th>Applicable To</th>
                            <th>Usage</th>
                            <th>Notes</th>
                            <th className="text-end">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                          {
                            coupons.map((coupon) => {
                              return (
                                <tr className="align-middle white-space-nowrap" role="row" key={coupon.id}>
                                  <td role="cell" className="py-2">{coupon.code}</td>
                                  <td role="cell">{coupon.discount ? coupon.discount : "-"}</td>
                                  <td role="cell">{coupon.discount_amount ? coupon.discount_amount : "-"}</td>
                                  <td role="cell" className="py-2"><SoftBadge pill bg={coupon.status === "valid" ? "success" : "danger"}>{coupon.status  }</SoftBadge></td>
                                  <td role="cell">{coupon.applicable_to}</td>
                                  <td role="cell">{coupon.usage}</td>
                                  <td role="cell">{coupon.note}</td>
                                  <td role="cell" className="text-end">
                                      <CardDropdown iconClassName="fs--1" drop="start">
                                          <div className="py-2">
                                              <Dropdown.Item onClick={() => {
                                                getEditingCouponValues(coupon.id)
                                                setIsEdit(true)
                                                setModalShow(true)
                                              }}>Edit</Dropdown.Item>
                                              <Dropdown.Divider as="div"/>
                                              <Dropdown.Item className="text-danger" onClick={() => {
                                                setDeletingCouponId(coupon.id)
                                                setDeleteModalShow(true)
                                              }}>
                                                  Delete
                                              </Dropdown.Item>
                                          </div>
                                      </CardDropdown>
                                  </td>
                                </tr>
                                )
                            })
                          }
                        </tbody>
                    </table>
                        </div>
                </Card.Body>
                <Card.Footer>
                    <ReactPaginate
                        previousLabel={previous_icon()}
                        nextLabel={next_icon()}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination mb-0 mx-1'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </Card.Footer>
            </Card>
            <Modal
                show={modalShow}
                onHide={() => {
                  isEdit && resetForm()
                  setModalShow(false)
                  setIsEdit(false)
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{isEdit ? "Edit Coupon" : "Add Coupon"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="code">
                                <Form.Label>Code</Form.Label>
                                <Form.Control type="text" placeholder="Code" value={formData.code} onChange={handleInputsChange}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="discount">
                                <Form.Label>Discount</Form.Label>
                                <Form.Control type="text" placeholder="discount" value={formData.discount} onChange={handleInputsChange}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="discount_amount">
                                <Form.Label>Discount Amount (fixed amount)</Form.Label>
                                <Form.Control type="text" placeholder="Discount Amount (fixed amount)" value={formData.discount_amount} onChange={handleInputsChange}/>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="status">
                                <Form.Label>Status</Form.Label>
                                <Select
                                    placeholder='Status'
                                    options={statuses}
                                    value={status}
                                    onChange={value => setStatus(value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="applicable">
                                <Form.Label>Applicable to</Form.Label>
                                <Select
                                    placeholder='Status'
                                    options={applicables}
                                    value={applicable}
                                    onChange={value => setApplicable(value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3" controlId="usage">
                                <Form.Label>Usage</Form.Label>
                                <Select
                                    placeholder='Usage'
                                    options={usages}
                                    value={usage}
                                    onChange={value => setUsage(value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group className="mb-3" controlId="note">
                                <Form.Label>Note</Form.Label>
                                <Form.Control as="textarea" rows={3} value={formData.note} onChange={handleInputsChange}/>
                            </Form.Group>
                        </Col>
                        <Col sm={12} className="text-center">
                            <Button
                                size="sm"
                                variant="falcon-default"
                                className="me-2"
                                type="button"
                                onClick={() => isEdit ? handleEditCoupon(formData) : handleSaveButtonClick(formData)}
                            >
                                {isEdit ? "Edit" : "Add"}
                            </Button>
                        </Col>

                    </Row>
                </Modal.Body>
            </Modal>
            <Modal
              show={deleteModalShow}
              onHide={() => setDeleteModalShow(false)}
              size="sm"
              aria-labelledby="contained-delete-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                  <Modal.Title id="contained-delete-modal-title-vcenter">Delete Coupon?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className='d-flex justify-content-center'>
                    <Col className='d-flex gap-4' style={{ flex: '0' }}>
                      <Button  variant="danger" onClick={() => handleDeleteCoupon(deletingCouponId)}>Yes</Button>
                      <Button  variant="secondary" onClick={() => setDeleteModalShow(false)}>No</Button>
                    </Col>
                </Row>
              </Modal.Body>
            </Modal>
        </>
    );
}

export default Coupons;