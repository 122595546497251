import React, {useContext, useState} from 'react';
import {Form, Card, Col, Row} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from "moment";
import {MemberContext} from "../../../../context/Context";

const Other = () => {
    const {otherInfo, otherInfoHandleChange, otherInfoSelectChangeHandler, setOtherInfo, ambassadorGroups} = useContext(MemberContext);
    const [date, setDate] = useState(null);
    const generate_ambassador_group_link = (e, id) => {
        e.preventDefault()
    }
    const groups = [];
    return (
        <Row className="g-0 mb-3">
            <Col lg={12} className="pe-lg-2">
                <Card className="h-lg-100">
                    <Card.Header as="h5">Other</Card.Header>
                    <div className="border-dashed-bottom"></div>
                    <Card.Body>
                        <Row>
                            {
                                otherInfo.hasWon && (
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Date of last won red shirt</Form.Label>
                                            <Card.Text>
                                                {otherInfo.wonDate}
                                            </Card.Text>
                                        </Form.Group>
                                    </Col>
                                )
                            }


                            {/*<Col lg={6}>*/}
                            {/*    <Form.Group className="mb-3">*/}
                            {/*        <Form.Label>Won Red Shirt ?</Form.Label>*/}
                            {/*        <div className="d-block">*/}
                            {/*            <Form.Check inline*/}
                            {/*                        type='radio'*/}
                            {/*                        id='yes'*/}
                            {/*                        label='Yes'*/}
                            {/*                        name='hasWon'*/}
                            {/*                        value="1"*/}
                            {/*             checked={parseInt(otherInfo.hasWon) === 1}*/}
                            {/*                        onChange={otherInfoHandleChange}*/}
                            {/*                        />*/}
                            {/*            <Form.Check inline*/}
                            {/*                        type='radio'*/}
                            {/*                        id='no'*/}
                            {/*                        label='No'*/}
                            {/*                        name='hasWon'*/}
                            {/*                        value="0"*/}
                            {/*                        checked={parseInt(otherInfo.hasWon) !== 1}*/}
                            {/*                        onChange={otherInfoHandleChange}*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            {/*<Col lg={6}>*/}
                            {/*    <Form.Group className="mb-3">*/}
                            {/*        <Form.Label>Date of last won red shirt</Form.Label>*/}
                            {/*        <DatePicker*/}
                            {/*            selected={Date.parse(otherInfo.wonDate)}*/}
                            {/*            onChange={(eventTime) => setOtherInfo(prevState => ({*/}
                            {/*                ...prevState,*/}
                            {/*                wonDate: moment(eventTime).format('YYYY-MM-DD')*/}
                            {/*            }))}*/}
                            {/*            formatWeekDay={day => day.slice(0, 3)}*/}
                            {/*            className='form-control'*/}
                            {/*            placeholderText="yyyy-MM-dd"*/}
                            {/*            dateFormat="yyyy-MM-dd"*/}
                            {/*            name="won_date"*/}
                            {/*        />*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}

                            {
                                ambassadorGroups.length > 0 && (
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Groups of Ambassador</Form.Label>
                                            <Card.Text>
                                                {
                                                    ambassadorGroups.map(group => {
                                                        return (
                                                            <span className="me-4" key={group.id}><a  href="" onClick={(e) => generate_ambassador_group_link(e, group.id)}>{group.name}</a></span>
                                                        )
                                                        }
                                                    )
                                                }
                                            </Card.Text>
                                        </Form.Group>
                                    </Col>
                                )
                            }


                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default Other;