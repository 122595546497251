import toast from 'react-hot-toast';
import React from "react";
import axios from "axios";

const response_error = error => {
  let message = 'Network Error';

  if (error.hasOwnProperty('response') && error.response.status > 0) {
    if (error.response.status === 401 || error.response.status === 419) {
      location.href = '/login';
      return false;
    } else if (error.response.status === 422) {
      if(error.response.data.hasOwnProperty('errors')){
        let errors = error.response.data.errors;
        let errors_str = '';
        for (let index in errors) {
          errors_str += errors[index];
        }
        message = errors_str;
      }
      else{
        message = error.response.data.message
      }

    } else if (error.response.status === 204 || error.response.status === 200  || error.response.status === 201) {
      return true;
    } else if (error.response.status !== 404) {
      message = error.response.data.message;
    } else {
      message = 'Unable to perform your request.';
    }
  }

  toast.error(message);
  return message;
};

const login_error = error => {
  let message = 'Network Error';
  if (error.response && error.response.status > 0) {
    if (error.response.status === 422) {
      let errors = error.response.data.errors;
      let errors_str = '';
      for (let index in errors) {
        errors_str += errors[index];
      }
      message = errors_str;
    } else if (error.response.status !== 404) {
      message = error.response.data.message;
    } else {
      message = 'Unable to perform your request.';
    }
  }
  toast.error(message);
  return message;
};

const input_data = (e, setValue, bool) => {
  let value = e.target.value;
  if (bool) {
    value = !!parseInt(value);
  }
  setValue(prevState => ({ ...prevState, [e.target.name]: value }));
};

const previous_icon = () => (
    <svg aria-hidden="true" focusable="false" data-prefix="fas"
         data-icon="chevron-left"
         className="svg-inline--fa fa-chevron-left fa-w-10 "
         role="img" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 320 512">
      <path fill="currentColor"
            d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
    </svg>
)

const next_icon = () => (
    <svg aria-hidden="true" focusable="false" data-prefix="fas"
         data-icon="chevron-right"
         className="svg-inline--fa fa-chevron-right fa-w-10 " role="img"
         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path fill="currentColor"
            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
    </svg>
)

const logout = () => {
  axios.post('/logout').then(r => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('memberId');
    location.href = '/login';
  });
}

export { response_error, login_error, input_data, previous_icon, next_icon, logout };
